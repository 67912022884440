import { simplicateApiV3 } from "@simplicate/api-client";

export type addSaleServicesToExistingProjectBody = {
  projectId: string;
  saleId: string;
  saleServiceIds: string[];
};

const endpointsV3 = simplicateApiV3.injectEndpoints({
  endpoints: (builder) => ({
    addSaleServicesToExistingProject: builder.mutation<string, addSaleServicesToExistingProjectBody>({
      query: (body) => ({
        url: `/project.addSaleServicesToExistingProject`,
        body: body,
        method: "POST",
      }),
    }),
  }),
});

export const { useAddSaleServicesToExistingProjectMutation } = endpointsV3;
