import { useTranslation } from "@simplicate/translations";
import { TextArea } from "@simplicate/ui";
import { memo } from "react";
import { useProjectForm } from "../hooks/useProjectServiceForm";
import styles from "./ExplanationTextArea.module.scss";

const useExplanationTextAreaProps = () => {
  const { values, errors, touched, setExplanation } = useProjectForm();

  return {
    explanation: values.explanation,
    setExplanation,
    touched: touched.explanation,
    error: errors.explanation,
    hasError: touched.explanation && errors.explanation !== undefined,
    defaultService: values.defaultService,
  };
};

export const ExplanationTextAreaContent = memo(function ExplanationTextAreaContent({
  explanation,
  setExplanation,
  touched,
  error,
  defaultService,
}: ReturnType<typeof useExplanationTextAreaProps>) {
  const { t } = useTranslation("project_services");
  const isDefaultServiceUndefined = defaultService === undefined;

  return (
    <TextArea
      name="explanation"
      testId="explanation-text-area"
      label={t("explanation")}
      className={styles.textArea}
      value={explanation}
      onChange={(event) => setExplanation?.(event.target.value)}
      hasError={touched && error !== undefined}
      disabled={isDefaultServiceUndefined}
    />
  );
});

export const ExplanationTextArea = () => <ExplanationTextAreaContent {...useExplanationTextAreaProps()} />;
