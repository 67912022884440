import { InvoiceMethod } from "@simplicate/api-client";
import {
  Big,
  type HourTypeInServiceBody,
  type HourTypeInService,
  transformMoneyToMoneyFromAPI,
  EmployeeHourlyRateAgreements,
  transformMoneyFromAPIToMoney,
} from "../../data";
import { HourTypeInForm, ValidHourTypeInForm } from "./types";
import { type WithSortOrder } from "./utils";

export const transformToCreateServiceHourType = (
  { id, hourlyRate, amount, isInvoiceable, sortOrder }: WithSortOrder<ValidHourTypeInForm>,
  invoiceMethod: InvoiceMethod,
): HourTypeInServiceBody => ({
  defaultHourTypeId: id,
  hourlyRate: transformMoneyToMoneyFromAPI(hourlyRate),
  amountOfHours: amount,
  isInvoiceable: invoiceMethod === InvoiceMethod.time_and_expenses ? isInvoiceable : undefined,
  sortOrder,
});

export const transformEmployeeHourlyRatesInForm = (employeeHourlyRates: EmployeeHourlyRateAgreements[] | undefined) =>
  employeeHourlyRates?.map(({ hourlyRate, employeeId }) => ({
    id: employeeId,
    hourlyRate: transformMoneyFromAPIToMoney(hourlyRate),
  }));

export const transformToHourTypeInForm = ({
  defaultHourType,
  amountOfHours,
  isInvoiceable = true,
  hourlyRate,
  hasRegistrations,
}: HourTypeInService): HourTypeInForm => ({
  id: defaultHourType.id,
  name: defaultHourType.name,
  amount: amountOfHours,
  isInvoiceable,
  hourlyRate: {
    amount: Big(hourlyRate.amount),
    currency: hourlyRate.currency,
  },
  total: {
    amount: Big(hourlyRate.amount).mul(amountOfHours),
    currency: hourlyRate.currency,
  },
  hasRegistrations,
});
