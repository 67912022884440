import { simplicateApiV3, createTagTypes } from "@simplicate/api-client";
import { cubeDimensionToKey, convertStringToCubeDimension } from "../../../types";
import { Column, ViewPreset, Filter } from "../../slices/dashboardSlice/types";

type ApiFilter = Omit<Filter, "labelDimension" | "valueDimension"> & {
  valueColumn: string;
  labelColumn: string;
};

type ApiColumn = Omit<Column, "dimension"> & {
  column: string;
};

type ApiViewPreset = Omit<ViewPreset, "columns" | "filters"> & {
  columns: ApiColumn[];
  filters: ApiFilter[];
};

const ViewPresetTags = createTagTypes({
  tagPrefix: "ViewPreset",
  tags: ["ViewPreset"],
});

function fromApiViewPreset(viewPreset: ApiViewPreset): ViewPreset {
  return {
    ...viewPreset,
    columns: viewPreset.columns.map(({ column, order, visible }) => ({
      dimension: convertStringToCubeDimension(column),
      order,
      visible,
    })),
    filters: viewPreset.filters.map(({ label, value, ...filter }) => ({
      label,
      value,
      labelDimension: convertStringToCubeDimension(filter.labelColumn),
      valueDimension: convertStringToCubeDimension(filter.valueColumn),
    })),
  };
}

function toApiViewPreset(viewPreset?: Partial<ViewPreset>): Partial<ApiViewPreset> | undefined {
  return (
    viewPreset && {
      ...viewPreset,
      columns: viewPreset.columns?.map(({ dimension, order, visible }) => ({
        column: cubeDimensionToKey(dimension),
        order,
        visible,
      })),
      filters: viewPreset.filters?.map(({ label, value, ...filter }) => ({
        label,
        value,
        labelColumn: cubeDimensionToKey(filter.labelDimension),
        valueColumn: cubeDimensionToKey(filter.valueDimension),
      })),
    }
  );
}

const endpointsV3 = simplicateApiV3.enhanceEndpoints({ addTagTypes: Object.values(ViewPresetTags) }).injectEndpoints({
  endpoints: (builder) => ({
    getViewPresetsList: builder.query<ViewPreset[], string>({
      query: (dashboardId) => ({
        url: `/insight.viewPreset.list`,
        params: {
          dashboardId,
        },
      }),
      transformResponse: (result: ApiViewPreset[]) => {
        return result.map(fromApiViewPreset);
      },
      providesTags: [ViewPresetTags.ViewPreset],
    }),

    getViewPreset: builder.query<ViewPreset, number>({
      query: (viewId) => ({
        url: `/insight.viewPreset.get/${viewId}`,
      }),
      transformResponse: fromApiViewPreset,
    }),

    createViewPreset: builder.mutation<ViewPreset, Partial<ViewPreset>>({
      query: (data) => ({
        url: "/insight.viewPreset.create",
        method: "POST",
        body: toApiViewPreset(data),
      }),
      transformResponse: fromApiViewPreset,
      invalidatesTags: [ViewPresetTags.ViewPreset],
    }),

    updateViewPreset: builder.mutation<ViewPreset, Partial<ViewPreset>>({
      query: (data) => ({
        url: `/insight.viewPreset.update/${data.id}`,
        method: "PUT",
        body: toApiViewPreset(data),
      }),
      transformResponse: fromApiViewPreset,
      invalidatesTags: [ViewPresetTags.ViewPreset],
    }),

    deleteViewPreset: builder.mutation<void, number>({
      query: (viewId) => ({
        url: `/insight.viewPreset.delete/${viewId}`,
        method: "DELETE",
      }),
      invalidatesTags: [ViewPresetTags.ViewPreset],
    }),
  }),
});

export const {
  useCreateViewPresetMutation,
  useDeleteViewPresetMutation,
  useGetViewPresetQuery,
  useGetViewPresetsListQuery,
  useUpdateViewPresetMutation,
} = endpointsV3;
export const ViewPresetEndpoints = endpointsV3.endpoints;
