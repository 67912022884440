import { InvoiceMethod } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { format } from "date-fns";
import { memo } from "react";
import { useProjectForm } from "../hooks/useProjectServiceForm";
import styles from "./InvoicedUntil.module.scss";

const DATE_FORMAT = "dd MMMM yyyy";

const useInvoicedUntilProps = () => {
  const { existingProjectService } = useProjectForm();

  const invoicedUntilDate =
    existingProjectService?.invoiceMethod === InvoiceMethod.subscription && existingProjectService?.invoicedUntil
      ? new Date(existingProjectService.invoicedUntil)
      : undefined;

  return { invoicedUntilDate };
};

export const InvoicedUntilContent = memo(function InvoicedUntilContent({
  invoicedUntilDate,
}: ReturnType<typeof useInvoicedUntilProps>) {
  const { t } = useTranslation("project_services");

  if (!invoicedUntilDate) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <span className={styles.label}>{t("invoiced_until")}</span>
      <span className={styles.date}>{format(invoicedUntilDate, DATE_FORMAT)}</span>
    </div>
  );
});

export const InvoicedUntil = () => <InvoicedUntilContent {...useInvoicedUntilProps()} />;
