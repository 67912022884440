import { useMemo } from "react";
import type { HourlyRateInForm, HourTypeInForm } from "../types";

type UseRateAgreementsProps = {
  rates?: HourlyRateInForm[] | HourTypeInForm[];
  agreements?: HourlyRateInForm[];
};

export type RateWithAgreedRate = HourlyRateInForm & {
  agreedRate: HourlyRateInForm["hourlyRate"] | null;
};

type UseRateAgreementsReturn = {
  mergedRates: RateWithAgreedRate[];
  hasDeviation: boolean;
};

export const useRateAgreements = ({ rates = [], agreements = [] }: UseRateAgreementsProps): UseRateAgreementsReturn => {
  const mergedRates = useMemo(
    () =>
      rates
        .map((rate) => {
          const agreement = agreements.find((agreement) => agreement.id === rate.id);

          return {
            ...rate,
            agreedRate: agreement ? agreement.hourlyRate : null,
          };
        })
        .filter(({ agreedRate }) => agreedRate !== null),
    [agreements, rates],
  );

  const hasDeviation = useMemo(() => {
    return mergedRates.some(({ agreedRate, hourlyRate }) => {
      if (!agreedRate || !hourlyRate) return false;

      return hourlyRate.amount && !agreedRate.amount?.eq(hourlyRate?.amount);
    });
  }, [mergedRates]);

  return { mergedRates, hasDeviation };
};
