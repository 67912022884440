import { useCallback, useState } from "react";
import type { ServiceGroup } from "../../data";

export type UseServiceGroupModalReturnType = ReturnType<typeof useServiceGroupModal>;

export const useServiceGroupModal = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [serviceGroupInEdit, setServiceGroupInEdit] = useState<ServiceGroup | undefined>(undefined);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
    setServiceGroupInEdit(undefined);
  }, [setIsModalOpen, setServiceGroupInEdit]);

  const handleAddGroup = useCallback(() => {
    setIsModalOpen(true);
  }, [setIsModalOpen]);

  const handleEditGroup = useCallback(
    (group: ServiceGroup) => {
      setIsModalOpen(true);
      setServiceGroupInEdit(group);
    },
    [setIsModalOpen, setServiceGroupInEdit],
  );

  return {
    closeModal,
    handleAddGroup,
    handleEditGroup,
    isModalOpen,
    serviceGroupInEdit,
  };
};
