import classNames from "classnames";
import { ButtonHTMLAttributes, forwardRef, MutableRefObject, PropsWithChildren } from "react";
import { Link, LinkProps, To } from "react-router-dom";
import styles from "./Clickable.module.scss";

type ButtonProps = Omit<ButtonHTMLAttributes<HTMLButtonElement>, "onClick"> & {
  to?: never;
};

type GenericProps = {
  className?: string;
  testId?: string;
  disabled?: boolean;
  title?: string;
};

type AnchorProps = Omit<LinkProps, "onClick"> & Required<Pick<LinkProps, "to">>;

export type ClickableProps = GenericProps & {
  onClick?: () => void;
} & (AnchorProps | ButtonProps);

function isLinkProps(props: ButtonProps | LinkProps): props is GenericProps & LinkProps {
  return props.to !== undefined;
}

const getTarget = (to: To): LinkProps["target"] => {
  if (typeof to === "string" && !to.startsWith(window.origin)) {
    const toAsUrl = new URL(to, window.origin);

    return toAsUrl.origin === window.origin ? undefined : "_blank";
  }
};

export const Clickable = forwardRef<HTMLAnchorElement | HTMLButtonElement, PropsWithChildren<ClickableProps>>(
  ({ children, testId, className, onClick, title, disabled, ...props }, ref) => {
    const commonProps = {
      "data-testid": testId,
      onClick,
      title,
      disabled,
    };

    const disabledProps = {
      "data-testid": testId,
      title,
      disabled,
    };

    if (isLinkProps(props)) {
      const anchorProps = props;
      const target = getTarget(anchorProps.to) ?? anchorProps.target;

      if (disabled) {
        return (
          <span {...disabledProps} className={className} ref={ref}>
            {children}
          </span>
        );
      }

      return (
        <Link
          ref={ref as MutableRefObject<HTMLAnchorElement>}
          {...commonProps}
          {...anchorProps}
          className={classNames(styles.anchorReset, className)}
          target={target}
        >
          {children}
        </Link>
      );
    }

    const buttonProps = { type: "button" as const, ...props };

    return (
      <button
        ref={ref as MutableRefObject<HTMLButtonElement>}
        {...commonProps}
        {...(buttonProps as ButtonProps)}
        className={classNames(styles.buttonReset, className)}
      >
        {children}
      </button>
    );
  },
);

Clickable.displayName = "Clickable";
