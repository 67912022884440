// istanbul ignore file -- This module contains bundling and lazy loading logic
import { Spinner } from "@simplicate/ui";
import { Suspense, lazy } from "react";

async function loadSettingsModule() {
  const { AllAddOnsPage, InstalledAddOnsPage, ActivationAddOnDetailsPage } = await import("./pages");
  const { AddOnCenterTabBar } = await import("./components");

  return { AllAddOnsPage, InstalledAddOnsPage, AddOnCenterTabBar, ActivationAddOnDetailsPage };
}

const AllAddOnsPageComponent = lazy(() =>
  loadSettingsModule().then(({ AllAddOnsPage }) => ({ default: AllAddOnsPage })),
);
const InstalledAddOnsPageComponent = lazy(() =>
  loadSettingsModule().then(({ InstalledAddOnsPage }) => ({ default: InstalledAddOnsPage })),
);
const AddOnCenterTabBarComponent = lazy(() =>
  loadSettingsModule().then(({ AddOnCenterTabBar }) => ({ default: AddOnCenterTabBar })),
);
const ActivationAddOnDetailsPageComponent = lazy(() =>
  loadSettingsModule().then(({ ActivationAddOnDetailsPage }) => ({ default: ActivationAddOnDetailsPage })),
);

export const AllAddOnsPage = () => (
  <Suspense fallback={<Spinner size="large" />}>
    <AllAddOnsPageComponent />
  </Suspense>
);
export const InstalledAddOnsPage = () => (
  <Suspense fallback={<Spinner size="large" />}>
    <InstalledAddOnsPageComponent />
  </Suspense>
);
export const ActivationAddOnDetailsPage = () => (
  <Suspense fallback={<Spinner size="large" />}>
    <ActivationAddOnDetailsPageComponent />
  </Suspense>
);
export const AddOnCenterTabBar = () => (
  <Suspense>
    <AddOnCenterTabBarComponent />
  </Suspense>
);
