import { endOfWeek, startOfWeek } from "date-fns";
import { convertStringToCubeDimension } from "../../../types";
import { DashboardSlice, ViewPreset } from "./types";

type Filter = {
  valueDimension: readonly [string, string];
  labelDimension: readonly [string, string];
  value: string;
  label: string;
};
type FilterPreset = {
  id?: number;
  dashboardId: string;
  name: string;
  columns: PersistedColumn[];
  order: ColumnOrder[];
  filters: Filter[];
};
type PersistedColumn = { column: string; order: number; visible: boolean };
type ColumnOrder = { column: string; direction: "asc" | "desc" };
type DashboardViewState = {
  view?: FilterPreset;
  columns?: PersistedColumn[];
  dashboardId: string;
  order?: ColumnOrder[];
  filters?: Filter[];
};
type DashboardSliceV0 = Record<string, DashboardViewState & { createdAt: number; updatedAt: number }>;

export const migrations = {
  "0": (state: DashboardSliceV0): DashboardSliceV0 => {
    return Object.fromEntries(
      Object.entries(state)
        .filter(([key]) => !key?.startsWith("_"))
        .map(([key, value]) => {
          const order = value.order ?? [];

          return [
            key,
            {
              ...value,
              order: Array.isArray(order) ? order : [order],
            },
          ] as const;
        }),
    );
  },
  "1": (state: DashboardSliceV0): DashboardSlice => {
    const dashboards = Object.fromEntries(
      Object.entries(state)
        .filter(([key]) => !key?.startsWith("_"))
        .map(([key, value]): [string, ViewPreset] => {
          const columns =
            value.columns?.map((column) => ({
              dimension: convertStringToCubeDimension(column.column),
              order: column.order,
              visible: column.visible,
            })) ?? [];

          return [
            key,
            {
              columns,
              dashboardId: value.dashboardId,
              filters: value.filters ?? [],
              order:
                /* istanbul ignore next -- keep TS happy, but should not be possible since v2 is the first tagged version */
                value.order ?? [],
            },
          ] as const;
        }),
    );

    return {
      availableViewPresets: [],
      changedMeta: {
        deleteRequested: false,
        hasChanges: false,
        saveRequested: false,
        saveType: "create",
      },
      dateRange: {
        end: endOfWeek(new Date()),
        start: startOfWeek(new Date()),
      },
      localChanges: {},
      dashboards,
    };
  },
  "2": (state: DashboardSlice): DashboardSlice => {
    const dashboards = Object.fromEntries(
      Object.entries(state.dashboards).map(([key, value]) => {
        return [
          key,
          {
            ...value,
            order: value.order[0] ? [value.order[0]] : [],
          },
        ];
      }),
    );

    return {
      ...state,
      localChanges: {
        ...state.localChanges,
        order: state.localChanges?.order?.[0] ? [state.localChanges.order[0]] : [],
      },
      dashboards,
    };
  },
};
