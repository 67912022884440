import { PayloadAction, createSlice, Reducer } from "@simplicate/state";
import { endOfWeek, startOfWeek } from "date-fns";
import { cubeDimensionToKey } from "../../../types";
import { ViewPresetEndpoints } from "../../endpoints/ViewPreset/ViewPreset";
import { migrations } from "./dashboardSlice.migrations";
import { DashboardSlice, Column, ColumnOrder, DateRange, Filter, ViewPreset, AvailableFilters } from "./types";

export function createInitialState(): DashboardSlice {
  return {
    activeDashboardId: undefined,
    availableViewPresets: [],
    dashboards: {},
    localChanges: {},
    dateRange: {
      start: startOfWeek(Date.now()),
      end: endOfWeek(Date.now()),
    },
    availableFilters: [],
    changedMeta: {
      deleteRequested: false,
      hasChanges: false,
      saveRequested: false,
      saveType: "create",
    },
  };
}

const slice = createSlice(
  {
    namespace: "insights",
    localStorageKey: "insights_dashboard_views",
    version: 2,
    config: {
      whitelist: ["localChanges", "dashboards", "dateRange"],
      migrations: migrations,
    },
  },
  {
    name: "Insights Dashboard State",
    initialState: createInitialState(),
    reducerPath: "dashboard_views",
    reducers: {
      setActiveDashboard: (
        state,
        { payload: { dashboardId, dateRange } }: PayloadAction<{ dashboardId: string; dateRange?: DateRange }>,
      ) => {
        state.activeDashboardId = dashboardId;
        state.availableViewPresets = [];

        if (dateRange) {
          state.dateRange = dateRange;
        }
      },
      setAvailablePresetsSubscription: (state, { payload }: PayloadAction<{ unsubscribe: () => void }>) => {
        state.availableViewPresetsSubscription = payload;
      },
      setActivePreset: (state: DashboardSlice, { payload }: PayloadAction<ViewPreset>) => {
        if (!state.activeDashboardId) {
          return;
        }

        state.dashboards[state.activeDashboardId] = payload;
        state.localChanges = {};
        state.changedMeta = {
          deleteRequested: false,
          hasChanges: false,
          saveRequested: false,
          saveType: "create",
        };
      },
      removeActivePreset: (state: DashboardSlice) => {
        if (!state.activeDashboardId) {
          return;
        }

        delete state.dashboards[state.activeDashboardId];
        state.localChanges = {};
      },
      setFilters: (state: DashboardSlice, { payload }: PayloadAction<Filter[]>) => {
        state.localChanges.filters = payload;
        state.changedMeta.hasChanges = true;
      },

      setAvailableFilters: (state: DashboardSlice, action: PayloadAction<AvailableFilters[]>) => {
        const localChangesFilters = state.localChanges.filters;
        const activeDashboardFilters = state.activeDashboardId
          ? state.dashboards[state.activeDashboardId]?.filters
          : undefined;

        const currentFilters = localChangesFilters ?? activeDashboardFilters ?? [];

        const newFilters = currentFilters.filter((filter) =>
          action.payload.some(
            (availableFilter) =>
              availableFilter.visible &&
              cubeDimensionToKey(filter.labelDimension) === cubeDimensionToKey(availableFilter.dimension),
          ),
        );

        state.localChanges.filters = newFilters;
        state.availableFilters = action.payload;
        state.changedMeta.hasChanges = true;
      },

      clearAllFilters: (state: DashboardSlice) => {
        if (state?.localChanges.filters && state.localChanges.filters.length === 0) {
          return;
        }

        state.localChanges.filters = [];
        state.changedMeta.hasChanges = true;
      },
      setColumns: (state: DashboardSlice, { payload }: PayloadAction<Column[]>) => {
        state.localChanges.columns = payload;
        state.changedMeta.hasChanges = true;
      },
      setOrder: (state: DashboardSlice, { payload }: PayloadAction<ColumnOrder[]>) => {
        state.localChanges.order = payload;
        state.changedMeta.hasChanges = true;
      },
      setChangedMeta: (state: DashboardSlice, { payload }: PayloadAction<DashboardSlice["changedMeta"]>) => {
        state.changedMeta = payload;
      },
      setDateRange: (state, { payload }: PayloadAction<{ start: Date; end: Date }>) => {
        state.dateRange = payload;
      },
      restoreView: (state: DashboardSlice) => {
        state.localChanges = {};
        state.changedMeta.hasChanges = false;
      },
    },
    extraReducers: (builder) => {
      builder.addMatcher(
        ViewPresetEndpoints.getViewPresetsList.matchFulfilled,
        // istanbul ignore next -- these matchers are mocked and therefore nonexistant
        (state: DashboardSlice, { payload }) => {
          state.availableViewPresets = payload;

          return state;
        },
      );
      builder.addMatcher(
        ViewPresetEndpoints.updateViewPreset.matchFulfilled,
        // istanbul ignore next -- these matchers are mocked and therefore nonexistant
        (state: DashboardSlice, { payload }) => {
          if (!state.activeDashboardId) {
            return;
          }

          state.dashboards[state.activeDashboardId] = payload;
          state.localChanges = {};
          state.changedMeta = {
            deleteRequested: false,
            hasChanges: false,
            saveRequested: false,
            saveType: "create",
          };
        },
      );
      builder.addMatcher(
        ViewPresetEndpoints.createViewPreset.matchFulfilled,
        // istanbul ignore next -- these matchers are mocked and therefore nonexistant
        (state: DashboardSlice, { payload }) => {
          if (!state.activeDashboardId) {
            return;
          }

          state.dashboards[state.activeDashboardId] = payload;
          state.localChanges = {};
          state.changedMeta = {
            deleteRequested: false,
            hasChanges: false,
            saveRequested: false,
            saveType: "create",
          };
        },
      );
      builder.addMatcher(
        ViewPresetEndpoints.deleteViewPreset.matchFulfilled,
        // istanbul ignore next -- these matchers are mocked and therefore nonexistant
        (state: DashboardSlice) => {
          if (!state.activeDashboardId) {
            return;
          }

          delete state.dashboards[state.activeDashboardId];
          state.localChanges = {};
          state.changedMeta = {
            deleteRequested: false,
            hasChanges: false,
            saveRequested: false,
            saveType: "create",
          };
        },
      );
    },
  },
);

export const {
  clearAllFilters,
  setActiveDashboard,
  setColumns,
  setDateRange,
  setFilters,
  setOrder,
  setActivePreset,
  setChangedMeta,
  restoreView,
  setAvailablePresetsSubscription,
  setAvailableFilters,
  removeActivePreset,
} = slice.actions;

export const _dashboardSliceReducer: Reducer<DashboardSlice> = slice.reducer;
