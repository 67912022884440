import { useTranslation } from "@simplicate/translations";
import { Button, Icon, Modal, SortableList } from "@simplicate/ui";
import { useCallback, useMemo, useState } from "react";
import { Filter, getAvailableFilters, useAppDispatch, useAppSelector } from "../../data";
import { setAvailableFilters } from "../../data/slices/dashboardSlice/dashboardSlice";
import { convertStringToCubeDimension, cubeDimensionToKey } from "../../types";
import styles from "./FilterActionsModal.module.scss";

type FilterActionsModalProps = {
  filters: Filter[];
};

type FilterViewState = { id: string; filter: Filter; visible: boolean; disabled?: boolean }[];

export const FilterActionsModal = ({ filters: allFilters }: FilterActionsModalProps) => {
  const { t } = useTranslation("insights");
  const [modalOpen, setModalOpen] = useState(false);
  const availableFilters = useAppSelector(getAvailableFilters);
  const dispatch = useAppDispatch();

  const filtersState = useMemo(
    () =>
      allFilters.map((filter) => ({
        id: cubeDimensionToKey(filter.labelDimension),
        filter,
        visible:
          availableFilters?.find((stateFilter) => stateFilter?.dimension === filter.labelDimension)?.visible ?? true,
      })),

    [allFilters, availableFilters],
  );

  const filtersStateWithDateRange = useMemo(() => {
    const filterDateRange = {
      id: "dateRange",
      filter: {
        label: t("general.date_range_label"),
        value: "date_range",
        labelDimension: convertStringToCubeDimension("date.range"),
        valueDimension: convertStringToCubeDimension("date.range"),
      },
      visible: true,
      disabled: true,
    } as const;

    return [filterDateRange, ...filtersState];
  }, [filtersState, t]);

  const [filtersSelections, setFiltersSelections] = useState<FilterViewState>(
    filtersStateWithDateRange.filter((filter) => filter.visible),
  );
  const [filtersOrder, setFiltersOrder] = useState<FilterViewState>(filtersStateWithDateRange);

  const openModal = useCallback(() => {
    setFiltersSelections(filtersStateWithDateRange.filter((filter) => filter.visible));

    setModalOpen(true);
  }, [filtersStateWithDateRange]);
  const closeModal = useCallback(/* istanbul ignore next */ () => setModalOpen(false), []);

  const handleSubmit = useCallback(() => {
    const newFilters = [...filtersOrder]
      .filter((filter) => !filter.disabled)
      .map((filter, index) => ({
        dimension: filter.filter.labelDimension,
        order: index,
        visible: filtersSelections.some((selection) => selection.id === filter.id),
      }));

    dispatch(setAvailableFilters(newFilters));
    closeModal();
  }, [filtersOrder, dispatch, closeModal, filtersSelections]);

  const handleReset = useCallback(() => {
    setFiltersSelections(filtersStateWithDateRange.filter((filter) => filter.visible));
    setFiltersOrder(filtersStateWithDateRange);
    closeModal();
  }, [closeModal, filtersStateWithDateRange]);

  return (
    <>
      <Button variant="subtle" size="small" onClick={openModal}>
        <Icon icon="plus" />
        {t("general.add_filter_button")}
      </Button>

      <Modal
        title={t("general.configuration_filters")}
        isOpen={modalOpen}
        onClose={handleReset}
        footerComponent={
          <div className={styles.modalFooter}>
            <Button variant="primary" size="medium" type="submit" onClick={handleSubmit}>
              {t("general.button_add")}
            </Button>
            <Button variant="subtle" size="medium" onClick={handleReset}>
              {t("general.button_cancel")}
            </Button>
          </div>
        }
      >
        <div className={styles.modalContent}>
          <p className={styles.description}>{t("general.data_grid_description_filters")}</p>
          <SortableList
            name="filtersList"
            testId="filters-list"
            data={filtersOrder}
            selection={filtersSelections}
            isSortable={true}
            isSelectable={true}
            onOrderChange={setFiltersOrder}
            onSelectionChange={setFiltersSelections}
          >
            {(item) => <span>{item.filter.label}</span>}
          </SortableList>
        </div>
      </Modal>
    </>
  );
};
