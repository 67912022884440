import { Clickable, Dialog, type DialogProps, Icon } from "@simplicate/ui";
import classNames from "classnames";
import { forwardRef, PropsWithChildren } from "react";
// eslint-disable-next-line css-modules/no-unused-class -- classes are used
import styles from "./DialogBase.module.scss";

type DialogBaseProps = DialogProps & {
  maxWidth?: "large" | "medium";
  onSubmit?: () => void;
};

const DialogContainer = forwardRef<unknown, DialogBaseProps>(function DialogBase(
  { children, maxWidth = "medium", onSubmit, ...props },
  ref,
) {
  return (
    <Dialog ref={ref} {...props}>
      <form onSubmit={onSubmit}>
        <div className={classNames(styles.container, styles[maxWidth])}>
          <Clickable type="reset" className={styles.closeButton}>
            <Icon icon="times" />
          </Clickable>
          {children}
        </div>
      </form>
    </Dialog>
  );
});

const Title = ({ children }: { children: React.ReactNode }) => <h3 className={styles.title}>{children}</h3>;

const Description = ({ children }: { children: React.ReactNode }) => (
  <span className={styles.description}>{children}</span>
);

const Content = ({ children }: PropsWithChildren) => children;

const Footer = ({ children }: PropsWithChildren) => <div className={styles.footer}>{children}</div>;

export const DialogBase = Object.assign(DialogContainer, {
  Title,
  Description,
  Content,
  Footer,
});
