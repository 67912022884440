// istanbul ignore file -- This component will be covered by e2e tests
import { Query } from "@cubejs-client/core";
import { usePageTitle } from "@simplicate/state";
import { TFunction, useTranslation } from "@simplicate/translations";
import { Page } from "@simplicate/ui";
import { ComponentProps, useMemo } from "react";
import { CubeProvider, Dashboard } from "../../components";
import { ViewMenu } from "../../components/ViewMenu";
import { Column, cubeDimensionToKey } from "../../types";
import { FilterWidget, MultiKPIWidget, TableWidget, ViewActionsWidget } from "../../widgets";
import { BarChartWidget } from "../../widgets/BarChartWidget";
import { DashboardReportPageTemplate } from "../DashboardReportPageTemplate";

const WORKFLOW_CREATED_AT = ["workflow_report", "workflow_created_at"] as const;
// eslint-disable-next-line @typescript-eslint/no-unused-vars -- Exists in the view, but not used in the report yet
const WORKFLOW_STEP_NAME = ["workflow_report", "workflow_step_name"] as const;
const WORKFLOW_STEP_OWNER = ["workflow_report", "workflow_step_owner"] as const;
const WORKFLOW_TYPE = ["workflow_report", "workflow_type"] as const;
const WORKFLOW_COUNT_CLOSED_WORKFLOWS = ["workflow_report", "count_closed_workflows"] as const;
const WORKFLOW_COUNT_OPEN_WORKFLOWS = ["workflow_report", "count_open_workflows"] as const;
const WORKFLOW_COUNT_WORKFLOWS = ["workflow_report", "count_workflows"] as const;
const WORKFLOW_DEADLINE_CURRENT_STEP = ["workflow_report", "deadline_current_workflow_step"] as const;
// eslint-disable-next-line @typescript-eslint/no-unused-vars -- Exists in the view, but not used in the report yet
const WORKFLOW_DEADLINE = ["workflow_report", "deadline_current_workflow"] as const;

const DASHBOARD_ID = "workflows-report";
const QUERY: Query = {
  dimensions: [WORKFLOW_TYPE, WORKFLOW_STEP_OWNER].map(cubeDimensionToKey),
  measures: [WORKFLOW_COUNT_OPEN_WORKFLOWS, WORKFLOW_COUNT_CLOSED_WORKFLOWS].map(cubeDimensionToKey),
  timeDimensions: [
    {
      dimension: cubeDimensionToKey(WORKFLOW_CREATED_AT),
      granularity: "month",
    },
  ],
};

const FILTER_CONFIG = [
  {
    valueDimension: WORKFLOW_TYPE,
    labelDimension: WORKFLOW_TYPE,
    placeholder: (t: TFunction<"insights">) => t("workflows.workflow_type_column_title"),
    filterFormat: (value: unknown, t: TFunction<"insights">) => t("workflows.workflow_type_filter_label", { value }),
  },
];

const MULTI_KPI_CONFIG = {
  kpis: [
    {
      dimension: WORKFLOW_COUNT_OPEN_WORKFLOWS,
      label: (t: TFunction<"insights">) => t("workflows.count_open_workflows_column_title"),
      aggregation: "sum" as const,
    },
    {
      dimension: WORKFLOW_COUNT_CLOSED_WORKFLOWS,
      label: (t: TFunction<"insights">) => t("workflows.count_closed_workflows_column_title"),
      aggregation: "sum" as const,
    },
  ],
};

const COLUMNS_CONFIG: Column[] = [
  {
    dimension: WORKFLOW_TYPE,
    title: (t: TFunction<"insights">) => t("workflows.workflow_type_column_title"),
    sortable: true,
    width: "dynamic",
    align: "left",
  },
  {
    dimension: WORKFLOW_STEP_OWNER,
    title: (t: TFunction<"insights">) => t("workflows.workflow_step_owner_column_title"),
    sortable: true,
    width: "dynamic",
    align: "left",
  },
  {
    dimension: WORKFLOW_COUNT_CLOSED_WORKFLOWS,
    title: (t: TFunction<"insights">) => t("workflows.count_closed_workflows_column_title"),
    sortable: true,
    width: "dynamic",
    align: "right",
  },
  {
    dimension: WORKFLOW_COUNT_OPEN_WORKFLOWS,
    title: (t: TFunction<"insights">) => t("workflows.count_open_workflows_column_title"),
    sortable: true,
    width: "dynamic",
    align: "right",
  },
];

const useOpenWorkflowsByAssigneeConfig = (): ComponentProps<typeof BarChartWidget> => {
  const { t } = useTranslation("insights");

  return useMemo(
    () =>
      ({
        title: t("workflows.kpi_title_open_steps_per_owner"),
        query: {
          dimensions: [WORKFLOW_STEP_OWNER].map(cubeDimensionToKey),
          measures: [WORKFLOW_COUNT_OPEN_WORKFLOWS].map(cubeDimensionToKey),
          timeDimensions: [
            {
              dimension: cubeDimensionToKey(WORKFLOW_CREATED_AT),
            },
          ],
        },
        pivotConfig: {
          x: [WORKFLOW_STEP_OWNER],
          y: [WORKFLOW_COUNT_OPEN_WORKFLOWS],
        },
        translations: {
          [cubeDimensionToKey(WORKFLOW_STEP_OWNER)]: t("workflows.workflow_step_owner_column_title"),
          [cubeDimensionToKey(WORKFLOW_COUNT_OPEN_WORKFLOWS)]: t("workflows.workflow_open_count_column_title"),
        },
      }) as const,
    [t],
  );
};

const useWorkflowsByStatusConfig = (): ComponentProps<typeof BarChartWidget> => {
  const { t } = useTranslation("insights");

  return useMemo(
    () =>
      ({
        title: t("workflows.kpi_title_workflows_by_type"),
        query: {
          dimensions: [WORKFLOW_TYPE].map(cubeDimensionToKey),
          measures: [WORKFLOW_COUNT_OPEN_WORKFLOWS, WORKFLOW_COUNT_CLOSED_WORKFLOWS].map(cubeDimensionToKey),
          timeDimensions: [
            {
              dimension: cubeDimensionToKey(WORKFLOW_CREATED_AT),
            },
          ],
        },
        pivotConfig: {
          x: [WORKFLOW_STEP_OWNER],
          y: [WORKFLOW_COUNT_OPEN_WORKFLOWS, WORKFLOW_COUNT_CLOSED_WORKFLOWS],
        },
        translations: {
          [cubeDimensionToKey(WORKFLOW_TYPE)]: t("workflows.workflow_type_column_title"),
          [cubeDimensionToKey(WORKFLOW_COUNT_OPEN_WORKFLOWS)]: t("workflows.workflow_open_count_column_title"),
          [cubeDimensionToKey(WORKFLOW_COUNT_CLOSED_WORKFLOWS)]: t("workflows.workflow_closed_count_column_title"),
        },
      }) as const,
    [t],
  );
};

const useCreatedWorkflowsPerMonthConfig = (): ComponentProps<typeof BarChartWidget> => {
  const { t } = useTranslation("insights");

  return useMemo(
    () =>
      ({
        title: t("workflows.kpi_title_created_workflows_per_month"),
        query: {
          measures: [WORKFLOW_COUNT_OPEN_WORKFLOWS, WORKFLOW_COUNT_CLOSED_WORKFLOWS].map(cubeDimensionToKey),
          timeDimensions: [
            {
              dimension: cubeDimensionToKey(WORKFLOW_CREATED_AT),
              granularity: "month",
            },
          ],
        },
        pivotConfig: {
          x: [WORKFLOW_CREATED_AT],
          y: [WORKFLOW_COUNT_OPEN_WORKFLOWS, WORKFLOW_COUNT_CLOSED_WORKFLOWS],
        },
        translations: {
          [cubeDimensionToKey(WORKFLOW_TYPE)]: t("workflows.workflow_type_column_title"),
          [cubeDimensionToKey(WORKFLOW_COUNT_OPEN_WORKFLOWS)]: t("workflows.workflow_open_count_column_title"),
          [cubeDimensionToKey(WORKFLOW_COUNT_CLOSED_WORKFLOWS)]: t("workflows.workflow_closed_count_column_title"),
        },
        orientation: "vertical",
        labelFormat: "month-name",
        selectCount: 0,
      }) as const,
    [t],
  );
};

const useDeadlineWorkflowsPerMonthConfig = (): ComponentProps<typeof BarChartWidget> => {
  const { t } = useTranslation("insights");

  return useMemo(
    () =>
      ({
        title: t("workflows.kpi_title_deadline_workflows_per_month"),
        query: {
          measures: [WORKFLOW_COUNT_WORKFLOWS].map(cubeDimensionToKey),
          timeDimensions: [
            {
              dimension: cubeDimensionToKey(WORKFLOW_DEADLINE_CURRENT_STEP),
              granularity: "month",
            },
          ],
        },
        pivotConfig: {
          x: [WORKFLOW_DEADLINE_CURRENT_STEP],
          y: [WORKFLOW_COUNT_WORKFLOWS],
        },
        translations: {
          [cubeDimensionToKey(WORKFLOW_COUNT_WORKFLOWS)]: t("workflows.workflow_count_column_title"),
          [cubeDimensionToKey(WORKFLOW_DEADLINE_CURRENT_STEP)]: t(
            "workflows.workflow_deadline_current_step_column_title",
          ),
        },
        orientation: "vertical",
        labelFormat: "month-name",
      }) as const,
    [t],
  );
};

export const WorkflowsReportPage = () => {
  const { t } = useTranslation("insights");
  const openWorkflowsByAssigneeConfig = useOpenWorkflowsByAssigneeConfig();
  const workflowsByStatusConfig = useWorkflowsByStatusConfig();
  const createdWorkflowsPerMonthConfig = useCreatedWorkflowsPerMonthConfig();
  const deadlineCurrentStepConfig = useDeadlineWorkflowsPerMonthConfig();

  usePageTitle(t("workflows.report_title"));

  return (
    <Page>
      <CubeProvider>
        <Dashboard dashboardId={DASHBOARD_ID} query={QUERY}>
          <DashboardReportPageTemplate>
            <DashboardReportPageTemplate.Header>
              <DashboardReportPageTemplate.Filters>
                <FilterWidget label={t("workflows.created_periode")} dimensions={FILTER_CONFIG} />
              </DashboardReportPageTemplate.Filters>
              <DashboardReportPageTemplate.Settings>
                <ViewMenu />
                <ViewActionsWidget columns={COLUMNS_CONFIG} />
              </DashboardReportPageTemplate.Settings>
            </DashboardReportPageTemplate.Header>
            <DashboardReportPageTemplate.KpiContainer>
              <DashboardReportPageTemplate.Kpi>
                <MultiKPIWidget title={t("workflows.kpi_title_workflow_totals")} {...MULTI_KPI_CONFIG} />
              </DashboardReportPageTemplate.Kpi>
              <DashboardReportPageTemplate.Kpi width="wide">
                <BarChartWidget {...openWorkflowsByAssigneeConfig} />
              </DashboardReportPageTemplate.Kpi>
              <DashboardReportPageTemplate.Kpi>
                <BarChartWidget {...deadlineCurrentStepConfig} />
              </DashboardReportPageTemplate.Kpi>
              <DashboardReportPageTemplate.Kpi width="wide">
                <BarChartWidget {...createdWorkflowsPerMonthConfig} />
              </DashboardReportPageTemplate.Kpi>
              <DashboardReportPageTemplate.Kpi width="wide">
                <BarChartWidget {...workflowsByStatusConfig} />
              </DashboardReportPageTemplate.Kpi>
            </DashboardReportPageTemplate.KpiContainer>
            <DashboardReportPageTemplate.Table>
              <TableWidget title={t("workflows.time_entry_table_title")} columns={COLUMNS_CONFIG} />
            </DashboardReportPageTemplate.Table>
          </DashboardReportPageTemplate>
        </Dashboard>
      </CubeProvider>
    </Page>
  );
};
