// istanbul ignore file -- This is lazy loading logic
import { Suspense, lazy } from "react";
import { useEmbeddableScript } from "../../hooks/useEmbeddableScript/useEmbeddableScript";

const Component = lazy(() => import("./EmbeddableDashboardPage"));

type EmbeddableDashboardPageProps = {
  sdkUrl: string;
};

export const EmbeddableDashboardPage = ({ sdkUrl }: EmbeddableDashboardPageProps) => {
  useEmbeddableScript(sdkUrl);

  return (
    <Suspense fallback={null}>
      <Component />
    </Suspense>
  );
};
