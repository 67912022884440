import { skipToken } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { Select, Tooltip, TypedDropdownChangeEvent } from "@simplicate/ui";
import { format } from "date-fns";
import { memo, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  ListSubscriptionsThatCanBeInvoicedTogetherBody,
  useListSubscriptionsThatCanBeInvoicedTogetherQuery,
} from "../../../data";
import { useProjectForm } from "../hooks/useProjectServiceForm";
import { ProjectServiceForm } from "../types";
import styles from "./InvoiceTogetherWith.module.scss";

type useSubscriptionsThatCanBeInvoicedTogetherValues = Pick<ProjectServiceForm, "subscriptionCycle" | "timeframe"> & {
  currentServiceId?: string;
};

const useSubscriptionsThatCanBeInvoicedTogetherValidator = (
  fields: Partial<ListSubscriptionsThatCanBeInvoicedTogetherBody>,
) => {
  const { t } = useTranslation("project_services");
  const { startDate, projectId, subscriptionCycle } = fields;
  const missingFields: string[] = [];

  if (!startDate) missingFields.push(t("invoice_together_with_missing_start_date"));
  if (!projectId) missingFields.push(t("invoice_together_with_missing_project_id"));
  if (!subscriptionCycle) missingFields.push(t("invoice_together_with_missing_subscription_cycle"));

  return { isValid: missingFields.length === 0, missingFields };
};

const useSubscriptionsThatCanBeInvoicedTogether = ({
  timeframe: { startDate },
  subscriptionCycle,
}: useSubscriptionsThatCanBeInvoicedTogetherValues) => {
  const { id: projectId, serviceId } = useParams();

  const fields = {
    projectId,
    currentServiceId: serviceId,
    startDate: startDate ? format(startDate, "yyyy-MM-dd") : undefined,
    subscriptionCycle,
  };

  const { isValid, missingFields } = useSubscriptionsThatCanBeInvoicedTogetherValidator(fields);

  const { data: services } = useListSubscriptionsThatCanBeInvoicedTogetherQuery(
    isValid ? (fields as ListSubscriptionsThatCanBeInvoicedTogetherBody) : skipToken,
  );

  return { services: services ?? [], missingFields, isValid };
};

const useInvoiceTogetherWithProps = () => {
  const { serviceId } = useParams();
  const { values, setInvoiceTogetherWith } = useProjectForm();
  const {
    services: subscriptionsThatCanBeInvoicedTogether,
    missingFields: missingSubscriptionsThatCanBeInvoicedTogetherFields,
    isValid,
  } = useSubscriptionsThatCanBeInvoicedTogether({
    currentServiceId: serviceId,
    timeframe: values.timeframe,
    subscriptionCycle: values.subscriptionCycle,
  });

  useEffect(() => {
    const ids = subscriptionsThatCanBeInvoicedTogether.map(({ id }) => id);

    if (values.invoiceTogetherWith && !ids.includes(values.invoiceTogetherWith)) {
      setInvoiceTogetherWith?.(undefined);
    }
  }, [subscriptionsThatCanBeInvoicedTogether, values.invoiceTogetherWith, setInvoiceTogetherWith]);

  return {
    services: subscriptionsThatCanBeInvoicedTogether,
    missingFields: missingSubscriptionsThatCanBeInvoicedTogetherFields,
    isValid,
    invoiceTogetherWith: values.invoiceTogetherWith,
    setInvoiceTogetherWith,
  };
};

const InvoiceTogetherWithContent = memo(function InvoiceTogetherWithContent({
  services,
  missingFields,
  isValid,
  invoiceTogetherWith,
  setInvoiceTogetherWith,
}: ReturnType<typeof useInvoiceTogetherWithProps>) {
  const { t } = useTranslation("project_services");

  const options = services?.map(({ id, description }) => ({
    label: description,
    value: id,
  }));

  let tooltip = "";
  if (services.length === 0) tooltip = t("invoice_together_with_empty");
  if (missingFields.length > 0) tooltip = t("invoice_together_with_disabled", { fields: missingFields.join(", ") });

  const select = (
    <div className={styles.select}>
      <Select
        testId="invoice_together_with_select"
        label={t("invoice_together_with")}
        options={options}
        onChange={(event: TypedDropdownChangeEvent<string>) => setInvoiceTogetherWith?.(event.target.value)}
        value={invoiceTogetherWith}
        disabled={!isValid || services.length === 0}
        placeholder={t("invoice_together_with_placeholder")}
        showClear
      />
    </div>
  );

  return tooltip ? (
    <Tooltip message={tooltip} position="right">
      {select}
    </Tooltip>
  ) : (
    select
  );
});

export const InvoiceTogetherWith = () => <InvoiceTogetherWithContent {...useInvoiceTogetherWithProps()} />;
