import { useTranslation } from "@simplicate/translations";
import { Checkbox } from "@simplicate/ui";
import { memo } from "react";
import { useProjectForm } from "../hooks/useProjectServiceForm";

const useRegistrationTimeframeProps = () => {
  const { values, setHasRegistrationTimeFrame } = useProjectForm();

  return {
    hasRegistrationTimeFrame: values.hasRegistrationTimeFrame,
    setHasRegistrationTimeFrame,
  };
};

const RegistrationTimeframeContent = memo(function RegistrationTimeframeContent({
  hasRegistrationTimeFrame,
  setHasRegistrationTimeFrame,
}: ReturnType<typeof useRegistrationTimeframeProps>) {
  const { t } = useTranslation("project_services");

  return (
    <Checkbox
      name="hasRegistrationTimeFrame"
      value={hasRegistrationTimeFrame ?? false}
      onChange={/* istanbul ignore next */ (event) => setHasRegistrationTimeFrame?.(event.target.checked)}
      label={t("deviating_registration_timeframe")}
    />
  );
});

export const RegistrationTimeframeToggle = () => <RegistrationTimeframeContent {...useRegistrationTimeframeProps()} />;
