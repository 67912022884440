import { InvoiceMethod, useFeatureFlag } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { DialogHandle } from "@simplicate/ui";
import { UniqueComponentId } from "primereact/utils";
import { memo, RefObject, useMemo } from "react";
import { CanRegisterToggleHeader } from "./CanRegisterToggleHeader/CanRegisterToggleHeader";
import { IsPlannableRadioButton } from "./fields/IsPlannableRadioButton";
import { FormikErrors } from "./FormikErrors";
import { HourTypeGrid } from "./grids/HourTypeGrid";
import { HourTypesTotalsInfo } from "./grids/HourTypeGrid/HourTypeTotalsInfo/HourTypesTotalsInfo";
import { useProjectForm } from "./hooks/useProjectServiceForm";
import styles from "./ProjectServicePageHourSection.module.scss";
import { TarrifRateDeviationAlert } from "./TarrifRateDeviationAlert";

const useHourTypeSectionProps = () => {
  const { hourTypeHandlers: handlers, values, touched, errors } = useProjectForm();

  const hasResourcePlanner = useFeatureFlag("resource-planner").enabled;

  const isCanRegisterHoursLocked = useMemo(
    () =>
      (values.hourTypes?.some(({ hasRegistrations }) => hasRegistrations) ?? false) &&
      (values.canRegisterHours ?? false),
    [values.canRegisterHours, values.hourTypes],
  );

  if (!handlers) {
    throw new Error("useProjectForm must be used within a ProjectServicePage component");
  }

  return {
    handlers,
    hasResourcePlanner,
    isCanRegisterHoursLocked,
    defaultService: values.defaultService,
    canRegisterHours: values.canRegisterHours,
    hourTypes: values.hourTypes,
    hourTypesSpecifiedTotal: values.hourTypesSpecifiedTotal,
    isPlannable: values.isPlannable,
    invoiceMethod: values.invoiceMethod,
    employeeHourlyRates: values.employeeHourlyRates,
    hasAssignments: values.hasAssignments,
    touched,
    errors,
  };
};

type HourTypeSectionProps = {
  hasHourTypeDeviation: boolean;
  hourlyRatesTariffsDialogRef?: RefObject<DialogHandle<unknown>>;
};

const HourTypeSectionContent = memo(function HourTypeSectionContent({
  handlers,
  hasResourcePlanner,
  isCanRegisterHoursLocked,
  defaultService,
  canRegisterHours,
  hourTypes,
  hourTypesSpecifiedTotal,
  isPlannable,
  invoiceMethod,
  employeeHourlyRates,
  hasAssignments,
  touched,
  errors,
  hasHourTypeDeviation,
  hourlyRatesTariffsDialogRef,
}: HourTypeSectionProps & ReturnType<typeof useHourTypeSectionProps>) {
  const { t } = useTranslation("project_services");

  const isDefaultServiceUndefined = defaultService === undefined;
  const isHourTypeGridDisabled = !canRegisterHours || isDefaultServiceUndefined;
  const invoiceMethodIsTimeAndExpenses = invoiceMethod === InvoiceMethod.time_and_expenses;
  const hasEmployeeHourlyRates = Boolean(employeeHourlyRates);

  return (
    <>
      <CanRegisterToggleHeader
        isDefaultServiceUndefined={isDefaultServiceUndefined}
        canRegister={canRegisterHours ?? false}
        setCanRegister={handlers.setCanRegisterHours}
        isCanRegisterLocked={isCanRegisterHoursLocked}
        labelText={t("hour_registration")}
        testId="hour_registration_toggle"
        tooltipText={t("cannot_disable_can_register_hours")}
        errors={[
          <FormikErrors touched={touched.canRegisterHours} error={errors?.hourTypes} key={UniqueComponentId()} />,
          <FormikErrors
            touched={touched.canRegisterHours}
            key={UniqueComponentId()}
            error={errors?.canRegisterHours}
          />,
        ]}
      />
      {hasResourcePlanner && (
        <IsPlannableRadioButton
          value={isPlannable ?? false}
          onChange={handlers.setIsPlannable}
          disabled={isHourTypeGridDisabled || hasAssignments}
          tooltip={hasAssignments ? t("is_plannable_locked_tooltip") : undefined}
        />
      )}
      {hasHourTypeDeviation && !hasEmployeeHourlyRates && (
        <TarrifRateDeviationAlert
          onClickAction={/* istanbul ignore next */ () => void hourlyRatesTariffsDialogRef?.current?.open()}
        />
      )}
      <div className={styles.hourTypeGridContainer}>
        <HourTypeGrid
          value={hourTypes}
          onAmountChange={handlers.setAmountForHourType}
          onReorderHourTypes={handlers.setHourTypes}
          onHourlyRateChange={handlers.setHourlyRateForHourType}
          onIsInvoiceableToggled={handlers.toggleIsInvoiceableForHourTypes}
          onAddNewHourTypeEntry={handlers.addHourType}
          onCancelNewHourTypeEntry={handlers.removeNewHourTypeEntry}
          onRemoveHourTypeEntries={handlers.removeHourTypes}
          onChangeSpecifiedTotal={handlers.setHourTypesTotal}
          specifiedTotal={hourTypesSpecifiedTotal}
          showIsInvoiceableColumn={invoiceMethodIsTimeAndExpenses}
          disabled={isHourTypeGridDisabled}
          showTariffColumn={!hasEmployeeHourlyRates}
        />
        {!hasEmployeeHourlyRates && (
          <HourTypesTotalsInfo
            hourTypes={hourTypes}
            specifiedTotal={hourTypesSpecifiedTotal}
            setSpecifiedTotal={handlers.setHourTypesTotal}
          />
        )}
      </div>
    </>
  );
});

export const HourTypeSection = ({ hasHourTypeDeviation, hourlyRatesTariffsDialogRef }: HourTypeSectionProps) => (
  <HourTypeSectionContent
    {...useHourTypeSectionProps()}
    hasHourTypeDeviation={hasHourTypeDeviation}
    hourlyRatesTariffsDialogRef={hourlyRatesTariffsDialogRef}
  />
);
