import { Checkbox } from "@simplicate/ui";
import { Field } from "formik";
import { ServiceData } from "../../SalesConvertToProjectPage.types";
import { InputLabel } from "../InputLabel";

type ServiceCheckboxProps = {
  service?: ServiceData;
};

export const ServiceCheckbox = ({ service }: ServiceCheckboxProps) => {
  return (
    service && (
      <Field name={`services.${service.id}`} as={Checkbox}>
        <InputLabel
          name={service.name}
          invoiceMethod={service.invoiceMethod}
          subscriptionCycle={service.subscriptionCycle}
        />
      </Field>
    )
  );
};
