import { DataTableValueArray } from "primereact/datatable";
import { ChangeEvent, ReactNode, useCallback, useEffect, useState } from "react";
import { SelectionHeaderCell } from "../Column";

type useTableSelectionProps<TValue extends DataTableValueArray> = {
  value: TValue;
  CollectiveActions?: ({ selection }: { selection: TValue }) => ReactNode;
};

export const useTableSelection = <TValue extends DataTableValueArray>({
  value,
  CollectiveActions,
}: useTableSelectionProps<TValue>) => {
  // @ts-expect-error -- The generic argument extends an array type -- this should be valid
  const [selection, setSelection] = useState<TValue>([]);

  const updateSelection = useCallback((e: { value: TValue }) => {
    setSelection(e.value);
  }, []);

  const toggleSelectAll = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const selectAll = event.target.checked;

      if (selectAll) {
        setSelection(value);
      } else {
        // @ts-expect-error -- The generic argument extends an array type -- this should be valid
        setSelection([]);
      }
    },
    [value],
  );

  const toggleSelectionOfRecord = useCallback((record: TValue[number]) => {
    // @ts-expect-error -- The generic argument extends an array type -- this should be valid
    setSelection((prevSelection: TValue) => {
      if (prevSelection.includes(record)) {
        return prevSelection.filter((r) => r !== record);
      }

      return [...prevSelection, record];
    });
  }, []);

  useEffect(() => {
    const invalidSelection = selection.filter((selected) => !value.includes(selected));

    if (invalidSelection.length === 0) return;

    const newSelection = selection.filter((selected) => !invalidSelection.includes(selected)) as TValue;

    updateSelection({ value: newSelection });
  }, [value, selection, updateSelection]);

  return {
    selection,
    selectable: true,
    selectionMode: "multiple" as const,
    selectAllComponent: (
      <SelectionHeaderCell
        value={selection.length < value.length && selection.length > 0 ? "indeterminate" : selection.length !== 0}
        selection={selection}
        toggleSelectAll={toggleSelectAll}
        CollectiveActions={CollectiveActions}
      />
    ),
    onSelectionChange: updateSelection,
    toggleSelectionOfRecord,
  };
};
