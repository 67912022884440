import { Slice, SliceCaseReducers, SliceSelectors, createSlice as createReduxSlice } from "@reduxjs/toolkit";
import { Reducer, combineReducers } from "redux";
import { PersistedState, createMigrate, persistReducer } from "redux-persist";
import localStorage from "redux-persist/lib/storage";
import { rootReducer } from "../slices";
import { defaultReducers, persistor, store } from "../store";

type SliceConfiguration = {
  /**
   * Namespace in which to inject the slice. State from this slice will be available at
   * `state.<namespace>.<your slice name>`.
   *
   * @example
   * ```ts
   * createSlice({ namespace: "insights" }, { reducerPath: "dashboard_views", ... });
   * const { state: { insights: { dashboard_views } } } = useAppSelector();
   * ```
   */
  namespace: string;

  /**
   * When provided, this slice will be persisted in local storage under this key, otherwise the slice will not be
   * persisted at all.
   */
  localStorageKey?: string;

  /**
   * Current version of the slice. Increment this value when you make breaking changes to the slice.
   * Also provide `config.migrations` if you need to migrate the data.
   *
   * @see https://github.com/rt2zz/redux-persist/blob/HEAD/docs/migrations.md
   */
  version: number;

  config?: {
    blacklist?: string[];
    whitelist?: string[];
    migrations?: Record<string, unknown>;
  };
};

const namespaceReducers: Record<string, object> = {};

function withPersistor(
  reducer: Reducer,
  key: string,
  version: number,
  { migrations, ...config }: SliceConfiguration["config"] = {},
) {
  const migrate = migrations
    ? createMigrate(migrations as Record<string, (state: PersistedState) => PersistedState>, { debug: false })
    : undefined;

  return persistReducer({ ...config, migrate, key, version, storage: localStorage }, reducer);
}

function buildNamespaceReducer(slice: Slice, { localStorageKey, namespace, config, version }: SliceConfiguration) {
  const reducer = localStorageKey ? withPersistor(slice.reducer, localStorageKey, version, config) : slice.reducer;

  const combined = combineReducers({
    ...namespaceReducers[namespace],
    [slice.reducerPath]: reducer,
  });

  namespaceReducers[namespace] = combined;

  return combined;
}

function injectSlice(slice: Slice, options: SliceConfiguration) {
  const reducer = buildNamespaceReducer(slice, options);

  store.replaceReducer(
    combineReducers({
      ...defaultReducers,
      state: rootReducer.inject({ reducerPath: options.namespace, reducer }, { overrideExisting: true }),
    }),
  );

  if (options.localStorageKey) {
    persistor.persist();
  }
}

export function createSlice<
  TState = object,
  TSliceReducers extends SliceCaseReducers<TState> = SliceCaseReducers<TState>,
  TName extends string = string,
  TSliceSelectors extends SliceSelectors<TState> = SliceSelectors<TState>,
  TReducerPath extends string = string,
>(
  options: SliceConfiguration,
  sliceOptions: Parameters<typeof createReduxSlice<TState, TSliceReducers, TName, TSliceSelectors, TReducerPath>>[0],
) {
  const slice = createReduxSlice<TState, TSliceReducers, TName, TSliceSelectors, TReducerPath>(sliceOptions);

  injectSlice(slice, options);

  return slice;
}
