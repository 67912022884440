import { SubscriptionCycle } from "@simplicate/api-client";
import { useTranslation, type TFunction } from "@simplicate/translations";
import { Select, Tooltip, TypedDropdownChangeEvent } from "@simplicate/ui";
import { memo } from "react";
import { useParams } from "react-router-dom";
import { useGetSubscriptionCyclesQuery } from "../../../data";
import { useProjectForm } from "../hooks/useProjectServiceForm";

function translateSubscriptionCycle(cycle: SubscriptionCycle, t: TFunction<"project_services", undefined>): string {
  switch (cycle) {
    case "monthly":
      return t("monthly");
    case "quarterly":
      return t("quarterly");
    case "halfYearly":
      return t("half_yearly");
    case "yearly":
      return t("yearly");
  }
}

const useSubscriptionCycleSelectProps = () => {
  const { data: options } = useGetSubscriptionCyclesQuery();
  const { serviceId } = useParams();
  const { values, errors, touched, setSubscriptionCycle } = useProjectForm();

  return {
    serviceId,
    options,
    subscriptionCycle: values.subscriptionCycle,
    touched: touched.subscriptionCycle,
    error: errors.subscriptionCycle,
    setSubscriptionCycle,
  };
};

export const SubscriptionCycleSelectContent = memo(function SubscriptionCycleSelectContent({
  serviceId,
  options,
  subscriptionCycle,
  touched,
  error,
  setSubscriptionCycle,
}: ReturnType<typeof useSubscriptionCycleSelectProps>) {
  const { t } = useTranslation("project_services");

  const compatibleOptions = options?.cycleOptions.map((option) => ({
    value: option,
    label: translateSubscriptionCycle(option, t),
  }));

  const isEditingService = serviceId !== undefined;
  const disabled = isEditingService;
  const disabledTooltip = isEditingService ? t("subscription_cycle_cannot_be_changed") : undefined;

  const select = (
    <Select
      testId="subscription_cycle_select"
      name="subscriptionCycle"
      label={t("subscription_cycle")}
      placeholder={t("subscription_cycle_placeholder")}
      emptyMessage={t("no_default_services")}
      value={subscriptionCycle}
      options={compatibleOptions}
      onChange={(event: TypedDropdownChangeEvent<SubscriptionCycle>) => setSubscriptionCycle?.(event.target.value)}
      invalid={touched && error !== undefined}
      disabled={disabled}
      filter={false}
    />
  );

  return (
    <>
      {!disabledTooltip && select}
      {disabled && disabledTooltip && (
        <Tooltip message={disabledTooltip} position="right">
          {select}
        </Tooltip>
      )}
    </>
  );
});

export const SubscriptionCycleSelect = () => <SubscriptionCycleSelectContent {...useSubscriptionCycleSelectProps()} />;
