import { CurrencyFormat } from "@simplicate/number-format";
import { useTranslation } from "@simplicate/translations";
import { Tooltip } from "@simplicate/ui";
import { memo } from "react";
import { Big } from "../../../data";
import { useProjectForm } from "../hooks/useProjectServiceForm";
import styles from "./InvoicePriceInput.module.scss";

const useInvoicePriceInputProps = () => {
  const { values, setInvoicePrice, defaultPrices } = useProjectForm();

  return {
    invoicePrice: values.invoicePrice,
    existsOnInvoice: values.existsOnInvoice,
    defaultPrices,
    setInvoicePrice,
  };
};

const InvoicePriceInputContent = memo(function InvoicePriceInputContent({
  invoicePrice,
  setInvoicePrice,
  existsOnInvoice,
  defaultPrices,
}: ReturnType<typeof useInvoicePriceInputProps>) {
  const { t } = useTranslation("project_services");

  const readOnly = defaultPrices?.isPriceLocked;

  const tooltip = (() => {
    if (existsOnInvoice) return t("invoice_price_locked_invoices_tooltip");
    if (defaultPrices?.isPriceLocked) return t("invoice_price_locked_default_service_tooltip");

    return undefined;
  })();

  const input = (
    <div className={styles.invoicePriceContainer}>
      <CurrencyFormat
        id="invoice_price_input"
        testId="invoice-price"
        displayType="input"
        size="normal"
        label={t("invoice_price_label")}
        textAlign="right"
        value={invoicePrice?.amount?.toString()}
        disabled={existsOnInvoice}
        readOnly={readOnly}
        onValueChange={
          /* istanbul ignore next -- Component is mocked as <span/> making input test cases impossible */
          ({ value }, { source }) => {
            if (source.valueOf() === "prop") {
              return;
            }

            if (value.length === 0) {
              return setInvoicePrice?.(undefined);
            }

            return setInvoicePrice?.(Big(value));
          }
        }
        onBlur={() => {
          if (invoicePrice?.amount === undefined) {
            setInvoicePrice?.(Big(0));
          }
        }}
      />
    </div>
  );

  return tooltip ? (
    <Tooltip message={tooltip} position="right">
      {input}
    </Tooltip>
  ) : (
    input
  );
});

export const InvoicePriceInput = () => <InvoicePriceInputContent {...useInvoicePriceInputProps()} />;
