import { useFeatureFlag } from "@simplicate/api-client";
import { postIframeMessage, useAppDispatch } from "@simplicate/state";
import { useTranslation } from "@simplicate/translations";
import { ActionDropdown, Icon } from "@simplicate/ui";
import { colorCommonGrey700, lightColorIconInvert } from "@simplicate-software/design-tokens";
import { useParams } from "react-router-dom";
import { ProjectDetailsPageTabs } from "../ProjectDetailsPageTabs";

export const ProjectDetailsServicePageTabs = () => {
  const { t } = useTranslation("project_services");
  const { id } = useParams<{ id: string }>();

  const { enabled: serviceUiEnabled } = useFeatureFlag("projects-new-service-ui");

  const dispatch = useAppDispatch();

  const openLegacyPopup = () => {
    dispatch(postIframeMessage({ eventName: "services_add" }));
  };

  return (
    <ProjectDetailsPageTabs
      actionButtons={
        serviceUiEnabled && (
          <ActionDropdown
            button={
              <ActionDropdown.Button testId="new-service-dropdown-button">
                {t("service_add")}
                <Icon icon="angleDown" color={lightColorIconInvert} />
              </ActionDropdown.Button>
            }
            testId="new-service-dropdown"
          >
            <ActionDropdown.Action to={`/projects/${id}/services/addservice`} testId="new-service-button">
              <Icon icon="stars" color={colorCommonGrey700} />
              {t("service_add_new")}
            </ActionDropdown.Action>

            <ActionDropdown.Action onClick={openLegacyPopup} testId="legacy-service-button">
              <Icon icon="plus" />
              {t("service_add_legacy")}
            </ActionDropdown.Action>
          </ActionDropdown>
        )
      }
    />
  );
};
