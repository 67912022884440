import { TFunction, useTranslation } from "@simplicate/translations";
import {
  Button,
  GroupedControls,
  Tag,
  TagList,
  Icon,
  usePortalContext,
  useIntersectionObserver,
  Portal,
  PAGE_STICKY_TOP,
  DateRangePicker,
} from "@simplicate/ui";
import { format } from "date-fns";
import { PropsWithChildren, useCallback, useMemo, useRef } from "react";
import { FilterActionsModal, DimensionValueSelect } from "../../components";
import {
  applyFilter,
  clearAllFilters,
  getDateRangeFilter,
  removeFilter,
  setDateRange,
  type Filter,
  useAppDispatch,
  useAppSelector,
  getAvailableFilters,
  getActiveFilters,
} from "../../data";
import { CubeDimension, cubeDimensionToKey, Formatter } from "../../types";
import styles from "./FilterWidget.module.scss";

const INTERSECTION_OBSERVER_OPTIONS = {
  // "out of view" when the element is entirely outside the observed box.
  threshold: 0,

  // Top UI (3rem) + height of itself (82px) = 130px
  // Negative values "lower" the observed boundary the element needs to cross to be out of view
  margin: "-130px",
};

export type DimensionFilterConfig = {
  valueDimension: CubeDimension;
  labelDimension: CubeDimension;
  placeholder?: (t: TFunction) => string;
  filterFormat?: (value: unknown, t: TFunction) => string;
  format?: Formatter;
};

type FilterWidgetProps = {
  label?: string;
  dimensions: DimensionFilterConfig[];
};

const StickyContainer = ({ children }: PropsWithChildren) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { targetRefs } = usePortalContext();
  const { inView } = useIntersectionObserver(containerRef.current, INTERSECTION_OBSERVER_OPTIONS);

  return (
    <>
      <div ref={containerRef}>{children}</div>
      {!inView && (
        <Portal targetRef={targetRefs[PAGE_STICKY_TOP]}>
          <div>{children}</div>
        </Portal>
      )}
    </>
  );
};

const AppliedFilter = ({
  label,
  valueDimension,
  onRemove,
  dimensions: config,
}: Filter & FilterWidgetProps & { onRemove: () => void }) => {
  const { t } = useTranslation("insights");
  const matchedDimensionConfig = config.find(
    ({ valueDimension: candidate }) => cubeDimensionToKey(candidate) === cubeDimensionToKey(valueDimension),
  );

  const text = matchedDimensionConfig?.filterFormat?.(label, t) ?? label;

  return <Tag text={text} onClose={onRemove} />;
};

export const FilterWidget = ({ dimensions, label }: FilterWidgetProps) => {
  const { t } = useTranslation("insights");
  const { filterValues, filters } = useAppSelector(getActiveFilters);
  const dateRange = useAppSelector(getDateRangeFilter);
  const dispatch = useAppDispatch();
  const availableFilters = useAppSelector(getAvailableFilters);

  const formattedFilters = useMemo(() => {
    return dimensions
      .map((filter, index) => {
        const stateFilter = availableFilters?.find((stateFilter) => stateFilter.dimension === filter.labelDimension);
        const order = stateFilter?.order ?? index;
        const isVisible = stateFilter?.visible ?? true;

        return {
          ...filter,
          order,
          hidden: !isVisible,
        };
      })
      .filter((filter) => !filter.hidden)
      .sort((a, b) => a.order - b.order);
  }, [availableFilters, dimensions]);

  const handleRemoveFilter = useCallback(
    (filter: Filter) => {
      dispatch(removeFilter(filter));
    },
    [dispatch],
  );

  const handleClearAllFilters = useCallback(() => {
    dispatch(clearAllFilters());
  }, [dispatch]);

  const applyFilterForDimensions = useCallback(
    (
      config: { labelDimension: CubeDimension; valueDimension: CubeDimension },
      values: { label: string; value: string }[],
    ) =>
      dispatch(
        applyFilter({
          config: {
            labelDimension: config.labelDimension,
            valueDimension: config.valueDimension,
          },
          values,
        }),
      ),
    [dispatch],
  );

  const dateRangeFilterLabel = useMemo(() => {
    const startDateLabel = format(dateRange.start, "dd MMM yyyy");
    const endDateLabel = format(dateRange.end, "dd MMM yyyy");

    return t("filters.time_filter", { start: startDateLabel, end: endDateLabel });
  }, [dateRange.start, dateRange.end, t]);

  const handleDateRangeChange = useCallback(
    (newDateRange: [start: Date | null | undefined, end: Date | null | undefined]) => {
      const [start, end] = newDateRange;

      if (start && end) {
        dispatch(setDateRange({ start, end }));
      }
    },
    [dispatch],
  );

  const dateRangeValue = useMemo(() => {
    return [dateRange.start, dateRange.end] as [Date, Date];
  }, [dateRange]);

  return (
    <StickyContainer>
      <div className={styles.filterWidgetContainer}>
        <div className={styles.controlsContainer}>
          <GroupedControls>
            <GroupedControls.Item>
              <DateRangePicker
                value={dateRangeValue}
                onValueChange={handleDateRangeChange}
                label={
                  <>
                    <span>{label ?? t("general.date_range_label")}</span>
                    <Icon icon="angleDown" />
                  </>
                }
                hasInlineLabel
              />
            </GroupedControls.Item>
            {formattedFilters.map((config: DimensionFilterConfig) => (
              <GroupedControls.Item key={cubeDimensionToKey(config.valueDimension)}>
                <DimensionValueSelect
                  key={cubeDimensionToKey(config.valueDimension)}
                  valueDimension={config.valueDimension}
                  labelDimension={config.labelDimension}
                  placeholder={config.placeholder?.(t)}
                  onChange={(newValues) => {
                    applyFilterForDimensions(config, newValues);
                  }}
                  value={filterValues[cubeDimensionToKey(config.valueDimension)]}
                  labelFormat={config.format}
                />
              </GroupedControls.Item>
            ))}
          </GroupedControls>
          {dimensions.length > 0 && (
            <FilterActionsModal
              filters={dimensions.map((config) => ({
                labelDimension: config.labelDimension,
                valueDimension: config.valueDimension,
                label: config.placeholder ? config.placeholder(t) : "",
                value: cubeDimensionToKey(config.valueDimension),
              }))}
            />
          )}
        </div>
        <TagList>
          <Tag text={dateRangeFilterLabel} />
          {filters.map((filter: Filter) => (
            <AppliedFilter
              {...filter}
              onRemove={() => handleRemoveFilter(filter)}
              dimensions={dimensions}
              key={`${cubeDimensionToKey(filter.valueDimension)}-${filter.value}`}
            />
          ))}
          <Button variant="subtle" size="small" onClick={handleClearAllFilters} disabled={filters.length === 0}>
            <Icon icon="times" />
            {t("filters.reset")}
          </Button>
        </TagList>
      </div>
    </StickyContainer>
  );
};
