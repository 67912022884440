import { CurrencyFormat } from "@simplicate/number-format";
import { useTranslation } from "@simplicate/translations";
import { Table, buildColumnProps } from "@simplicate/ui";
import { size8 } from "@simplicate-software/design-tokens";
import { memo } from "react";
import { Big } from "../../../../data";
import { useProjectForm } from "../../hooks/useProjectServiceForm";
import { Employee } from "./Employee/Employee";
import type { HourlyRateInForm } from "../../types";

const useEmployeeHourlyRatesGridProps = () => {
  const { values, setHourlyRateForEmployee } = useProjectForm();

  return {
    employeeHourlyRates: values.employeeHourlyRates,
    setHourlyRateForEmployee,
  };
};

const EmployeeHourlyRatesGridContent = memo(function EmployeeHourlyRatesGridContent({
  employeeHourlyRates,
  setHourlyRateForEmployee,
}: ReturnType<typeof useEmployeeHourlyRatesGridProps>) {
  const testId = "employee-hourly-rates-grid";
  const { t } = useTranslation("project_services");

  if (!employeeHourlyRates) {
    return null;
  }

  return (
    <Table testId={testId} value={employeeHourlyRates} emptyMessage={t("hourly_rate_employee_empty")}>
      <Table.Column
        {...buildColumnProps({
          header: t("hourly_rate_employee_header"),
          field: "employeeId",
          body: ({ id }: HourlyRateInForm) => id && <Employee id={id} />,
          width: "dynamic",
          alignContent: "center",
        })}
      />

      <Table.Column
        {...buildColumnProps({
          header: t("hourly_rate_employee_hourly_rate_header"),
          field: "hourlyRate",
          align: "right",
          body: ({ hourlyRate, id }: HourlyRateInForm) => (
            <CurrencyFormat
              testId={testId && `${testId}-input`}
              displayType="input"
              size="small"
              textAlign="right"
              value={hourlyRate.amount?.toString()}
              onValueChange={
                /* istanbul ignore next -- Component is mocked as <span/> making input test cases impossible */
                ({ value }) => setHourlyRateForEmployee!(id, Big(value || 0))
              }
            />
          ),
          width: size8,
        })}
      />
    </Table>
  );
});

export const EmployeeHourlyRatesGrid = () => <EmployeeHourlyRatesGridContent {...useEmployeeHourlyRatesGridProps()} />;
