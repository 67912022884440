import { Navigate } from "react-router-dom";
import { IFrameEnvironment, LegacyWebComponent, DebtorsTabs, OrganizationTabs, PersonTabs } from "../../components";
import { OrganizationSubHeader } from "../../components/NavigationWrapper/SubHeaders";
import { PersonSubHeader } from "../../components/NavigationWrapper/SubHeaders/PersonSubHeader";
import { RouteObject } from "../types";

export const crmRoutes: RouteObject<"crm" | "invoices">[] = [
  {
    path: "crm",
    handle: {
      theme: "crm",
      headerTitle: "module_crm",
      permissions: [
        {
          module_key: "crm",
          right_key: "view",
        },
      ],
    },
    children: [
      {
        index: true,
        element: <Navigate to="organizations" replace />,
      },
      {
        path: "organizations",
        element: <LegacyWebComponent />,
      },
      {
        path: "persons",
        element: <LegacyWebComponent />,
      },
      {
        path: "debtors",
        element: <IFrameEnvironment src="/v1/crm?tab=debtors" />,
        handle: {
          tabsComponent: DebtorsTabs,
          permissions: [
            {
              module_key: "invoices",
              right_key: "view",
            },
          ],
        },
      },
      {
        path: "organizations/:id",
        handle: {
          backNavigationPath: "/crm/organizations",
          subHeaderComponent: OrganizationSubHeader,
          tabsComponent: OrganizationTabs,
        },
        children: [
          {
            index: true,
            element: <Navigate to="overview" replace />,
          },
          {
            path: "overview",
            element: <LegacyWebComponent />,
          },
          {
            path: "general",
            element: <IFrameEnvironment src="/v1/crm/organization/view?id=:id&tab=general" />,
          },
          {
            path: "compliance",
            element: <IFrameEnvironment src="/v1/crm/organization/view?id=:id&tab=compliance" />,
          },
          {
            path: "contacts",
            element: <IFrameEnvironment src="/v1/crm/organization/view?id=:id&tab=personsatthisorganization" />,
          },
          {
            path: "employees",
            element: <IFrameEnvironment src="/v1/crm/organization/view?id=:id&tab=employees" />,
          },
          {
            path: "sales",
            element: <IFrameEnvironment src="/v1/crm/organization/view?id=:id&tab=acquisition" />,
          },
          {
            path: "projects",
            element: <IFrameEnvironment src="/v1/crm/organization/view?id=:id&tab=projects" />,
          },
          {
            path: "invoicing",
            element: <IFrameEnvironment src="/v1/crm/organization/view?id=:id&tab=client" />,
          },
          {
            path: "tasks",
            element: <IFrameEnvironment src="/v1/crm/organization/view?id=:id&tab=tasks" />,
          },
          {
            path: "documents",
            element: <IFrameEnvironment src="/v1/crm/organization/view?id=:id&tab=dossier" />,
          },
          {
            path: "tariff_agreements",
            element: <IFrameEnvironment src="/v1/crm/organization/view?id=:id&tab=tariff_agreements" />,
          },
        ],
      },
      {
        path: "persons/:id",
        handle: {
          backNavigationPath: "/crm/persons",
          subHeaderComponent: PersonSubHeader,
          tabsComponent: PersonTabs,
        },
        children: [
          {
            index: true,
            element: <Navigate to="overview" replace />,
          },
          {
            path: "overview",
            element: <LegacyWebComponent />,
          },
          {
            path: "general",
            element: <IFrameEnvironment src="/v1/crm/person/view?id=:id&tab=general" />,
          },
          {
            path: "compliance",
            element: <IFrameEnvironment src="/v1/crm/person/view?id=:id&tab=compliance" />,
          },
          {
            path: "contact-at",
            element: <IFrameEnvironment src="/v1/crm/person/view?id=:id&tab=personatorganizations" />,
          },
          {
            path: "sales",
            element: <IFrameEnvironment src="/v1/crm/person/view?id=:id&tab=acquisition" />,
          },
          {
            path: "projects",
            element: <IFrameEnvironment src="/v1/crm/person/view?id=:id&tab=projects" />,
          },
          {
            path: "invoices",
            element: <IFrameEnvironment src="/v1/crm/person/view?id=:id&tab=client" />,
          },
          {
            path: "tasks",
            element: <IFrameEnvironment src="/v1/crm/person/view?id=:id&tab=tasks" />,
          },
          {
            path: "documents",
            element: <IFrameEnvironment src="/v1/crm/person/view?id=:id&tab=dossier" />,
          },
        ],
      },
    ],
  },
];
