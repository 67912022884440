import { useTranslation } from "@simplicate/translations";
import { ActionDropdown, Icon } from "@simplicate/ui";
import { lightColorIconError } from "@simplicate-software/design-tokens";
import { memo } from "react";

type ActionDropdownProps = {
  id: string;
  isInvoiceable: boolean | undefined;
  hasRegistrations: boolean;
  showIsInvoiceableColumn: boolean;
  setCostTypeInEditMode: (id: string) => void;
  onIsInvoiceableToggled: (id: string) => void;
  onRemoveEntries: (ids: string[]) => void;
  onMoveToTop: () => void;
  onMoveToBottom: () => void;
  disabled: boolean;
};

export const CostTypeActionDropdown = memo(function CostTypeActionDropdown({
  id,
  isInvoiceable,
  hasRegistrations,
  setCostTypeInEditMode,
  showIsInvoiceableColumn,
  onIsInvoiceableToggled,
  onRemoveEntries,
  disabled,
  onMoveToTop,
  onMoveToBottom,
}: ActionDropdownProps) {
  const { t } = useTranslation("project_services");

  return (
    <ActionDropdown
      testId="cost-type-actions"
      button={
        <ActionDropdown.Button variant="subtle" disabled={disabled} testId="cost-type-actions-button">
          <Icon icon="ellipsisVertical" />
        </ActionDropdown.Button>
      }
    >
      <ActionDropdown.Action onClick={() => setCostTypeInEditMode?.(id)}>
        <Icon icon="pen" />
        {t("cost_type_edit_label")}
      </ActionDropdown.Action>

      <ActionDropdown.Action onClick={onMoveToTop}>
        <Icon icon="arrowUp" />
        {t("move_to_top")}
      </ActionDropdown.Action>

      <ActionDropdown.Action onClick={onMoveToBottom}>
        <Icon icon="arrowDown" />
        {t("move_to_bottom")}
      </ActionDropdown.Action>

      {showIsInvoiceableColumn && (
        <ActionDropdown.Action
          disabled={isInvoiceable && hasRegistrations}
          tooltip={isInvoiceable && hasRegistrations ? t("cannot_toggle_is_invoiceable_for_cost_type") : undefined}
          onClick={() => onIsInvoiceableToggled(id)}
        >
          <Icon icon="fileInvoice" />
          {isInvoiceable ? t("set_is_not_invoiceable") : t("set_is_invoiceable")}
        </ActionDropdown.Action>
      )}
      <ActionDropdown.Action
        tooltip={hasRegistrations ? t("cannot_remove_cost_type") : undefined}
        disabled={hasRegistrations}
        onClick={() => onRemoveEntries([id])}
      >
        <Icon icon="trash" color={lightColorIconError} />
        {t("remove_from_grid")}
      </ActionDropdown.Action>
    </ActionDropdown>
  );
});
