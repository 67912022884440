import { simplicateApiV3, createTagTypes } from "@simplicate/api-client";

type AuthToken = string & { __brand: "AuthToken" };

function asAuthToken(token: string): AuthToken {
  return Object.assign(token, {
    __brand: "AuthToken" as const,
  });
}

type EmbeddableAuthToken = {
  token: AuthToken;
};

const embeddableTokenTags = createTagTypes({
  tagPrefix: "Dashboard",
  tags: ["authToken"],
});

const endpointsV3 = simplicateApiV3
  .enhanceEndpoints({ addTagTypes: Object.values(embeddableTokenTags) })
  .injectEndpoints({
    endpoints: (builder) => ({
      getEmbeddableToken: builder.query<EmbeddableAuthToken, string>({
        query: (identifier) => ({
          url: `/insights.token.embeddable`,
          method: "POST",
          body: {
            identifier,
            type: "embeddable",
          },
        }),
        providesTags: (_result, _error, embeddableId) => [{ type: embeddableTokenTags.authToken, id: embeddableId }],
        transformResponse: (response: { token: string }) => ({
          token: asAuthToken(response.token),
        }),
      }),
    }),
  });

export const { useGetEmbeddableTokenQuery } = endpointsV3;
