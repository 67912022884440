import { InvoiceMethod, SubscriptionCycle } from "@simplicate/api-client";
import { Big } from "../../../data";
import type { DefaultPrices, ProjectServiceForm } from "../types/ProjectServiceForm.types";

const EMPTY_INVOICE_PRICE = { amount: Big(0), currency: "EUR" };

export const useDefaultInvoicePrice = (
  initialValues: ProjectServiceForm,
  defaultPrices?: DefaultPrices,
  subscriptionCycle?: SubscriptionCycle,
  invoiceMethod?: InvoiceMethod,
  defaultService?: string,
) => {
  const { defaultService: initialDefaultService, subscriptionCycle: initialSubscriptionCycle } = initialValues;

  if (!defaultPrices) return;

  if (invoiceMethod === InvoiceMethod.subscription && subscriptionCycle) {
    if (defaultService === initialDefaultService && subscriptionCycle === initialSubscriptionCycle) {
      return initialValues.invoicePrice;
    }

    return defaultPrices?.subscription[subscriptionCycle] ?? EMPTY_INVOICE_PRICE;
  }

  if (invoiceMethod === InvoiceMethod.fixed_price) {
    if (defaultService === initialDefaultService) {
      return initialValues.invoicePrice;
    }

    return defaultPrices?.fixedPrice;
  }
};
