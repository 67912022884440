import { simplicateApiV1 } from "@simplicate/api-client";
import { endOfDay, format, startOfDay } from "date-fns";

const DATE_FORMAT = "yyyy-MM-dd HH:mm:ss";

export type EmployeeStats = {
  readonly hours_registered: number;
  readonly hours_schedule: number;
  readonly value_registered: number;
};

export type EmployeeStatsQueryParams = {
  employeeId: string;
  startDate: Date;
  endDate: Date;
};

const endpoints = simplicateApiV1.injectEndpoints({
  endpoints: (builder) => ({
    getEmployeeStats: builder.query<{ data: EmployeeStats }, EmployeeStatsQueryParams>({
      query: ({ startDate, endDate, employeeId }) => ({
        url: "internal/hours/employeestat",
        params: {
          startDate: format(startOfDay(startDate), DATE_FORMAT),
          endDate: format(endOfDay(endDate), DATE_FORMAT),
          employeeId,
        },
      }),
    }),
  }),
});

export const { useGetEmployeeStatsQuery } = endpoints;
