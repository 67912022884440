import { useTranslation } from "@simplicate/translations";
import { Input } from "@simplicate/ui";
import { ChangeEventHandler, FocusEventHandler } from "react";
import { ErrorMessage } from "./ErrorMessage";

export const NameInput = ({
  error,
  isTouched,
  onChange,
  onBlur,
  name,
}: {
  error?: string;
  isTouched?: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onBlur: FocusEventHandler<HTMLInputElement>;
  name?: string;
}) => {
  const { t } = useTranslation("sales_services");

  const hasError = isTouched && !!error;

  return (
    <>
      <Input
        type="text"
        name="name"
        label={t("service_group.name_label")}
        onChange={onChange}
        onBlur={onBlur}
        value={name}
        hasError={hasError}
      />
      {hasError && <ErrorMessage>{error}</ErrorMessage>}
    </>
  );
};
