import { createTagTypes, simplicateApiV2 } from "@simplicate/api-client";

export type Project = {
  id: string;
  name: string;
  project_number?: string;
  organization?: {
    id: string;
    name: string;
  };
  person?: {
    id: string;
    full_name: string;
  };
  project_manager: {
    id: string;
    name: string;
  };
  my_organization_profile?: {
    id: string;
    organization: {
      id: string;
      name: string;
      relation_number: string;
    };
  };
  hours_rate_type?: "employee_tariff" | "itemtype_tariff";
};

const projectTags = createTagTypes({
  tagPrefix: "Project",
  tags: ["entity"],
});

const endpoints = simplicateApiV2.enhanceEndpoints({ addTagTypes: Object.values(projectTags) }).injectEndpoints({
  endpoints: (builder) => ({
    getProjectByOrganizationId: builder.query<{ data: Project[] }, string>({
      query: (id) => ({
        url: `/projects/project?q[organization.id]=${id}`,
      }),
      providesTags: [projectTags.entity],
    }),
    getProjectByPersonId: builder.query<{ data: Project[] }, string>({
      query: (id) => ({
        url: `/projects/project?q[person.id]=${id}`,
      }),
      providesTags: [projectTags.entity],
    }),
  }),
});

export const { useGetProjectByPersonIdQuery, useGetProjectByOrganizationIdQuery } = endpoints;
