import { Action, ThunkAction } from "@simplicate/state";
import { ViewPresetEndpoints } from "../../../endpoints/ViewPreset/ViewPreset";
import { setChangedMeta } from "../dashboardSlice";
import { DashboardRootState } from "../types";

export const requestSaveThunk =
  (
    saveType: "change-name" | "create" | "update",
  ): ThunkAction<void, DashboardRootState, unknown, Action<"Insights Dashboard State/setChangedMeta">> =>
  (dispatch, getState) => {
    const slice = getState().state.insights.dashboard_views;

    if (saveType !== "update") {
      const newChangedMeta = {
        ...slice.changedMeta,
        saveRequested: true,
        saveType,
      };

      dispatch(setChangedMeta(newChangedMeta));

      return;
    }

    if (!slice.activeDashboardId) {
      return;
    }

    const viewPreset = {
      ...slice.dashboards[slice.activeDashboardId],
      ...slice.localChanges,
    };

    void dispatch(ViewPresetEndpoints.updateViewPreset.initiate(viewPreset));
  };
