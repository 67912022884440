import { useTranslation } from "@simplicate/translations";
import { InputGroup, Modal, RadioButton } from "@simplicate/ui";
import { ChangeEvent, useState } from "react";
import { ServiceGroup } from "../../../../data";
import { Footer } from "./Footer";

type TypedRadioOnChangeEvent = Omit<ChangeEvent<HTMLInputElement>, "target"> & {
  target: Omit<EventTarget & HTMLInputElement, "target"> & {
    value: "delete-services" | "retain-services";
  };
};

type DeleteGroupModalProps = {
  group: ServiceGroup;
  deleteServiceGroup: (group: ServiceGroup, shouldDeleteServices: boolean) => void;
  onClose: () => void;
};

export const DeleteGroupModal = ({ group, deleteServiceGroup, onClose }: DeleteGroupModalProps) => {
  const { t } = useTranslation("grouped_services_manager");
  const [selectedValue, setSelectedValue] = useState<"delete-services" | "retain-services">("retain-services");

  const handleChange = (value: "delete-services" | "retain-services") => {
    setSelectedValue(value);
  };

  const handleDelete = () => {
    const shouldDeleteServices = selectedValue === "delete-services";

    deleteServiceGroup(group, shouldDeleteServices);
    onClose();
  };

  const radioOptions = [
    { value: "retain-services", label: t("retain_services") },
    { value: "delete-services", label: t("delete_services") },
  ];

  return (
    <Modal
      title={t("delete_group")}
      isOpen={true}
      onClose={onClose}
      footerComponent={<Footer deleteServices={handleDelete} cancel={onClose} />}
      testId="delete-group-modal"
    >
      <InputGroup direction="vertical" label={t("service_deletion_options")}>
        {radioOptions.map(({ value, label }) => (
          <RadioButton
            key={value}
            value={value}
            checked={selectedValue === value}
            name="delete-service-group-options"
            onChange={(event: TypedRadioOnChangeEvent) => handleChange(event.target.value)}
          >
            {label}
          </RadioButton>
        ))}
      </InputGroup>
    </Modal>
  );
};
