import { createTagTypes, simplicateApiV3 } from "@simplicate/api-client";

export type Relation = {
  id: string;
  name: string;
  relationNumber: string;
  relationEntityId: string;
  type: "organization" | "person";
};

export type Project = {
  id: string;
  relation: Relation;
};

const tags = createTagTypes({
  tagPrefix: "Project",
  tags: ["project"],
});

const endpoints = simplicateApiV3.enhanceEndpoints({ addTagTypes: Object.values(tags) }).injectEndpoints({
  endpoints: (builder) => ({
    getProject: builder.query<Project, string>({
      query: (projectId) => ({
        url: `/project.get/${projectId}`,
        method: "GET",
      }),
      providesTags: (_result, _error, projectId) => [{ type: tags.project, id: projectId }],
    }),
  }),
  overrideExisting: false,
});

export const { useGetProjectQuery } = endpoints;
