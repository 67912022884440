import { useGetVATCodesQuery } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { Select, Tooltip, TypedDropdownChangeEvent } from "@simplicate/ui";
import { memo } from "react";
import { useProjectForm } from "../hooks/useProjectServiceForm";

const useVATCodeSelectProps = () => {
  const { values, errors, touched, setVatCode } = useProjectForm();
  const { data: options } = useGetVATCodesQuery();

  return {
    options,
    defaultService: values.defaultService,
    existsOnInvoice: values.existsOnInvoice,
    vatCode: values.vatCode,
    touched: touched.vatCode,
    error: errors.vatCode,
    setVatCode,
  };
};

const VATCodeSelectContent = memo(function VATCodeSelectContent({
  options,
  defaultService,
  existsOnInvoice,
  vatCode,
  touched,
  error,
  setVatCode,
}: ReturnType<typeof useVATCodeSelectProps>) {
  const { t } = useTranslation("project_services");

  const isDefaultServiceUndefined = defaultService === undefined;
  const compatibleOptions = options?.map((option) => ({
    label: option.name,
    value: option.id,
  }));

  const tooltip = existsOnInvoice ? t("vat_code_locked_invoices_tooltip") : undefined;

  const select = (
    <Select
      testId="vat-code-select"
      name="vatCode"
      label={t("vat_code")}
      placeholder={t("vat_code_placeholder")}
      emptyMessage={t("no_vat_codes")}
      options={compatibleOptions}
      invalid={touched && error !== undefined}
      disabled={isDefaultServiceUndefined || existsOnInvoice}
      filter={false}
      onChange={(event: TypedDropdownChangeEvent<string>) => setVatCode?.(event.target.value)}
      value={vatCode}
    />
  );

  return tooltip ? (
    <Tooltip message={tooltip} position="down">
      {select}
    </Tooltip>
  ) : (
    select
  );
});

export const VATCodeSelect = () => <VATCodeSelectContent {...useVATCodeSelectProps()} />;
