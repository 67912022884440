import { useTranslation } from "@simplicate/translations";
import { TextArea } from "@simplicate/ui";
import { ChangeEventHandler, FocusEventHandler } from "react";
import { ErrorMessage } from "./ErrorMessage";

export const DescriptionInput = ({
  description,
  error,
  onChange,
  onBlur,
  isTouched,
}: {
  description?: string;
  error?: string;
  onChange: ChangeEventHandler<HTMLTextAreaElement>;
  onBlur: FocusEventHandler<HTMLTextAreaElement>;
  isTouched?: boolean;
}) => {
  const { t } = useTranslation("sales_services");

  const hasError = isTouched && !!error;

  return (
    <>
      <TextArea
        name="description"
        label={t("service_group.description_label")}
        label_tooltip={t("service_group.description_label_tooltip")}
        onChange={onChange}
        onBlur={onBlur}
        value={description}
        hasError={hasError}
      />
      {hasError && <ErrorMessage>{error}</ErrorMessage>}
    </>
  );
};
