import { Action, ThunkAction } from "@simplicate/state";
import { setChangedMeta } from "../dashboardSlice";
import { DashboardRootState } from "../types";

export const cancelMutationThunk =
  (): ThunkAction<void, DashboardRootState, unknown, Action<"Insights Dashboard State/setChangedMeta">> =>
  (dispatch, getState) => {
    const slice = getState().state.insights.dashboard_views;

    const changedMeta = {
      ...slice.changedMeta,
      saveRequested: false,
      deleteRequested: false,
    };

    dispatch(setChangedMeta(changedMeta));
  };
