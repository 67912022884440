import { useTranslation } from "@simplicate/translations";
import { Button, Icon, Modal, SortableList } from "@simplicate/ui";
import { useCallback, useMemo, useState } from "react";
import { getColumns, setColumns, useAppDispatch, useAppSelector } from "../../data";
import { Column, cubeDimensionToKey } from "../../types";
import styles from "./ViewActionsWidget.module.scss";

type ViewActionsWidgetProps = {
  columns: Column[];
};

type ColumnViewState = { id: string; column: Column; visible: boolean; disabled?: boolean }[];

export const ViewActionsWidget = ({ columns: allColumns }: ViewActionsWidgetProps) => {
  const { t } = useTranslation("insights");
  const [modalOpen, setModalOpen] = useState(false);
  const columns = useAppSelector(getColumns);
  const dispatch = useAppDispatch();

  const columnsState = useMemo(
    () =>
      allColumns
        .filter((column) => !column.hidden)
        .map((column) => ({
          id: cubeDimensionToKey(column.dimension),
          column,
          visible:
            columns.find(
              (stateColumn) => cubeDimensionToKey(stateColumn.dimension) === cubeDimensionToKey(column.dimension),
            )?.visible ??
            column.defaultVisible ??
            true,
          disabled: column.defaultVisible ?? false,
        })),
    [allColumns, columns],
  );

  const [columnsSelections, setColumnsSelections] = useState<ColumnViewState>(
    columnsState.filter((column) => column.visible),
  );
  const [columnsOrder, setColumnsOrder] = useState<ColumnViewState>(columnsState);

  const openModal = useCallback(() => {
    setColumnsSelections(columnsState.filter((column) => column.visible));
    setColumnsOrder(columnsState);

    setModalOpen(true);
  }, [columnsState]);
  const closeModal = useCallback(/* istanbul ignore next */ () => setModalOpen(false), []);

  const handleSubmit = useCallback(() => {
    const newColumns = [...columnsOrder].map((column, index) => ({
      dimension: column.column.dimension,
      order: index,
      visible: columnsSelections.some((selection) => selection.id === column.id),
    }));

    dispatch(setColumns(newColumns));

    closeModal();
  }, [columnsOrder, dispatch, closeModal, columnsSelections]);

  const handleReset = useCallback(() => {
    setColumnsSelections(columnsState.filter((column) => column.visible));
    setColumnsOrder(columnsState);
    closeModal();
  }, [columnsState, closeModal]);

  return (
    <>
      <Button size="medium" variant="secondary" onClick={openModal} title={t("general.configuration_columns")}>
        <Icon icon="slidersUp" />
        <span> {t("general.view_settings")}</span>
      </Button>
      <Modal
        title={t("general.data_grid")}
        isOpen={modalOpen}
        onClose={handleReset}
        footerComponent={
          <div className={styles.modalFooter}>
            <Button variant="primary" size="medium" type="submit" onClick={handleSubmit}>
              {t("general.button_add")}
            </Button>
            <Button variant="subtle" size="medium" onClick={handleReset}>
              {t("general.button_cancel")}
            </Button>
          </div>
        }
      >
        <div className={styles.modalContent}>
          <div className={styles.description}>{t("general.data_grid_description")}</div>
          <SortableList
            name="columnsList"
            testId="columnsList"
            data={columnsOrder}
            selection={columnsSelections}
            isSortable={true}
            isSelectable={true}
            onOrderChange={setColumnsOrder}
            onSelectionChange={setColumnsSelections}
          >
            {(item) => <div>{item.column.title(t)}</div>}
          </SortableList>
        </div>
      </Modal>
    </>
  );
};
