import { useTranslation } from "@simplicate/translations";
import { Datepicker, Tooltip } from "@simplicate/ui";
import { memo } from "react";
import { useProjectForm } from "../hooks/useProjectServiceForm";

const useInvoiceableFromDatepickerProps = () => {
  const { values, errors, touched, setInvoiceableFrom } = useProjectForm();

  return {
    invoiceableFrom: values.invoiceableFrom,
    invoiceInInstallments: values.invoiceInInstallments,
    existsOnInvoice: values.existsOnInvoice,
    touched: touched.invoiceableFrom,
    error: errors.invoiceableFrom,
    setInvoiceableFrom,
  };
};

const InvoiceableFromDatepickerContent = memo(function InvoiceableFromDatepickerContent({
  invoiceInInstallments,
  invoiceableFrom,
  existsOnInvoice,
  touched,
  error,
  setInvoiceableFrom,
}: ReturnType<typeof useInvoiceableFromDatepickerProps>) {
  const { t } = useTranslation("project_services");
  const canHaveInvoiceableFromValue = !invoiceInInstallments;
  const value = canHaveInvoiceableFromValue ? invoiceableFrom : undefined;
  const tooltip = existsOnInvoice ? t("invoiceable_from_locked_invoices_tooltip") : undefined;

  const datepicker = (
    <Datepicker
      name="invoiceableFrom"
      testId="invoiceable-from-datepicker"
      label={t("invoiceable_from")}
      value={value}
      onChange={/* istanbul ignore next */ (event) => setInvoiceableFrom?.(event.value)}
      invalid={touched && error !== undefined}
      disabled={!!invoiceInInstallments || !!existsOnInvoice}
      showButtonBar
    />
  );

  return tooltip ? (
    <Tooltip message={tooltip} position="right">
      {datepicker}
    </Tooltip>
  ) : (
    datepicker
  );
});

export const InvoiceableFromDatepicker = () => (
  <InvoiceableFromDatepickerContent {...useInvoiceableFromDatepickerProps()} />
);
