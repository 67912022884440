import { Toggle, Tooltip } from "@simplicate/ui";

type CanRegisterToggle = {
  label: React.ReactNode;
  value: boolean;
  onChange: (value: boolean) => void;
  disabled: boolean;
  tooltip?: string;
  testId?: string;
};

export const CanRegisterToggle = ({ label, value, onChange, disabled, tooltip, testId }: CanRegisterToggle) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  };

  const toggle = <Toggle testId={testId} onChange={handleChange} value={value} disabled={disabled} label={label} />;

  return tooltip ? (
    <Tooltip message={tooltip} position="right">
      {toggle}
    </Tooltip>
  ) : (
    toggle
  );
};
