import { useLazyGetV2IdentifierQuery } from "@simplicate/api-client";
import { clearMessages, useAppDispatch } from "@simplicate/state";
import { ConversionResult, iframeToReactUrl } from "@simplicate/utils";
import { RefObject, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { setWindowLocation } from "../../../routing/utils";
import { getWindowObject } from "./getWindowObject";
import { useNavigationBlocker } from "./useNavigationBlocker";

export const useNavigationIntercept = (
  iframeRef: RefObject<HTMLIFrameElement>,
  expectedRedirect: string | undefined = undefined,
) => {
  useNavigationBlocker();

  const { id: v2Identifier } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [fetchV2Identifier, results] = useLazyGetV2IdentifierQuery();
  const [urlConversionResult, setConversionResult] = useState<ConversionResult | undefined>(undefined);
  const [, setSearchParams] = useSearchParams();

  const navigateAndResetState = useCallback(
    (target: string) => {
      const currentLocation = getWindowObject().location;
      const targetAsUrl = new URL(target, getWindowObject().location.origin);

      // TODO: remove this. This is not desirable, just a simple band-aid fix for integration pages
      // SEE: https://simplicate.atlassian.net/browse/SIM-36666
      /* istanbul ignore next -- This is a band aid solution for SIM-36666 */
      if (expectedRedirect !== undefined) {
        const expectedTarget = v2Identifier ? expectedRedirect.replaceAll(":id", v2Identifier) : expectedRedirect;

        if (target === expectedTarget) {
          getWindowObject().history.replaceState(null, "", targetAsUrl);

          return;
        }
      }

      setConversionResult(undefined);

      if (targetAsUrl.origin !== currentLocation.origin) {
        getWindowObject().open(target, "_blank");

        return;
      }

      if (targetAsUrl.search && currentLocation.pathname === targetAsUrl.pathname) {
        // Iframe sometimes sets search params in the URL via a navigate event, so we catch the navigate, set the search params, but do not navigate
        setSearchParams(targetAsUrl.searchParams.toString(), { replace: true });

        return;
      }

      // When the target is the exact same as the current location, we could either reload the iframe content triggering
      // reinitialization of the simplicate app connector (iframe messages), or we just leave the (reloaded) document as
      // is, and not clear the received messages. Evidently, the latter is the preferred route as that requires fewer
      // page (re)loads.
      if (targetAsUrl.href !== currentLocation.href) {
        dispatch(clearMessages());
      }

      // Pass only required parts of the URL to the navigate function, not the origin, as navigate() can only handle
      // relative URLs.
      navigate({ pathname: targetAsUrl.pathname, search: targetAsUrl.search, hash: targetAsUrl.hash });
    },
    [dispatch, expectedRedirect, navigate, setSearchParams, v2Identifier],
  );

  useEffect(() => {
    if (
      results.data &&
      !results.isFetching &&
      urlConversionResult &&
      urlConversionResult.status === "pending-id-conversion"
    ) {
      navigateAndResetState(urlConversionResult.locationWithId(results.data));
    }
  }, [urlConversionResult, results, navigateAndResetState]);

  /**
   * Event hook for responding to the unload event of the iframe. Which is triggered as soon as
   * the document within the iframe is destroyed. Immediately after the unload event, the
   * location of the iframe document is updated to allow for the new document to be loaded. But
   * once the load event is triggered again, the new document has already been loaded. In order
   * to intercept navigation events of the iframe we need to read the new location of the iframe
   * at the first possible moment, such that we can prevent the new document from being loaded
   * twice.
   */
  /* istanbul ignore next -- the unload event cannot be triggered, this is also covered by the e2e suite */
  const onUnload = useCallback(() => {
    setTimeout(() => {
      try {
        const newLocation = iframeRef.current?.contentWindow?.location.href;

        if (newLocation && newLocation !== "about:blank") {
          const conversion = iframeToReactUrl(newLocation);

          if (conversion.status === "complete") {
            navigateAndResetState(conversion.location);
          } else if (conversion.status === "pending-id-conversion") {
            void fetchV2Identifier(conversion.queryArguments);
            setConversionResult(conversion);
          } else if (conversion.status === "no-known-conversions") {
            navigateAndResetState(newLocation);
          }
        }
      } catch (error) {
        console.error("Target location cannot be converted to React location", { cause: error });
        console.trace();
      }
    });
  }, [fetchV2Identifier, iframeRef, navigateAndResetState]);

  const convertUrlAndNavigate = useCallback(
    (targetLocation: string) => {
      try {
        const conversion = iframeToReactUrl(targetLocation);

        if (conversion.status === "complete") {
          navigateAndResetState(conversion.location);

          return;
        }

        if (conversion.status === "navigate-top-level") {
          setWindowLocation(conversion.location);

          return;
        }

        // If no conversion available, assume the payload url is already react compatible url and navigate
        if (conversion.status === "no-known-conversions") {
          navigateAndResetState(targetLocation);
        }
      } catch (error) {
        console.error("Target location cannot be converted to React location", { cause: error });
        console.trace();
      }
    },
    [navigateAndResetState],
  );

  return {
    navigate: convertUrlAndNavigate,
    navigationIntercepted: urlConversionResult?.status === "pending-id-conversion",
    onUnload,
  };
};
