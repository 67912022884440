import { Action, ThunkAction } from "@simplicate/state";
import { cubeDimensionToKey } from "../../../../types";
import { setFilters } from "../dashboardSlice";
import { DashboardRootState, Filter } from "../types";

export const removeFilterThunk =
  (
    filterToRemove: Filter,
  ): ThunkAction<void, DashboardRootState, unknown, Action<"Insights Dashboard State/setFilters">> =>
  (dispatch, getState) => {
    const slice = getState().state.insights.dashboard_views;
    const localChangesFilters = slice.localChanges.filters;
    const activeDashboardFilters = slice.activeDashboardId
      ? slice.dashboards[slice.activeDashboardId]?.filters
      : undefined;
    const currentFilters = localChangesFilters ?? activeDashboardFilters ?? [];

    const newFilters = [
      ...currentFilters.filter(
        (filter) =>
          !(
            cubeDimensionToKey(filter.valueDimension) === cubeDimensionToKey(filterToRemove.valueDimension) &&
            filter.value === filterToRemove.value
          ),
      ),
    ];

    dispatch(setFilters(newFilters));
  };
