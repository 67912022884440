import { skipToken } from "@simplicate/api-client";
import { TFunction, useTranslation } from "@simplicate/translations";
import { useCallback, useMemo, useState } from "react";
import * as Yup from "yup";
import { Service, ServiceGroup, useGetSalesQuery, useAddSaleServicesToExistingProjectMutation } from "../../data";
import { FormInitialValuesType } from "../../data/types/ProjectData";
import { ServiceData, ServiceGroupData } from "./SalesConvertToProjectPage.types";

export const OPTION_NONE = "__none__";

const projectData: FormInitialValuesType = {
  projectName: {
    options: [
      {
        value: "project:1",
        label: "Project 1",
      },
      {
        value: "project:2",
        label: "Project 2",
      },
      {
        value: "projects:354f1097e441755e",
        label: "Nieuw Duncan",
      },
    ],
  },
  projectManager: {
    value: "employee:1",
    options: [
      {
        value: "employee:1",
        label: "Manager A",
      },
      {
        value: "employee:2",
        label: "Manager B",
      },
    ],
  },
  relation: {
    type: "organization",
    value: "organization:1",
    options: [
      {
        value: "organization:1",
        label: "Organization 1",
      },
      {
        value: "organization:2",
        label: "Organization 2",
      },
    ],
  },
  myOrganizationProfile: null,
  hoursRate: {
    value: null,
    options1: [
      {
        value: "itemtype_tariff",
        label: "Urensoort uurtarief",
      },
      {
        value: "employee_tariff",
        label: "Medewerkwer uurtarief",
      },
    ],
    options2: ["itemtype_tariff"],
  },
  projectNumber: {
    value: "123SIM-0123", // tellers op bedrijfsprofiel
    lastAddedNumber: "123SIM-0122",
  },
  sale: {
    id: "sales:1",
  },
};

export type SalesConvertToProjectFormValues = {
  services: Record<string, boolean | string>;
};

const buildServiceData = (service: Service) => {
  return {
    id: service.id,
    name: service.description,
    invoiceMethod: service.invoiceMethod,
    subscriptionCycle: service.subscriptionCycle,
  } satisfies ServiceData;
};

const buildServiceGroupData = (group: ServiceGroup, t: TFunction) => {
  const servicesData: ServiceData[] = group.services.map((service) => buildServiceData(service));

  if (group.type === "single_optional") {
    servicesData.push({
      id: OPTION_NONE,
      name: t("no_conversion"),
      invoiceMethod: undefined,
      subscriptionCycle: undefined,
    });
  }

  return {
    id: group.id,
    name: group.name,
    services: servicesData,
    renderAs: group.type === "all_included" || group.type === "multiple_optional" ? "checkbox" : "radio",
  } satisfies ServiceGroupData;
};

export const useSalesConvertToProjectPageData = (saleId?: string, projectId?: string) => {
  const { t } = useTranslation("sales_convert_to_project");
  const [isNewProject, setIsNewProject] = useState<boolean>(!projectId);
  const [addSaleServicesToExistingProject] = useAddSaleServicesToExistingProjectMutation();

  const { data: salesData, isError: isGetSalesError } = useGetSalesQuery(
    /* istanbul ignore next */ saleId ?? skipToken,
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const groupedServices = useMemo(() => {
    if (!salesData) {
      return undefined;
    }

    const ungroupedServicesContainer = {
      id: "__ungrouped__",
      name: "",
      renderAs: "checkbox",
      services: salesData?.ungroupedServices.services?.map((service) => buildServiceData(service)) ?? [],
    } satisfies ServiceGroupData;

    const serviceGroups = salesData?.groupedServices?.map((group) => buildServiceGroupData(group, t)) ?? [];

    return [ungroupedServicesContainer, ...serviceGroups];
  }, [salesData, t]);

  const initialValues = useMemo(() => {
    if (!salesData) {
      return undefined;
    }

    const getInitialValue = (
      service: Service,
      group: Omit<ServiceGroup, "description" | "name" | "services" | "totals">,
    ) => {
      // @todo: implement logic for initial value based on selection entered in the quote acceptance portal.
      //   For now, use defaults based on group type.
      switch (group.type) {
        case "single_optional":
        case "single_required":
          return { [group.id]: false };
        case "multiple_optional":
          return { [service.id]: false };
        case "all_included":
        default:
          return { [service.id]: true };
      }
    };

    const ungroupedServiceIds = salesData.ungroupedServices.services.reduce(
      (acc: Record<string, boolean | string>, service) => {
        acc = {
          ...acc,
          ...getInitialValue(service, { id: "", type: "all_included" }),
        };

        return acc;
      },
      {},
    );

    const groupedServiceIds = salesData.groupedServices?.reduce((acc: Record<string, boolean | string>, group) => {
      group.services.forEach((service) => {
        acc = {
          ...acc,
          ...getInitialValue(service, group),
        };
      });

      return acc;
    }, {});

    const initialProjectNameValue = isNewProject
      ? {
          projectName: "",
        }
      : {
          projectId: projectId,
        };

    return {
      ...initialProjectNameValue,
      projectManager: projectData.projectManager.value,
      relation: projectData.relation.value,
      hoursRate: projectData.hoursRate.value,
      projectNumber: projectData.projectNumber?.value,
      myOrganizationProfile: projectData.myOrganizationProfile?.value,
      services: {
        ...ungroupedServiceIds,
        ...groupedServiceIds,
      },
    };
  }, [salesData, isNewProject, projectId]);

  const handleSubmit = useCallback(
    (values: SalesConvertToProjectFormValues) => {
      // istanbul ignore next -- this is extremely unlikely to happen
      if (!saleId) {
        return;
      }

      // Create an array of selected services, using the value of the key-value pair if it's a string (as is the case
      // for radio inputs) or the key where the value is true (as seen on checkbox inputs).
      // @todo make this smarter when besides selected services other types of inputs are added.
      const selectedServices = Object.entries(values.services).reduce((acc: string[], [key, value]) => {
        if (value === OPTION_NONE) {
          return acc;
        } else if (typeof value === "string") {
          acc.push(value);
        } else if (value) {
          acc.push(key);
        }

        return acc;
      }, []);

      if (projectId) {
        const payload = {
          saleId: saleId,
          projectId: projectId,
          saleServiceIds: selectedServices,
        };

        void addSaleServicesToExistingProject(payload);
      } else {
        // istanbul ignore next -- this is a placeholder for future functionality
        console.log("Create new project", selectedServices);
      }
    },
    [addSaleServicesToExistingProject, projectId, saleId],
  );

  const handleSetIsNewProject = useCallback(() => {
    setIsNewProject((prev) => !prev);
  }, []);

  const validationSchema = useMemo(() => {
    if (!salesData) {
      return Yup.object({});
    }

    const getServiceValidationRule = (
      service: Service,
      group: Omit<ServiceGroup, "description" | "name" | "totals">,
    ) => {
      // @todo: implement logic for initial value based on selection entered in the quote acceptance portal.
      //   For now, use defaults based on group type.
      switch (group.type) {
        case "single_optional":
          return { [group.id]: Yup.string().oneOf([...group.services.map((service) => service.id), OPTION_NONE]) };
        case "single_required":
          return { [group.id]: Yup.string().oneOf(group.services.map((service) => service.id)) };
        case "multiple_optional":
        case "all_included":
        default:
          return { [service.id]: Yup.boolean().required() };
      }
    };

    const ungroupedServicesValidationSchema = salesData.ungroupedServices.services.reduce((acc, service) => {
      acc = {
        ...acc,
        ...getServiceValidationRule(service, { id: "", type: "all_included", services: [] }),
      };

      return acc;
    }, {});

    const groupedServicesValidationSchema = salesData.groupedServices?.reduce((acc, group) => {
      group.services.forEach((service) => {
        acc = {
          ...acc,
          ...getServiceValidationRule(service, group),
        };
      });

      return acc;
    }, {});

    const servicesValidationSchema = Yup.object({
      ...ungroupedServicesValidationSchema,
      ...groupedServicesValidationSchema,
    });

    return Yup.object<SalesConvertToProjectFormValues>({
      services: servicesValidationSchema,
    });
  }, [salesData]);

  return {
    groupedServices,
    isGetSalesError,
    initialValues,
    handleSubmit,
    projectData,
    isNewProject,
    handleSetIsNewProject,
    validationSchema,
  };
};
