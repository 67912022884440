import { InvoiceMethod } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { Alert, Icon, Tooltip, Page, Footer, Button, useDialogRef, Spinner } from "@simplicate/ui";
import { useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Big } from "../../data";
import { DialogTariffRateDeviation } from "../DialogBase";
import { DialogDefaultServiceChanges, type DefaultServiceDialogForm } from "../DialogBase/DialogDefaultServiceChanges";
import { DefaultServiceSelect } from "./fields/DefaultServiceSelect";
import { DescriptionTextInput } from "./fields/DescriptionTextInput";
import { ExplanationTextArea } from "./fields/ExplanationTextArea";
import { InvoiceableFromDatepicker } from "./fields/InvoiceableFromDatepicker";
import { InvoicedUntil } from "./fields/InvoicedUntil";
import { InvoiceInInstallmentsCheckbox } from "./fields/InvoiceInInstallmentsCheckbox";
import { InvoiceMethodSelect } from "./fields/InvoiceMethodSelect";
import { InvoicePriceInput } from "./fields/InvoicePriceInput";
import { InvoiceQuantityInput } from "./fields/InvoiceQuantityInput";
import { InvoiceTogetherWith } from "./fields/InvoiceTogetherWith";
import { RegistrationTimeframe } from "./fields/RegistrationTimeframe";
import { RegistrationTimeframeToggle } from "./fields/RegistrationTimeframeToggle";
import { RevenueGroupSelect } from "./fields/RevenueGroupSelect";
import { SubscriptionCycleSelect } from "./fields/SubscriptionCycleSelect";
import { Timeframe } from "./fields/Timeframe";
import { Total } from "./fields/Total";
import { VATCodeSelect } from "./fields/VATCodeSelect";
import { EmployeeHourlyRatesGrid } from "./grids/EmployeeHourlyRatesGrid";
import { ProjectServiceContext, useProjectServiceForm } from "./hooks/useProjectServiceForm";
import { scrollToError } from "./hooks/useProjectServiceFormHelpers";
import { useRateAgreements } from "./hooks/useRateAgreements";
import { ProjectServiceHeader } from "./ProjectServiceHeader";
import styles from "./ProjectServicePage.module.scss";
import { ProjectServicePageTemplate } from "./ProjectServicePage.template";
import { CostTypeSection } from "./ProjectServicePageCostSection";
import { HourTypeSection } from "./ProjectServicePageHourSection";
import { ProjectServicePageSkeleton } from "./ProjectServicePageSkeleton";
import { ProjectServiceTotalInfo } from "./ProjectServiceTotalInfo";
import { TarrifRateDeviationAlert } from "./TarrifRateDeviationAlert";

type ProjectServicePageParams = {
  id: string;
  serviceId?: string;
};

// eslint-disable-next-line complexity -- This component is large,
export const ProjectServicePage = () => {
  const { t } = useTranslation("project_services");
  const navigate = useNavigate();

  const params = useParams<ProjectServicePageParams>();

  const { id: projectId, serviceId } = params;

  const defaultServiceDialogRef = useDialogRef<DefaultServiceDialogForm>();
  const employeeHourlyRatesTariffsDialogRef = useDialogRef();
  const hourlyRatesTariffsDialogRef = useDialogRef();

  const formRef = useRef<HTMLFormElement>(null);

  const projectServiceForm = useProjectServiceForm({
    projectId,
    dialogRef: defaultServiceDialogRef,
    serviceId,
    afterSubmitTarget: `/projects/${projectId}/services`,
  });

  const {
    values,
    errors,
    handleSubmit: handleFormikSubmit,
    isSubmitting,
    serviceLoading,
    defaultPrices,
    employeeRateAgreements,
    hourTypeRateAgreements,
    setHourlyRatesForEmployeesToAgreedRate,
    hourTypeHandlers,
  } = projectServiceForm;

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isSubmitting) handleFormikSubmit?.(e);
    scrollToError(errors, formRef);
  };

  const { hasDeviation: hasEmployeeHourlyRateDeviation, mergedRates: employeeRatesWithAgreedRate } = useRateAgreements({
    rates: values.employeeHourlyRates,
    agreements: employeeRateAgreements ?? [],
  });

  const { hasDeviation: hasHourTypeDeviation, mergedRates: hourTypeRatesWithAgreedRate } = useRateAgreements({
    rates: values.hourTypes?.map((hourType) => ({
      ...hourType,
      hourlyRate: {
        ...hourType.hourlyRate,
        amount: new Big(hourType.hourlyRate.amount ?? 0),
      },
    })),
    agreements: hourTypeRateAgreements ?? [],
  });

  const invoiceMethodIsSubscription = values.invoiceMethod === InvoiceMethod.subscription;
  const invoiceMethodIsFixedPrice = values.invoiceMethod === InvoiceMethod.fixed_price;

  if (serviceLoading) {
    return <ProjectServicePageSkeleton testId="project-service-page-skeleton" />;
  }

  return (
    <ProjectServiceContext.Provider value={projectServiceForm}>
      <Page
        footer={
          <Footer>
            <Button type="submit" form="projectservice-form" testId="submit-button">
              {t("buttons.save", { ns: "general" })}
            </Button>
            <Button onClick={() => navigate(-1)} variant="subtle" testId="cancel-button">
              {t("buttons.cancel", { ns: "general" })}
            </Button>
            {isSubmitting && (
              <div className={styles.isSubmittingContainer}>
                <Spinner testId="project-service-page-is-submitting-spinner" />
                <span className={styles.normalText}>{t("service_is_being_submitted")}</span>
              </div>
            )}
          </Footer>
        }
      >
        <DialogDefaultServiceChanges ref={defaultServiceDialogRef} />
        <DialogTariffRateDeviation
          ref={employeeHourlyRatesTariffsDialogRef}
          rateWithAgreedRate={employeeRatesWithAgreedRate}
          onRateChange={setHourlyRatesForEmployeesToAgreedRate}
          type="HOURLY_RATE"
        />
        <DialogTariffRateDeviation
          ref={hourlyRatesTariffsDialogRef}
          rateWithAgreedRate={hourTypeRatesWithAgreedRate}
          onRateChange={hourTypeHandlers.setHourlyRatesForHourTypesToAgreedRates}
          type="HOUR_TYPE"
        />

        <form onSubmit={handleSubmit} ref={formRef} id="projectservice-form" data-testid="project-service-page">
          <ProjectServicePageTemplate>
            <ProjectServicePageTemplate.Header>
              <Alert type="info">
                <span>{t("pilot_notice")}</span>
              </Alert>

              <ProjectServiceHeader />
            </ProjectServicePageTemplate.Header>
            <ProjectServicePageTemplate.Section>
              <ProjectServicePageTemplate.PropertiesBlock>
                <ProjectServicePageTemplate.Title>
                  <h4 className={styles.subTitle}>{t("basic_properties")}</h4>
                </ProjectServicePageTemplate.Title>

                <ProjectServicePageTemplate.PropertiesBlockColumn>
                  <DefaultServiceSelect />

                  <div className={styles.invoiceMethodWrapper}>
                    <div className={styles.invoiceMethod}>
                      <InvoiceMethodSelect />
                    </div>

                    {invoiceMethodIsSubscription && (
                      <div className={styles.subscriptionCycle}>
                        <SubscriptionCycleSelect />
                      </div>
                    )}
                  </div>

                  <div className={styles.timeframeLayout}>
                    <div className={styles.timeframeSection}>
                      <h4 className={styles.subTitle}>{t("timeframe")}</h4>

                      <Tooltip message={t("optional")} position="right">
                        <Icon icon="infoCircle" className={styles.infoIcon} />
                      </Tooltip>
                    </div>

                    <Timeframe />
                  </div>
                </ProjectServicePageTemplate.PropertiesBlockColumn>

                <ProjectServicePageTemplate.PropertiesBlockColumn>
                  <DescriptionTextInput />

                  <ExplanationTextArea />
                </ProjectServicePageTemplate.PropertiesBlockColumn>
              </ProjectServicePageTemplate.PropertiesBlock>

              <ProjectServicePageTemplate.OptionsBlock>
                <ProjectServicePageTemplate.Title>
                  <h4 className={styles.subTitle}>{t("advanced_properties")}</h4>
                </ProjectServicePageTemplate.Title>

                <ProjectServicePageTemplate.OptionsBlockColumn>
                  <div className={styles.column}>
                    <RevenueGroupSelect />

                    <VATCodeSelect />

                    {invoiceMethodIsSubscription && (
                      <>
                        <RegistrationTimeframeToggle />

                        {values.hasRegistrationTimeFrame && <RegistrationTimeframe />}
                      </>
                    )}
                  </div>
                </ProjectServicePageTemplate.OptionsBlockColumn>
              </ProjectServicePageTemplate.OptionsBlock>
            </ProjectServicePageTemplate.Section>
            <ProjectServicePageTemplate.Section>
              <ProjectServicePageTemplate.Content>
                <HourTypeSection
                  hasHourTypeDeviation={hasHourTypeDeviation}
                  hourlyRatesTariffsDialogRef={hourlyRatesTariffsDialogRef}
                />
              </ProjectServicePageTemplate.Content>

              {Boolean(values.employeeHourlyRates) && (
                <ProjectServicePageTemplate.Content>
                  {hasEmployeeHourlyRateDeviation && (
                    <TarrifRateDeviationAlert
                      onClickAction={
                        /* istanbul ignore next */ () => void employeeHourlyRatesTariffsDialogRef.current?.open()
                      }
                    />
                  )}
                  <EmployeeHourlyRatesGrid />
                </ProjectServicePageTemplate.Content>
              )}

              <ProjectServicePageTemplate.Content>
                <CostTypeSection />
              </ProjectServicePageTemplate.Content>
            </ProjectServicePageTemplate.Section>
            <ProjectServicePageTemplate.Footer>
              <ProjectServicePageTemplate.TotalBlock>
                <Total />
              </ProjectServicePageTemplate.TotalBlock>

              {(invoiceMethodIsFixedPrice || invoiceMethodIsSubscription) && (
                <ProjectServicePageTemplate.Content>
                  <div className={styles.invoicingLayout}>
                    <h4 className={styles.subTitle}>{t("invoicing")}</h4>

                    <div className={styles.invoicePrice}>
                      <InvoiceQuantityInput />
                      <InvoicePriceInput />
                      {!values.existsOnInvoice && !values.isInvoicePriceLocked && !defaultPrices?.isPriceLocked && (
                        <ProjectServiceTotalInfo />
                      )}
                    </div>

                    <div className={styles.invoicingFields}>
                      {invoiceMethodIsSubscription && (
                        <>
                          <InvoicedUntil />

                          <InvoiceTogetherWith />
                        </>
                      )}
                      {invoiceMethodIsFixedPrice && (
                        <>
                          <InvoiceableFromDatepicker />

                          <InvoiceInInstallmentsCheckbox />
                        </>
                      )}
                    </div>
                  </div>
                </ProjectServicePageTemplate.Content>
              )}
            </ProjectServicePageTemplate.Footer>
          </ProjectServicePageTemplate>
        </form>
      </Page>
    </ProjectServiceContext.Provider>
  );
};
