import { useTranslation } from "@simplicate/translations";
import { Input } from "@simplicate/ui";
import { memo } from "react";
import { useProjectForm } from "../hooks/useProjectServiceForm";

const useDescriptionTextInputProps = () => {
  const { values, errors, touched, setDescription } = useProjectForm();

  return {
    description: values.description,
    setDescription,
    touched: touched.description,
    error: errors.description,
    defaultService: values.defaultService,
  };
};

export const DescriptionTextInputContent = memo(function DescriptionTextInputContent({
  description,
  setDescription,
  touched,
  error,
  defaultService,
}: ReturnType<typeof useDescriptionTextInputProps>) {
  const { t } = useTranslation("project_services");
  const isDefaultServiceUndefined = defaultService === undefined;

  return (
    <Input
      type="text"
      name="description"
      testId="description-text-input"
      label={t("description")}
      value={description}
      onChange={({ target }) => setDescription?.(target.value)}
      hasError={touched && error !== undefined}
      disabled={isDefaultServiceUndefined}
    />
  );
});

export const DescriptionTextInput = () => <DescriptionTextInputContent {...useDescriptionTextInputProps()} />;
