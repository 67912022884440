import { useTranslation } from "@simplicate/translations";
import { Icon, Link } from "@simplicate/ui";
import { useCallback } from "react";
import { getChangedMeta, restoreView, useAppDispatch, useAppSelector } from "../../data";

export const RestoreViewAction = () => {
  const { t } = useTranslation("insights");
  const dispatch = useAppDispatch();
  const { hasChanges } = useAppSelector(getChangedMeta);

  const handleClick = useCallback(() => {
    dispatch(restoreView());
  }, [dispatch]);

  return (
    <Link testId="restore-view-button" onClick={handleClick} disabled={!hasChanges}>
      <Icon icon="rotateLeft" fixedWidth />
      {t("general.restore_view")}
    </Link>
  );
};
