import { InputGroup } from "@simplicate/ui";
import { ServiceGroupData } from "../../SalesConvertToProjectPage.types";
import { ServiceCheckbox } from "../ServiceCheckbox";
import { ServiceRadio } from "../ServiceRadio";
import styles from "./ServiceGroup.module.scss";

type ServiceGroupProps = {
  serviceGroupData?: ServiceGroupData;
};

export const ServiceGroup = ({ serviceGroupData }: ServiceGroupProps) => {
  if (!serviceGroupData?.services?.length) {
    return null;
  }

  const { services, renderAs, name, id } = serviceGroupData;

  return (
    <>
      {name && <h4 className={styles.groupedServiceTitle}>{name}</h4>}
      <InputGroup hasBorder={true} spacing="large">
        {renderAs === "checkbox"
          ? services.map((service) => <ServiceCheckbox key={service.id} service={service} />)
          : services.map((service) => <ServiceRadio key={service.id} service={service} groupId={id} />)}
      </InputGroup>
    </>
  );
};
