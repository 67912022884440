import { addYears, compareAsc, endOfDay, isPast, isValid, setYear } from "date-fns";
import type { Employee } from "../types";

export const setToFakeYear = (date: Date | string) => {
  const YEAR_NOW = new Date().getFullYear();
  const fakeDate = setYear(date, YEAR_NOW);

  if (isPast(endOfDay(fakeDate))) return addYears(fakeDate, 1);

  return fakeDate;
};

export const transformAPIEmployee = (oldData: { data: Employee[] }): Employee[] => {
  if (!oldData?.data.length) return [];

  const filtered = oldData.data.filter((employee) => isValid(new Date(employee.person.date_of_birth)));

  return [...filtered].sort((a, b) => {
    const aDate = setToFakeYear(a.person.date_of_birth);
    const bDate = setToFakeYear(b.person.date_of_birth);

    return compareAsc(aDate, bDate);
  });
};
