import { GroupedServicesManager } from "@simplicate/grouped-services-manager";
import { useTranslation } from "@simplicate/translations";
import { ActionDropdown, Button, Icon, Page, PageHeader, showToast } from "@simplicate/ui";
import { lightColorIconInvert } from "@simplicate-software/design-tokens";
import { useParams } from "react-router-dom";
import { DeleteGroupModal } from "./components/DeleteGroupModal";
import { LoadingFallback } from "./components/LoadingFallback";
import { ServiceGroupModal } from "./components/ServiceGroupModal";
import { useSalesServicesPageData } from "./SalesServicesPage.hooks";
import styles from "./SalesServicesPage.module.scss";
import { useServiceGroupModal } from "./useServiceGroupModal";

export const SalesServicesPage = () => {
  const { id: saleId } = useParams<{ id: string }>();
  const { closeModal, handleAddGroup, handleEditGroup, isModalOpen, serviceGroupInEdit } = useServiceGroupModal();
  const {
    groupServicesByInvoiceMethod,
    isAutoGroupServicesSuccess,
    isGetSalesError,
    loadingMutation,
    loadingText,
    rootId,
    setAndSaveTreeData,
    treeData,
    serviceGroupInDelete,
    setServiceGroupInDelete,
    handleServiceGroupDelete,
  } = useSalesServicesPageData(handleEditGroup, saleId);
  const { t } = useTranslation("sales_services");

  if (!treeData) {
    if (isGetSalesError) {
      showToast({
        message: t("service_data_fetch_failed"),
        type: "error",
      });

      return null;
    }

    return <LoadingFallback />;
  }

  const shouldShowLoadingFallback = () => {
    return loadingMutation?.isAutoGroupServicesLoading && !isAutoGroupServicesSuccess ? (
      <LoadingFallback text={loadingText} />
    ) : null;
  };

  return (
    <>
      {shouldShowLoadingFallback()}
      <Page>
        <div className={styles.pageContent}>
          <PageHeader title={t("services_page_title")}>
            <ActionDropdown
              ariaLabel={t("add_services_dropdown")}
              button={
                <ActionDropdown.Button>
                  {t("add_services_dropdown")}
                  <Icon icon="angleDown" color={lightColorIconInvert} />
                </ActionDropdown.Button>
              }
            >
              <ActionDropdown.Action to={`/sales/${saleId}/services/new`}>
                {t("add_service_button")}
              </ActionDropdown.Action>
              <ActionDropdown.Action onClick={handleAddGroup}>{t("add_services_group_button")}</ActionDropdown.Action>
            </ActionDropdown>
            <div className={styles.pageHeaderRight}>
              <Button variant="secondary" onClick={groupServicesByInvoiceMethod}>
                {t("group_by_invoice_method")}
                <Icon icon="wand" />
              </Button>
            </div>
          </PageHeader>

          {treeData && <GroupedServicesManager treeData={treeData} onChangeTree={setAndSaveTreeData} rootId={rootId} />}
        </div>

        {serviceGroupInDelete && (
          <DeleteGroupModal
            group={serviceGroupInDelete}
            deleteServiceGroup={handleServiceGroupDelete}
            onClose={
              /* istanbul ignore next */ () => {
                setServiceGroupInDelete(undefined);
              }
            }
          />
        )}

        {saleId && isModalOpen && (
          <ServiceGroupModal onClose={closeModal} saleId={saleId} serviceGroupInEdit={serviceGroupInEdit} />
        )}
      </Page>
    </>
  );
};
