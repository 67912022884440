import { SelectMulti } from "@simplicate/ui";
import { TypedMultiSelectChangeEvent } from "@simplicate/ui/src/components/SelectMulti/SelectMulti";
import { format } from "date-fns";
import { useCallback, useMemo } from "react";
import { useDataset } from "../../data";
import { CubeDimension, cubeDimensionToKey, Formatter } from "../../types";

type ValueType = { label: string; value: string; key: string };
export type DimensionSelectProps = {
  labelDimension: CubeDimension;
  valueDimension: CubeDimension;
  placeholder?: string;
  value?: string[];
  onChange: (value: ValueType[]) => void;
  labelFormat?: Formatter;
};

export const DimensionValueSelect = ({
  labelDimension,
  valueDimension,
  placeholder,
  onChange,
  value,
  labelFormat,
}: DimensionSelectProps) => {
  const { resultSet } = useDataset({
    dimensions: [cubeDimensionToKey(labelDimension), cubeDimensionToKey(valueDimension)],
  });

  const options = useMemo(() => {
    if (!resultSet) return [];

    return resultSet
      .tablePivot()
      .map((record): ValueType | undefined => {
        const labelKey = cubeDimensionToKey(labelDimension);
        const valueKey = cubeDimensionToKey(valueDimension);

        const label = record[labelKey];
        const value = record[valueKey];

        if (labelFormat === "date") {
          return {
            key: String(value),
            label: format(label as string, "dd-MM-yyyy"),
            value: String(value),
          };
        }
        if (value === undefined || label === undefined || label == null) {
          return undefined;
        }

        return {
          key: String(value),
          label: String(label),
          value: String(value),
        };
      })
      .filter((option): option is ValueType => option !== undefined);
  }, [labelDimension, labelFormat, resultSet, valueDimension]);

  const handleChange = useCallback(
    (event: TypedMultiSelectChangeEvent<string>) => {
      const newValue = event.target?.value;
      const selectedOptions = options.filter((option) => newValue.includes(option.value));

      onChange(selectedOptions);
    },
    [onChange, options],
  );

  return (
    <SelectMulti
      value={value}
      placeholder={placeholder}
      options={options}
      onChange={handleChange}
      showSelectedCount
      width="default"
    />
  );
};
