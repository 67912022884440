import classnames from "classnames";
import { InputHTMLAttributes } from "react";
// eslint-disable-next-line css-modules/no-unused-class -- classes are used in styles[direction]
import styles from "./RadioButton.module.scss";

type RadioButtonValueType = number | string | undefined;

export type RadioButtonProps<TValue extends RadioButtonValueType> = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  "value"
> & {
  value: TValue;
  disabled?: boolean;
  variant?: "button" | "input";
  name?: string;
};

export const RadioButton = <TValue extends RadioButtonValueType>({
  value,
  name,
  disabled = false,
  children,
  variant = "input",
  ...inputProps
}: RadioButtonProps<TValue>) => {
  return (
    <label className={classnames(styles.radioInput, disabled && styles.disabled, styles[variant])}>
      <input
        type="radio"
        name={name}
        value={value}
        data-testid={`radio-button-for-${value}`}
        disabled={disabled}
        {...inputProps}
      />
      {children}
    </label>
  );
};
