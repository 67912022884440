import { useTranslation } from "@simplicate/translations";
import { Icon, Link } from "@simplicate/ui";
import { useCallback } from "react";
import { getActiveViewPreset, requestSave, useAppDispatch, useAppSelector } from "../../data";

export const SaveViewAction = () => {
  const { t } = useTranslation("insights");
  const activeView = useAppSelector(getActiveViewPreset);
  const dispatch = useAppDispatch();

  const handleClick = useCallback(() => {
    dispatch(requestSave("update"));
  }, [dispatch]);

  return (
    <Link testId="save-view-button" onClick={handleClick} disabled={!activeView?.id}>
      <Icon icon="floppyDisk" fixedWidth />
      {t("general.save_view")}
    </Link>
  );
};
