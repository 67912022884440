import { useTranslation } from "@simplicate/translations";
import { ActionDropdown, Button, Icon } from "@simplicate/ui";
import { colorCommonGrey500 } from "@simplicate-software/design-tokens";
import styles from "./CollectiveActions.module.scss";

type CollectiveActionsProps = {
  showInvoiceable?: boolean;
  onDelete: () => void;
  onMakeInvoiceable?: () => void;
  onMakeNonInvoiceable?: () => void;
  testId?: string;
};

export const CollectiveActions = ({
  showInvoiceable = false,
  onDelete,
  onMakeInvoiceable,
  onMakeNonInvoiceable,
  testId,
}: CollectiveActionsProps) => {
  const { t } = useTranslation("project_services");

  return (
    <div className={styles.collectiveActions} data-testid={testId}>
      <Button size="medium" variant="subtle" onClick={onDelete} testId={testId && `${testId}-delete`}>
        <Icon fixedWidth icon="trash" />
        {t("collective_actions.delete")}
      </Button>

      {showInvoiceable && (
        <ActionDropdown
          button={
            <ActionDropdown.Button size="medium" variant="subtle" testId={testId && `${testId}-invoiceable`}>
              <Icon fixedWidth icon="pen" />
              {t("collective_actions.change_invoice_status")}
              <Icon fixedWidth icon="angleDown" color={colorCommonGrey500} />
            </ActionDropdown.Button>
          }
        >
          <ActionDropdown.Action onClick={onMakeInvoiceable} testId={testId && `${testId}-make-invoiceable`}>
            <Icon fixedWidth icon="fileInvoice" />
            {t("collective_actions.make_invoiceable")}
          </ActionDropdown.Action>

          <ActionDropdown.Action onClick={onMakeNonInvoiceable} testId={testId && `${testId}-make-non-invoiceable`}>
            <Icon fixedWidth icon="fileInvoiceSlash" />
            {t("collective_actions.make_non_invoiceable")}
          </ActionDropdown.Action>
        </ActionDropdown>
      )}
    </div>
  );
};
