import { skipToken } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { Button, Select, Icon, TypedDropdownChangeEvent } from "@simplicate/ui";
import { lightColorIconSuccess } from "@simplicate-software/design-tokens";
import { useState } from "react";
import { useGetHourTypeQuery, useGetHourTypesQuery } from "../../../../../data";
import styles from "./HourTypeSelect.module.scss";
import type { HourTypeInForm } from "../../../types";

type HourTypeSelectProps = {
  onConfirmNewEntry: (entry: HourTypeInForm) => void;
  onCancelNewEntry: () => void;
  hourTypesInForm: HourTypeInForm[] | undefined;
  disabled?: boolean;
};

export const HourTypeSelect = ({
  onConfirmNewEntry,
  onCancelNewEntry,
  hourTypesInForm,
  disabled = false,
}: HourTypeSelectProps) => {
  const { t } = useTranslation("project_services");
  const [selectedHourTypeId, setSelectedHourTypeId] = useState<string | undefined>(undefined);
  const { data: options } = useGetHourTypesQuery();
  const { data: selectedHourType } = useGetHourTypeQuery(selectedHourTypeId ?? skipToken);

  const compatibleOptions = options?.map((option) => ({
    label: option.name,
    value: option.id,
  }));

  const isOptionDisabled = (option: { label: string; value: string }) => {
    // TODO: improve coverage, or just remove the ability for hourTypeInForm to be undefined
    /* istanbul ignore next -- branching is not covered */
    return hourTypesInForm?.some((hourType) => hourType.id === option.value) ?? false;
  };

  return (
    <div className={styles.container}>
      <div className={styles.input}>
        <Select
          testId="hour-type-select"
          value={selectedHourTypeId}
          options={compatibleOptions}
          placeholder={t("hour_type_placeholder")}
          onChange={(event: TypedDropdownChangeEvent<string>) => setSelectedHourTypeId(event.target.value)}
          optionDisabled={isOptionDisabled}
          size="small"
          disabled={disabled}
        />
      </div>

      <Button
        testId="confirm-new-hour-type"
        variant="secondary"
        disabled={disabled || !selectedHourTypeId}
        hasInlinePadding={false}
        onClick={() =>
          selectedHourType &&
          onConfirmNewEntry({
            id: selectedHourType.id,
            name: selectedHourType.name,
            amount: 1,
            isInvoiceable: true,
            hourlyRate: selectedHourType.hourlyRate,
            total: selectedHourType.hourlyRate,
            hasRegistrations: false,
          })
        }
      >
        <Icon icon="check" color={lightColorIconSuccess} />
      </Button>

      <Button
        variant="secondary"
        testId="cancel-new-hour-type"
        onClick={onCancelNewEntry}
        disabled={disabled}
        hasInlinePadding={false}
      >
        <Icon icon="times" />
      </Button>
    </div>
  );
};
