import { InputHTMLAttributes } from "react";
import styles from "./Toggle.module.scss";

type ToggleProps = Omit<InputHTMLAttributes<HTMLInputElement>, "value"> & {
  value: boolean;
  testId?: string;
  disabled?: boolean;
  label?: React.ReactNode | string;
};

export const Toggle = ({ value, testId, label, disabled = false, ...inputProps }: ToggleProps) => {
  return (
    <label className={styles.toggle}>
      <input
        className={styles.input}
        checked={value}
        type="checkbox"
        data-testid={testId}
        disabled={disabled}
        {...inputProps}
      />
      <span className={styles.slider}></span>
      <span>{label}</span>
    </label>
  );
};
