import { InvoiceMethod } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { memo } from "react";
import { useParams } from "react-router-dom";
import { useProjectForm } from "../hooks/useProjectServiceForm";
import styles from "./TimeFrame.module.scss";
import { TimeframeDatePicker } from "./TimeFrameDatePicker";

const useTimeframeProps = () => {
  const { values, errors, touched, setTimeframeStartDate, setTimeframeEndDate, existingProjectService } =
    useProjectForm();
  const { serviceId } = useParams();

  return {
    serviceId,
    invoiceMethod: values.invoiceMethod,
    defaultService: values.defaultService,
    timeframe: values.timeframe,
    touched: touched.timeframe,
    errors: errors.timeframe,
    setTimeframeStartDate,
    setTimeframeEndDate,
    existingProjectService,
  };
};

// eslint-disable-next-line complexity -- This is a complex component
const TimeframeContent = memo(function TimeframeContent({
  serviceId,
  invoiceMethod,
  defaultService,
  timeframe,
  touched,
  errors,
  setTimeframeStartDate,
  setTimeframeEndDate,
  existingProjectService,
}: ReturnType<typeof useTimeframeProps>) {
  const { t } = useTranslation("project_services");
  const isEditingService = serviceId !== undefined;
  const invoiceMethodIsSubscription = invoiceMethod === InvoiceMethod.subscription;

  const isTimeframeStartDateLocked =
    isEditingService && invoiceMethodIsSubscription && !!existingProjectService?.timeFrame?.startDate;

  const isDefaultServiceUndefined = defaultService === undefined;

  const tooltip = isTimeframeStartDateLocked ? t("subscription_timeframe_start_cannot_be_changed") : undefined;

  return (
    <>
      <div className={styles.timeframeFields}>
        <div className={styles.timeframeFieldContainer}>
          <TimeframeDatePicker
            name="startDate"
            testId="start-date-datepicker"
            label={invoiceMethodIsSubscription ? t("start_subscription") : t("start_date")}
            touched={touched?.startDate}
            error={errors?.startDate}
            disabled={isDefaultServiceUndefined || isTimeframeStartDateLocked}
            tooltip={tooltip}
            onChange={(value) => setTimeframeStartDate?.(value)}
            value={timeframe.startDate}
            maxValue={timeframe.endDate}
          />
        </div>
        <div className={styles.timeframeFieldContainer}>
          <TimeframeDatePicker
            name="endDate"
            testId="end-date-datepicker"
            label={invoiceMethodIsSubscription ? t("end_subscription") : t("end_date")}
            touched={touched?.endDate}
            error={errors?.endDate}
            disabled={isDefaultServiceUndefined || (invoiceMethodIsSubscription && !timeframe.startDate)}
            minValue={timeframe.startDate}
            onChange={(value) => setTimeframeEndDate?.(value)}
            value={timeframe.endDate}
          />
        </div>
      </div>
    </>
  );
});

export const Timeframe = () => <TimeframeContent {...useTimeframeProps()} />;
