import { CurrencyFormat } from "@simplicate/number-format";
import { useTranslation } from "@simplicate/translations";
import { Link, Table, buildColumnProps } from "@simplicate/ui";
import { size10, size6 } from "@simplicate-software/design-tokens";
import { memo } from "react";
import { RateWithAgreedRate } from "../../hooks/useRateAgreements";
import { HourlyRateInForm, HourTypeInForm } from "../../types";

export type BaseDeviationGridProps = {
  value: RateWithAgreedRate[];
  columnProps?: {
    field: string;
    header: string;
    body: (data: RateWithAgreedRate & (HourlyRateInForm | HourTypeInForm)) => JSX.Element | string;
  };
  onApplyTariff: (values: RateWithAgreedRate[]) => void;
};

export const BaseDeviationGrid = memo(function BaseDeviationGrid({
  value,
  columnProps,
  onApplyTariff,
}: BaseDeviationGridProps) {
  const { t } = useTranslation("project_services");

  return (
    <Table testId="rate-deviation-grid" value={value}>
      <Table.Column
        key={columnProps?.field}
        {...buildColumnProps({
          ...columnProps,
          width: size10,
          align: "left",
        })}
      />
      <Table.Column
        {...buildColumnProps({
          header: t("rate_aggreed_upon_header"),
          field: "agreedRate",
          body: ({ agreedRate }: RateWithAgreedRate) => (
            <CurrencyFormat displayType="text" size="small" value={agreedRate?.amount?.toString()} readOnly={true} />
          ),
          width: size6,
          align: "right",
        })}
      />
      <Table.Column
        key="filledInRate"
        {...buildColumnProps({
          header: t("rate_filled_in_header"),
          field: "filledInRate",
          body: ({ hourlyRate }: RateWithAgreedRate) => (
            <CurrencyFormat displayType="text" size="small" value={hourlyRate.amount!.toString()} readOnly={true} />
          ),
          width: size6,
          align: "right",
        })}
      />
      <Table.Column
        key="apply"
        {...buildColumnProps({
          header: "",
          field: "apply",
          body: ({ id, ...values }: RateWithAgreedRate) => (
            <Link onClick={() => onApplyTariff([{ id, ...values }])} testId={`apply-rate-clickable-${id}`}>
              {t("rate_apply_action")}
            </Link>
          ),
          width: size6,
          align: "center",
        })}
      />
    </Table>
  );
});
