import { useTranslation } from "@simplicate/translations";
import { memo } from "react";
import { type RateWithAgreedRate } from "../../hooks/useRateAgreements";
import { HourlyRateInForm } from "../../types";
import { Employee } from "../EmployeeHourlyRatesGrid/Employee/Employee";
import { BaseDeviationGrid, BaseDeviationGridProps } from "./BaseDeviationGrid";

export const EmployeeHourlyRateDeviationGrid = memo(function Fn({
  ...props
}: Omit<BaseDeviationGridProps, "columnProps">) {
  const { t } = useTranslation("project_services");

  const columnProps = {
    header: t("hourly_rate_employee_header"),
    field: "employeeId",
    body: ({ id }: HourlyRateInForm & RateWithAgreedRate) => id && <Employee id={id} />,
  };

  return <BaseDeviationGrid columnProps={columnProps} {...props} />;
});
