import { Action, ThunkAction } from "@simplicate/state";
import { ViewPresetEndpoints } from "../../../endpoints/ViewPreset/ViewPreset";
import { setChangedMeta } from "../dashboardSlice";
import { DashboardRootState } from "../types";

export const finalizeDeleteThunk =
  (): ThunkAction<void, DashboardRootState, unknown, Action<"Insights Dashboard State/setChangedMeta">> =>
  (dispatch, getState) => {
    const slice = getState().state.insights.dashboard_views;

    dispatch(
      setChangedMeta({
        ...slice.changedMeta,
        deleteRequested: false,
      }),
    );

    if (!slice.activeDashboardId) {
      return;
    }

    const dashboard = slice.dashboards[slice.activeDashboardId];

    if (dashboard?.id) {
      void dispatch(ViewPresetEndpoints.deleteViewPreset.initiate(dashboard.id));
    }
  };
