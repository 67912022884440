import { ColumnsLayout } from "@simplicate/ui";
import { PropsWithChildren, useMemo } from "react";
import classes from "./DashboardReportPageTemplate.module.scss";

export const DashboardReportPageTemplate = ({ children }: PropsWithChildren) => {
  return <ColumnsLayout>{children}</ColumnsLayout>;
};

DashboardReportPageTemplate.Header = function HeaderSlot({ children }: PropsWithChildren) {
  return (
    <ColumnsLayout.Slot onSmallScreens={12} className={classes.header}>
      {children}
    </ColumnsLayout.Slot>
  );
};
DashboardReportPageTemplate.Filters = function FiltersSlot({ children }: PropsWithChildren) {
  return <ColumnsLayout.Slot>{children}</ColumnsLayout.Slot>;
};
DashboardReportPageTemplate.Settings = function SettingsSlot({ children }: PropsWithChildren) {
  return (
    <ColumnsLayout.Slot className={classes.settings} onSmallScreens={1}>
      {children}
    </ColumnsLayout.Slot>
  );
};

DashboardReportPageTemplate.KpiContainer = function KpiContainerSlot({ children }: PropsWithChildren) {
  return (
    <ColumnsLayout.Slot hasSubgridOnColumns className={classes.kpiContainer}>
      {children}
    </ColumnsLayout.Slot>
  );
};

DashboardReportPageTemplate.Kpi = function Kpi({
  children,
  width = "default",
}: PropsWithChildren<{ width?: "default" | "wide" }>) {
  const slotProps = useMemo(() => {
    if (width === "default") {
      return { onMediumScreens: 6, onHugeScreens: 3 } as const;
    }

    return { onMediumScreens: 12, onHugeScreens: 6 } as const;
  }, [width]);

  return <ColumnsLayout.Slot {...slotProps}>{children}</ColumnsLayout.Slot>;
};

DashboardReportPageTemplate.Table = function TableSlot({ children }: PropsWithChildren) {
  return <ColumnsLayout.Slot>{children}</ColumnsLayout.Slot>;
};
