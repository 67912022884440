import classNames from "classnames";
import { useId } from "react";
import { Triangle } from "../Triangle";
// eslint-disable-next-line css-modules/no-unused-class -- classes are used in styles[position]
import styles from "./Tooltip.module.scss";

type TooltipProps = {
  children: React.ReactNode;
  message: string;
  position?: "down" | "left" | "right" | "up";
  testId?: string;
};

export const Tooltip = ({ children, message, position = "up", testId }: TooltipProps) => {
  const positionMap: Record<typeof position, typeof position> = {
    up: "down",
    down: "up",
    left: "right",
    right: "left",
  };

  const uid = useId();
  const id = `tooltip-${uid}`;

  const trianglePosition = positionMap[position];

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex -- This is a tooltip and should be focusable
    <div className={styles.tooltip} tabIndex={0} title={message} aria-describedby={id} data-testid={testId}>
      {children}
      <div role="tooltip" id={id} className={classNames(styles.tooltipContent, styles[position])}>
        <div className={styles.triangleContainer}>
          <Triangle direction={trianglePosition} />
        </div>
        <span>{message}</span>
      </div>
    </div>
  );
};
