import { CurrencyFormat } from "@simplicate/number-format";
import { useTranslation } from "@simplicate/translations";
import styles from "./ServiceGroupFooter.module.scss";
import type { MoneyFromAPI, GroupType } from "../../GroupedServicesManager.types";

type ServiceGroupFooterProps = {
  total?: MoneyFromAPI;
  groupType?: GroupType;
};
export const ServiceGroupFooter = ({ total, groupType }: ServiceGroupFooterProps) => {
  const { t } = useTranslation("grouped_services_manager");

  const amount = Number(total?.amount) > 0 ? total?.amount : undefined;

  if (groupType !== "all_included") {
    return null;
  }

  return (
    <div className={styles.footer} data-testid="services-group-footer">
      <div className={styles.label}>{t("total")}</div>
      <div className={styles.total}>
        <CurrencyFormat value={amount ?? "-"} />
      </div>
    </div>
  );
};
