import { colorCommonGrey500 } from "@simplicate-software/design-tokens";
import classNames from "classnames";
import { Checkbox } from "../Checkbox";
import { Icon } from "../Icon";
import { useDraggable, useSelectable } from "./hooks";
import { SortableListRecord } from "./SortableList.types";
// eslint-disable-next-line css-modules/no-unused-class -- classes are used
import styles from "./SortableListItem.module.scss";

type SortableListItemProps<T extends SortableListRecord> = {
  disabled?: boolean;
  renderItem: (item: T) => React.ReactNode;
  value: T;
};

export const SortableListItem = <T extends SortableListRecord>({
  renderItem,
  value,
  disabled,
}: SortableListItemProps<T>) => {
  const { isSelectable, checkboxProps } = useSelectable(value);
  const { isSortable, isDragging, isOver, isOnTopHalf, ref } = useDraggable(value);

  return (
    <li
      className={classNames(
        styles.listItem,
        isDragging && !disabled && styles.isDragging,
        isOver && !disabled && styles.isOver,
        isOnTopHalf && !disabled && styles.isOnTopHalf,
      )}
      ref={ref}
    >
      {isSortable && (
        <div className={classNames(styles.dragHandle, disabled && styles.disabled)}>
          <Icon icon="grip" color={disabled ? colorCommonGrey500 : undefined} />
        </div>
      )}
      {isSelectable ? (
        <Checkbox {...checkboxProps} name={value.id} disabled={disabled}>
          {renderItem(value)}
        </Checkbox>
      ) : (
        renderItem(value)
      )}
    </li>
  );
};
