import { useTranslation } from "@simplicate/translations";
import { Alert, Link } from "@simplicate/ui";
import styles from "./TarrifRateDeviationAlert.module.scss";

type TarrifRateDeviationAlertProps = {
  onClickAction: () => void;
};

export const TarrifRateDeviationAlert = ({ onClickAction }: TarrifRateDeviationAlertProps) => {
  const { t } = useTranslation("project_services");

  return (
    <Alert type="warning">
      <span className={styles.alert}>
        {t("rate_agreement_deviation_notice")}
        <Link onClick={onClickAction}>{t("rate_agreement_deviation_notice_action")}</Link>
      </span>
    </Alert>
  );
};
