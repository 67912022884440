import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { DateRange } from "../../data";

export const useDateRangeSearchQuery = (): DateRange | undefined => {
  const [params] = useSearchParams();

  return useMemo(() => {
    const start = params.get("startDate");
    const end = params.get("endDate");

    if (!start || !end) {
      return undefined;
    }

    return { start: new Date(start), end: new Date(end) };
  }, [params]);
};
