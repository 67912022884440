import { Action, ThunkAction } from "@simplicate/state";
import { ViewPresetEndpoints } from "../../../endpoints/ViewPreset/ViewPreset";
import { setActiveDashboard, setAvailablePresetsSubscription } from "../dashboardSlice";
import { DashboardRootState, DateRange } from "../types";

export const setActiveDashboardThunk =
  ({
    dashboardId,
    dateRange,
  }: {
    dashboardId: string;
    dateRange?: DateRange;
  }): ThunkAction<
    void,
    DashboardRootState,
    unknown,
    Action<"Insights Dashboard State/setActiveDashboard" | "Insights Dashboard State/setAvailablePresetsSubscription">
  > =>
  (dispatch, getState) => {
    const slice = getState().state.insights.dashboard_views;

    if (slice.availableViewPresetsSubscription !== undefined) {
      slice.availableViewPresetsSubscription.unsubscribe();
    }

    dispatch(setActiveDashboard({ dashboardId, dateRange }));

    const promise = dispatch(ViewPresetEndpoints.getViewPresetsList.initiate(dashboardId, { forceRefetch: true }));

    dispatch(setAvailablePresetsSubscription(promise));
  };
