import { useTranslation } from "@simplicate/translations";
import { useFormik } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import * as Yup from "yup";
import {
  type GroupType,
  type ServiceGroup,
  useCreateServiceGroupMutation,
  useUpdateServiceGroupMutation,
} from "../../../../data";

export const DESCRIPTION_MAX_LENGTH = 65535;
export const NAME_MAX_LENGTH = 255;

export type ServiceGroupModalForm = {
  name?: string;
  description?: string;
  groupType: GroupType;
};

export type UseServiceGroupModalFormReturnType = ReturnType<typeof useServiceGroupModalForm>;

export const useServiceGroupModalForm = (saleId: string, serviceGroup?: ServiceGroup) => {
  const { t } = useTranslation("sales_services");
  const [
    createServiceGroup,
    {
      isLoading: isCreateServiceGroupLoading,
      isSuccess: isCreateServiceGroupSuccess,
      isError: isCreateServiceGroupError,
    },
  ] = useCreateServiceGroupMutation();
  const [
    updateServiceGroup,
    {
      isLoading: isUpdateServiceGroupLoading,
      isSuccess: isUpdateServiceGroupSuccess,
      isError: isUpdateServiceGroupError,
    },
  ] = useUpdateServiceGroupMutation();

  const isMutationSuccess = isCreateServiceGroupSuccess || (serviceGroup && isUpdateServiceGroupSuccess);
  const hasMutationError = isCreateServiceGroupError || (serviceGroup && isUpdateServiceGroupError);

  const [mutationError, setMutationError] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (isCreateServiceGroupError) {
      setMutationError(t("error_messages.failed_to_add_group"));
    }
    if (serviceGroup && isUpdateServiceGroupError) {
      setMutationError(t("error_messages.failed_to_edit_group"));
    }
  }, [isCreateServiceGroupError, isUpdateServiceGroupError, serviceGroup, t]);

  const validationSchema = Yup.object<ServiceGroupModalForm>({
    name: Yup.string()
      .required(t("error_messages.group_name_required"))
      .max(NAME_MAX_LENGTH, t("error_messages.group_name_max_length")),
    description: Yup.string().max(DESCRIPTION_MAX_LENGTH, t("error_messages.group_description_max_length")),
    groupType: Yup.string().required(t("error_messages.group_type_required")),
  });

  const { errors, handleChange, handleBlur, handleSubmit, isValid, setFieldTouched, setFieldValue, touched, values } =
    useFormik<ServiceGroupModalForm>({
      initialValues: serviceGroup
        ? {
            name: serviceGroup.name,
            description: serviceGroup.description,
            groupType: serviceGroup.type,
          }
        : {
            name: undefined,
            description: undefined,
            groupType: "all_included",
          },
      onSubmit: /* istanbul ignore next - onSubmit function is mocked in tests */ (form) => {
        if (serviceGroup) {
          void updateServiceGroup({
            serviceGroupId: serviceGroup.id,
            name: form.name,
            description: form.description,
            type: form.groupType,
          });
        } else {
          void createServiceGroup({
            saleId,
            name: form.name ?? "",
            description: form.description ?? "",
            type: form.groupType ?? "all_included",
          });
        }
      },
      validationSchema,
    });

  const setGroupType = useCallback(
    async (groupType: GroupType) => {
      await setFieldTouched("groupType");
      await setFieldValue("groupType", groupType);
    },
    [setFieldTouched, setFieldValue],
  );

  const { isLoading, loadingText, loadingMutation } = useMemo(() => {
    const loadingStates = {
      isCreateServiceGroupLoading,
      isUpdateServiceGroupLoading,
    };

    const loadingMessages = {
      isCreateServiceGroupLoading: t("loading_messages.add_group"),
      isUpdateServiceGroupLoading: t("loading_messages.edit_group"),
    };

    const mutationInLoading = Object.entries(loadingStates).filter(([, value]) => value);
    const isLoading = mutationInLoading.length > 0;

    return {
      isLoading,
      loadingText: loadingMessages[mutationInLoading[0]?.[0] as keyof typeof loadingMessages] ?? "",
      loadingMutation: isLoading ? loadingStates : undefined,
    };
  }, [isCreateServiceGroupLoading, isUpdateServiceGroupLoading, t]);

  return {
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    hasMutationError,
    isLoading,
    isMutationSuccess,
    isValid,
    loadingMutation,
    loadingText,
    mutationError,
    setFieldValue,
    setGroupType,
    touched,
    values,
  };
};
