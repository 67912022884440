import { InvoiceMethod, SubscriptionCycle } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { Tag } from "@simplicate/ui";
import styles from "./InputLabel.module.scss";

interface InputLabelProps {
  name?: string;
  invoiceMethod?: InvoiceMethod;
  subscriptionCycle?: SubscriptionCycle;
}

export const InputLabel = ({ name, invoiceMethod, subscriptionCycle }: InputLabelProps) => {
  const { t } = useTranslation("grouped_services_manager");

  const getInvoiceMethodLabel = (invoiceMethod: InvoiceMethod, subscriptionCycle?: SubscriptionCycle) => {
    switch (invoiceMethod) {
      case InvoiceMethod.fixed_price:
        return t("invoice_method.fixed");
      case InvoiceMethod.time_and_expenses:
        return t("invoice_method.hours");
      case InvoiceMethod.subscription:
        switch (subscriptionCycle) {
          case "monthly":
            return t("invoice_method.subscription_month");
          case "quarterly":
            return t("invoice_method.subscription_quarter");
          case "halfYearly":
            return t("invoice_method.subscription_halve_year");
          case "yearly":
            return t("invoice_method.subscription_year");
          default:
            return "";
        }
    }
  };

  return (
    <div className={styles.wrapper}>
      {name}
      {invoiceMethod && (
        <Tag
          text={getInvoiceMethodLabel(invoiceMethod, subscriptionCycle)}
          testId="inputLabel"
          variant="light"
          size="medium"
        />
      )}
    </div>
  );
};
