import { useTranslation } from "@simplicate/translations";
import { Button, RadioButton } from "@simplicate/ui";
import { ChangeEvent, forwardRef, useState } from "react";
import { DialogBase } from "./DialogBase";

type TypedRadioOnChangeEvent = Omit<ChangeEvent<HTMLInputElement>, "target"> & {
  target: Omit<EventTarget & HTMLInputElement, "target"> & {
    value: "discard-touched" | "keep-touched";
  };
};

export type DefaultServiceDialogForm = {
  defaultServiceChanged: "discard-touched" | "keep-touched";
};

export const DialogDefaultServiceChanges = forwardRef(function DefaultServicesValuesDialog(_, ref) {
  const { t } = useTranslation("project_services");

  const [selectedMethod, setSelectedMethod] = useState<"discard-touched" | "keep-touched">("keep-touched");

  const radioOptions = [
    { label: t("keep_touched_values_when_changing_default_service"), value: "keep-touched" },
    { label: t("discard_touched_values_when_changing_default_service"), value: "discard-touched" },
  ];

  return (
    <DialogBase ref={ref} maxWidth="medium" onSubmit={() => setSelectedMethod("keep-touched")}>
      <DialogBase.Title>{t("change_default_service_title")}</DialogBase.Title>
      <DialogBase.Description>{t("change_default_service_info_text")}</DialogBase.Description>
      <DialogBase.Content>
        {radioOptions.map(({ value, label }) => (
          <RadioButton
            key={value}
            checked={selectedMethod === value}
            value={value}
            name="defaultServiceChanged"
            onChange={(event: TypedRadioOnChangeEvent) => setSelectedMethod(event.target.value)}
          >
            {label}
          </RadioButton>
        ))}
      </DialogBase.Content>
      <DialogBase.Footer>
        <Button testId="confirm-changing-default-service" type="submit">
          {t("confirm_changing_default_service")}
        </Button>
        <Button variant="subtle" type="reset">
          {t("cancel_changing_default_service")}
        </Button>
      </DialogBase.Footer>
    </DialogBase>
  );
});
