import { useTranslation } from "@simplicate/translations";
import { Select, TypedDropdownChangeEvent } from "@simplicate/ui";
import { FocusEventHandler } from "react";
import type { GroupType } from "../../../../../data";

export const GroupTypeSelect = ({
  groupType,
  onChange,
  onBlur,
}: {
  groupType: GroupType;
  onChange: (groupType: GroupType) => Promise<void>;
  onBlur: FocusEventHandler<HTMLInputElement>;
}) => {
  const { t } = useTranslation("sales_services");

  const groupTypeOptions: { value: GroupType; label: string }[] = [
    { value: "all_included", label: t("optional_services.all_included") },
    { value: "multiple_optional", label: t("optional_services.multiple_optional") },
    { value: "single_required", label: t("optional_services.single_required") },
    { value: "single_optional", label: t("optional_services.single_optional") },
  ];

  return (
    <Select
      name="groupType"
      testId="group-type"
      onChange={(event: TypedDropdownChangeEvent<GroupType>) => void onChange(event.target.value)}
      value={groupType}
      options={groupTypeOptions}
      label={t("optional_services.label")}
      filter={false}
      onBlur={onBlur}
    />
  );
};
