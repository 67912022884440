import { useTranslation } from "@simplicate/translations";
import { Icon, Link } from "@simplicate/ui";
import { lightColorIconError } from "@simplicate-software/design-tokens";
import { useCallback } from "react";
import { getActiveViewPreset, requestDelete, useAppDispatch, useAppSelector } from "../../data";

export const DeleteViewAction = () => {
  const { t } = useTranslation("insights");
  const dashboard = useAppSelector(getActiveViewPreset);
  const dispatch = useAppDispatch();

  const handleClick = useCallback(() => {
    dispatch(requestDelete());
  }, [dispatch]);

  return (
    <Link testId="delete-view-button" onClick={handleClick} variant="danger" disabled={!dashboard?.id}>
      <Icon icon="trash" color={lightColorIconError} fixedWidth />
      {t("general.delete_view")}
    </Link>
  );
};
