import { useTranslation } from "@simplicate/translations";
import { Button, Page, showToast } from "@simplicate/ui";
import { Form, Formik } from "formik";
import { useParams, useSearchParams } from "react-router-dom";
import { LoadingFallback } from "../SalesServicesPage/components/LoadingFallback";
import { ProjectForm, ServiceGroup } from "./components";
import { useSalesConvertToProjectPageData } from "./SalesConvertToProjectPage.hooks";
import styles from "./SalesConvertToProjectPage.module.scss";

export const SalesConvertToProjectPage = () => {
  const { id: saleId } = useParams<{ id: string }>();
  const [searchParams] = useSearchParams();

  const {
    groupedServices,
    isGetSalesError,
    initialValues,
    handleSubmit,
    projectData,
    isNewProject,
    handleSetIsNewProject,
    validationSchema,
  } = useSalesConvertToProjectPageData(saleId, searchParams.get("project_id") ?? undefined);
  const { t } = useTranslation("sales_convert_to_project");

  if (!groupedServices) {
    if (isGetSalesError) {
      showToast({
        message: t("data_fetch_failed"),
        type: "error",
      });

      return null;
    }

    return <LoadingFallback />;
  }

  return (
    initialValues && (
      <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
        <Page>
          <div className={styles.pageContainer}>
            {saleId && (
              <Form className={styles.pageWidth}>
                <ProjectForm
                  data={projectData}
                  handleSetIsNewProject={handleSetIsNewProject}
                  isNewProject={isNewProject}
                />
                <h3 className={styles.servicesTitle} data-testid="convert-to-project">
                  {t("services_title")}
                </h3>
                <p className={styles.servicesDescription}> {t("services_description")}</p>
                <div className={styles.servicesTypeWrapper}>
                  {groupedServices.map((group) => (
                    <ServiceGroup key={group.id} serviceGroupData={group} />
                  ))}
                </div>
                <Button type="submit">{t("save")}</Button>
              </Form>
            )}
          </div>
        </Page>
      </Formik>
    )
  );
};
