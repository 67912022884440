import { createSelector } from "@simplicate/state";
import { DashboardRootState } from "../types";

export const getAvailableViewPresets = createSelector(
  (state: DashboardRootState) => state.state.insights.dashboard_views,
  (dashboard_views) => {
    if (!dashboard_views.activeDashboardId) {
      return [];
    }

    return dashboard_views.availableViewPresets;
  },
);
