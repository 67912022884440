import { DataTableValueArray } from "primereact/datatable";
import { ChangeEvent, ReactNode } from "react";
import { Checkbox } from "../../Checkbox";
import styles from "../SelectionColumn.module.scss";

type CollectiveActionColumnProps<TValue extends DataTableValueArray> = {
  value: boolean | "indeterminate";
  selection: TValue;
  toggleSelectAll: (event: ChangeEvent<HTMLInputElement>) => void;
  CollectiveActions?: ({ selection }: { selection: TValue }) => ReactNode;
};

export const SelectionHeaderCell = <TValue extends DataTableValueArray>({
  value,
  selection,
  toggleSelectAll,
  CollectiveActions,
}: CollectiveActionColumnProps<TValue>) => {
  return (
    <>
      <Checkbox value={value} onChange={toggleSelectAll} />
      {CollectiveActions && selection.length > 0 && (
        <div className={styles.collectiveActions}>
          <CollectiveActions selection={selection} />
        </div>
      )}
    </>
  );
};
