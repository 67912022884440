import { useEffect } from "react";

function addScriptTagToHead(sdkUrl: string) {
  const hasBeenAdded = !!document.head.querySelector(`script[src="${sdkUrl}"]`);

  if (hasBeenAdded) {
    return;
  }

  const scriptElement = document.createElement("script");

  scriptElement.src = sdkUrl;
  scriptElement.type = "module";
  scriptElement.defer = true;

  document.head.appendChild(scriptElement);
}

export const useEmbeddableScript = (sdkUrl: string) => useEffect(() => addScriptTagToHead(sdkUrl), [sdkUrl]);
