import { useTranslation } from "@simplicate/translations";
import { FloatingActionButton } from "@simplicate/ui";
import { useCallback } from "react";
import { useIntercom } from "react-use-intercom";
import { useRouteConfig } from "../useRouteConfig";

export const SupportArticleButton = () => {
  const config = useRouteConfig();
  const { t } = useTranslation("general");
  const { showArticle } = useIntercom();

  const hasSupportArticle = config.supportArticleId !== undefined;
  const handleClick = useCallback(() => {
    // istanbul ignore else -- button is not rendered if there is no article
    if (config.supportArticleId !== undefined) {
      showArticle(config.supportArticleId);
    }
  }, [config.supportArticleId, showArticle]);

  if (!hasSupportArticle) {
    return null;
  }

  return <FloatingActionButton icon="light" hoverMessage={t("show_support_article")} onClick={handleClick} />;
};
