import { InvoiceMethod } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { Checkbox, Tooltip } from "@simplicate/ui";
import { memo } from "react";
import { useProjectForm } from "../hooks/useProjectServiceForm";
import styles from "./InvoiceInInstallmentsCheckbox.module.scss";

const useInvoiceInInstallmentsCheckboxProps = () => {
  const { values, setInvoiceInInstallments } = useProjectForm();

  return {
    invoiceMethod: values.invoiceMethod,
    invoiceInInstallments: values.invoiceInInstallments,
    hasInstallmentPlan: values.hasInstallmentPlan,
    existsOnInvoice: values.existsOnInvoice,
    setInvoiceInInstallments,
  };
};

export const InvoiceInInstallmentsCheckboxContent = memo(function InvoiceInInstallmentsCheckboxContent({
  invoiceMethod,
  invoiceInInstallments,
  hasInstallmentPlan,
  existsOnInvoice,
  setInvoiceInInstallments,
}: ReturnType<typeof useInvoiceInInstallmentsCheckboxProps>) {
  const { t } = useTranslation("project_services");

  if (invoiceMethod !== InvoiceMethod.fixed_price) return null;

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInvoiceInInstallments?.(event.target.checked);
  };

  const tooltip = hasInstallmentPlan ? t("invoice_in_installments_locked") : undefined;

  const checkbox = (
    <Checkbox
      name="invoiceInInstallments"
      testId="invoice-in-installments-checkbox"
      label={t("invoice_in_installments")}
      value={invoiceInInstallments ?? false}
      disabled={!!hasInstallmentPlan || !!existsOnInvoice}
      onChange={onChange}
    />
  );

  return (
    <div className={styles.invoiceInInstallmentsCheckboxContainer}>
      {tooltip ? (
        <Tooltip message={tooltip} position="right">
          {checkbox}
        </Tooltip>
      ) : (
        checkbox
      )}
    </div>
  );
});

export const InvoiceInInstallmentsCheckbox = () => (
  <InvoiceInInstallmentsCheckboxContent {...useInvoiceInInstallmentsCheckboxProps()} />
);
