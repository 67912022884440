import { useTranslation } from "@simplicate/translations";
import { memo } from "react";
import { RateWithAgreedRate } from "../../hooks/useRateAgreements";
import { HourlyRateInForm, HourTypeInForm } from "../../types";
import { BaseDeviationGrid, BaseDeviationGridProps } from "./BaseDeviationGrid";

export const HourTypeDeviationGrid = memo(function Fn({ ...props }: Omit<BaseDeviationGridProps, "columnProps">) {
  const { t } = useTranslation("project_services");

  const columnProps = {
    header: t("hour_type_label_header"),
    field: "label",
    body: (data: RateWithAgreedRate & (HourlyRateInForm | HourTypeInForm)) => ("name" in data ? data.name : ""),
  };

  return <BaseDeviationGrid columnProps={columnProps} {...props} />;
});
