import { skipToken } from "@simplicate/api-client";
import {
  ServiceGroupType,
  isServiceGroup,
  type GroupedServicesNodeModel,
  type ServiceGroupNodeModel,
  type ServiceNodeModel,
} from "@simplicate/grouped-services-manager";
import { useTranslation } from "@simplicate/translations";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  type ChangeServiceTreeBody,
  type Sales,
  type Service,
  type ServiceGroup,
  useAutoGroupServicesMutation,
  useChangeServiceTreeMutation,
  useDeleteSalesServiceMutation,
  useDeleteServiceGroupMutation,
  useDuplicateServiceMutation,
  useGetSalesQuery,
} from "../../data";

export const UNGROUPED_GROUP_ID = "__ungrouped__";

export type UseSalesServicesPageDataReturnType = ReturnType<typeof useSalesServicesPageData>;

export const useSalesServicesPageData = (onServiceGroupEdit: (group: ServiceGroup) => void, saleId?: string) => {
  const [treeData, setTreeData] = useState<GroupedServicesNodeModel[]>();
  const { data, isError: isGetSalesError } = useGetSalesQuery(/* istanbul ignore next */ saleId ?? skipToken, {
    refetchOnMountOrArgChange: true,
  });
  const [autoGroupServices, { isLoading: isAutoGroupServicesLoading, isSuccess: isAutoGroupServicesSuccess }] =
    useAutoGroupServicesMutation();
  const [changeServiceTree, { isLoading: isChangeServiceTreeLoading }] = useChangeServiceTreeMutation();
  const [deleteService, { isLoading: isDeleteServiceLoading }] = useDeleteSalesServiceMutation();
  const [deleteServiceGroup, { isLoading: isDeleteServiceGroupLoading }] = useDeleteServiceGroupMutation();
  const [duplicateService, { isLoading: isDuplicateServiceLoading }] = useDuplicateServiceMutation();
  const navigate = useNavigate();
  const { t } = useTranslation("sales_services");

  const rootId = 0;

  const [serviceGroupInDelete, setServiceGroupInDelete] = useState<ServiceGroup | undefined>(undefined);

  const { isLoading, loadingText, loadingMutation } = useMemo(() => {
    const loadingStates = {
      isAutoGroupServicesLoading,
      isChangeServiceTreeLoading,
      isDeleteServiceGroupLoading,
      isDeleteServiceLoading,
      isDuplicateServiceLoading,
    };

    const loadingMessages = {
      isAutoGroupServicesLoading: t("loading_messages.auto_group_services"),
      isChangeServiceTreeLoading: t("loading_messages.change_service_group_location"),
      isDeleteServiceLoading: t("loading_messages.delete_service_row"),
      isDeleteServiceGroupLoading: t("loading_messages.delete_services_group"),
      isDuplicateServiceLoading: t("loading_messages.duplicate_service_row"),
    };

    const mutationInLoading = Object.entries(loadingStates).filter(([, value]) => value);
    const isLoading = mutationInLoading.length > 0;

    return {
      isLoading,
      loadingText: loadingMessages[mutationInLoading[0]?.[0] as keyof typeof loadingMessages] ?? "",
      loadingMutation: isLoading ? loadingStates : undefined,
    };
  }, [
    isAutoGroupServicesLoading,
    isChangeServiceTreeLoading,
    isDeleteServiceGroupLoading,
    isDeleteServiceLoading,
    isDuplicateServiceLoading,
    t,
  ]);

  const buildServiceNode = useCallback(
    (service: Service, parent: string) =>
      ({
        id: service.id,
        text: service.description,
        parent: parent,
        data: {
          fixedPrice: service.invoicePrice,
          hoursTotalAmount: service.hourTypeConfiguration?.hourTypeTotals.hoursBudget,
          hoursTotalBudget: service.hourTypeConfiguration?.hourTypeTotals.specifiedTotal,
          invoiceMethod: service.invoiceMethod,
          isGrouped: parent !== UNGROUPED_GROUP_ID,
          purchaseTotalBudget: service.costTypeTotals?.budget,
          subscriptionCycle: service.subscriptionCycle,
          totalPrice: service.totalPrice,
          isLoading,
          loadingText,
          deleteCallback: () => {
            void deleteService(service.id);
          },
          duplicateCallback: () => {
            void duplicateService({ serviceId: service.id });
          },
          editCallback: () => {
            if (service.id) {
              navigate(`/sales/${saleId}/service/${service.id}`);
            }
          },
        },
      }) satisfies ServiceNodeModel,
    [deleteService, duplicateService, navigate, isLoading, loadingText, saleId],
  );

  const handleServiceGroupDelete = (group: ServiceGroup, shouldDeleteServices: boolean) => {
    void deleteServiceGroup({
      serviceGroupId: group.id,
      shouldDeleteServices,
    });
  };

  const buildServiceGroupNode = useCallback(
    (group: ServiceGroup) =>
      ({
        id: group.id,
        text: group.name,
        parent: rootId,
        droppable: true,
        data: {
          groupType: ServiceGroupType.NORMAL,
          type: group.type,
          description: group.description,
          isLoading,
          loadingMutation,
          loadingText,
          deleteCallback: () => setServiceGroupInDelete(group),
          editCallback: () => onServiceGroupEdit(group),
          totals: group.totals,
        },
      }) satisfies ServiceGroupNodeModel,
    [isLoading, loadingText, loadingMutation, onServiceGroupEdit],
  );

  const buildGroupedServicesTree = useCallback(
    (sales: Sales): GroupedServicesNodeModel[] => {
      const groups = [
        {
          id: UNGROUPED_GROUP_ID,
          text: t("ungrouped_services"),
          parent: rootId,
          droppable: true,
          data: { groupType: ServiceGroupType.UNGROUPED },
        },
        ...sales.groupedServices.map((group) => buildServiceGroupNode(group)),
      ];

      const ungroupedServices = sales.ungroupedServices.services.map((service) =>
        buildServiceNode(service, UNGROUPED_GROUP_ID),
      );

      const services = sales.groupedServices.flatMap((group) =>
        group.services.map((service) => buildServiceNode(service, group.id)),
      );

      return [...groups, ...ungroupedServices, ...services];
    },
    [buildServiceGroupNode, buildServiceNode, t],
  );

  const saveTreeStructure = useCallback(
    (treeData: GroupedServicesNodeModel[], saleId: string) => {
      // Process the groups first to preserve the order.
      const serviceGroups = treeData
        .filter((node) => isServiceGroup(node) && node.id !== UNGROUPED_GROUP_ID)
        .map((group) => ({
          groupId: group.id.toString(),
          serviceIds: treeData.filter((node) => node.parent === group.id).map((node) => node.id.toString()),
        }));

      const ungroupedServices = treeData
        .filter((node) => node.parent === UNGROUPED_GROUP_ID)
        .map((node) => node.id.toString());

      const payload: ChangeServiceTreeBody = {
        saleId: /* istanbul ignore next */ saleId ?? "",
        serviceGroupStructure: {
          structureForGroups: serviceGroups,
          ungroupedServiceIds: ungroupedServices,
        },
      };

      void changeServiceTree(payload);
    },
    [changeServiceTree],
  );

  useEffect(() => {
    if (data) {
      setTreeData(buildGroupedServicesTree(data));
    }
  }, [buildGroupedServicesTree, data]);

  const setAndSaveTreeData = (newTreeData: GroupedServicesNodeModel[]) => {
    /* istanbul ignore next */
    if (!newTreeData || !saleId) return;
    setTreeData(newTreeData);
    saveTreeStructure(newTreeData, saleId);
  };

  const groupServicesByInvoiceMethod = useCallback(() => {
    if (saleId) {
      void autoGroupServices(saleId);
    }
  }, [autoGroupServices, saleId]);

  return {
    groupServicesByInvoiceMethod,
    isAutoGroupServicesSuccess,
    isGetSalesError,
    isLoading,
    loadingMutation,
    loadingText,
    rootId,
    setAndSaveTreeData,
    treeData,
    serviceGroupInDelete,
    setServiceGroupInDelete,
    handleServiceGroupDelete,
  };
};
