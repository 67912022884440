import { useGetRevenueGroupsQuery } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { Select, Tooltip, TypedDropdownChangeEvent } from "@simplicate/ui";
import { memo } from "react";
import { useProjectForm } from "../hooks/useProjectServiceForm";

const useGetRevenueGroupSelectProps = () => {
  const { values, errors, touched, setRevenueGroup } = useProjectForm();
  const { data: options } = useGetRevenueGroupsQuery();

  return {
    options,
    defaultService: values.defaultService,
    existsOnInvoice: values.existsOnInvoice,
    revenueGroup: values.revenueGroup,
    touched: touched.revenueGroup,
    error: errors.revenueGroup,
    setRevenueGroup,
  };
};

export const RevenueGroupSelectContent = memo(function RevenueGroupSelectContent({
  options,
  defaultService,
  existsOnInvoice,
  revenueGroup,
  touched,
  error,
  setRevenueGroup,
}: ReturnType<typeof useGetRevenueGroupSelectProps>) {
  const { t } = useTranslation("project_services");

  const isDefaultServiceUndefined = defaultService === undefined;
  const compatibleOptions = options?.map((option) => ({
    label: option.name,
    value: option.id,
  }));

  const tooltip = existsOnInvoice ? t("revenue_group_locked_invoices_tooltip") : undefined;

  const select = (
    <Select
      testId="revenue-group-select"
      name="revenueGroup"
      label={t("revenue_group")}
      placeholder={t("revenue_group_placeholder")}
      emptyMessage={t("no_revenue_groups")}
      options={compatibleOptions}
      invalid={touched && error !== undefined}
      disabled={isDefaultServiceUndefined || existsOnInvoice}
      filter={false}
      value={revenueGroup}
      onChange={(event: TypedDropdownChangeEvent<string>) => setRevenueGroup?.(event.target.value)}
    />
  );

  return tooltip ? (
    <Tooltip message={tooltip} position="down">
      {select}
    </Tooltip>
  ) : (
    select
  );
});

export const RevenueGroupSelect = () => <RevenueGroupSelectContent {...useGetRevenueGroupSelectProps()} />;
