import * as Yup from "yup";
import { Planning } from "../../data";

export const buildValidationSchema = () =>
  Yup.object<Planning>({
    expectedStartDate: Yup.date().optional(),
    expectedEndDate: Yup.date()
      .optional()
      .when(["expectedStartDate"], ([expectedStartDate], schema) => {
        if (!expectedStartDate) {
          return schema;
        }

        return schema.min(expectedStartDate, "error_end_date_before_start_date");
      }),
  });
