import { format } from "date-fns";
import { useMemo } from "react";
import { useDataset, CubeQuery, useAppSelector, getActiveFilters } from "../../data";
import { CubeDimension, cubeDimensionToKey } from "../../types";

export function useBarChartData(
  query: Partial<CubeQuery>,
  pivotConfig: { x: readonly CubeDimension[]; y: readonly CubeDimension[] },
  selectCount: number,
  translations: Record<string, string>,
  labelFormat?: "month-name" | "unformatted",
) {
  const { filters } = useAppSelector(getActiveFilters);
  const { resultSet } = useDataset({
    ...query,
    state: {
      filters,
    },
  });

  return useMemo(() => {
    if (!resultSet) {
      return { datasets: [] };
    }

    const config = {
      x: pivotConfig.x.map(cubeDimensionToKey),
      y: pivotConfig.y.map(cubeDimensionToKey),
    };
    const labels = resultSet.categories(config).map(({ x }) => {
      if (labelFormat === "month-name") {
        return format(new Date(x), "MMM yy");
      }

      return x;
    });
    const datasets = resultSet.series(config).map((series) => {
      const seriesData = series.series.map(({ value }: { value: number }) => value);

      return {
        label: translations[series.key] ?? series.title,
        data: selectCount > 0 ? seriesData.slice(0, selectCount) : seriesData,
        yValues: [series.key],
      };
    });

    return {
      labels: selectCount > 0 ? labels.slice(0, selectCount) : labels,
      datasets,
    };
  }, [labelFormat, pivotConfig.x, pivotConfig.y, resultSet, selectCount, translations]);
}
