import { CurrencyFormat } from "@simplicate/number-format";
import { useTranslation } from "@simplicate/translations";
import { memo } from "react";
import { useProjectForm } from "../hooks/useProjectServiceForm";
import styles from "./Total.module.scss";

const useTotalProps = () => {
  const { budgetTotal } = useProjectForm();

  return {
    budgetTotal,
  };
};

export const TotalContent = memo(function TotalContent({ budgetTotal }: ReturnType<typeof useTotalProps>) {
  const { t } = useTranslation("project_services");

  return (
    <div className={styles.totalBlock}>
      <div className={styles.serviceTotalContainer}>
        <span className={styles.normalText}>{t("service_total")}</span>
        <CurrencyFormat className={styles.normalText} displayType="text" value={budgetTotal?.toString()} />
      </div>
    </div>
  );
});

export const Total = () => <TotalContent {...useTotalProps()} />;
