import { useTranslation } from "@simplicate/translations";
import { Button, Dialog, Icon } from "@simplicate/ui";
import { forwardRef } from "react";
import styles from "./ConfirmDeleteDialog.module.scss";

export const ConfirmDeleteDialog = forwardRef(function ConfirmDeleteDialog(_, ref) {
  const { t } = useTranslation("insights");

  return (
    <Dialog ref={ref}>
      <form className={styles.dialogContent}>
        <h3 className={styles.header}>
          <Icon icon="trash" fixedWidth />
          {t("general.delete_are_you_sure")}
        </h3>
        <p className={styles.body}>{t("general.delete_view_confirmation")}</p>
        <div className={styles.footer}>
          <Button type="submit">{t("general.button_delete")}</Button>
          <Button type="reset" variant="secondary">
            {t("general.button_cancel")}
          </Button>
        </div>
      </form>
    </Dialog>
  );
});
