import { InvoiceMethod } from "@simplicate/api-client";
import { Big, CostType, transformMoneyToMoneyFromAPI, CostTypeInServiceBody, CostTypeInService } from "../../data";
import { type WithSortOrder, randomId } from "./utils";
import type { CostTypeInForm, ValidCostTypeInForm } from "./types";

export const transformCostTypeInServiceToCostTypeInForm = ({
  id,
  defaultCostType,
  purchasePrice,
  sellingPrice,
  margin,
  isInvoiceable = true,
  hasRegistrations,
  total,
  description,
}: CostTypeInService): CostTypeInForm => {
  const costType = {
    id,
    name: description,
    defaultCostType: {
      id: defaultCostType.id,
      name: defaultCostType.name,
    },
    purchasePrice: {
      amount: Big(purchasePrice.amount),
      currency: purchasePrice.currency,
    },
    sellingPrice: {
      amount: Big(sellingPrice.amount),
      currency: sellingPrice.currency,
    },
    margin,
    total: {
      amount: Big(total.amount),
      currency: "EUR" as const,
    },
    isInvoiceable,
    hasRegistrations,
  };

  if (defaultCostType.usePricePerUnit && defaultCostType.unit) {
    return {
      ...costType,
      usePricePerUnit: true,
      unit: defaultCostType.unit,
      quantity: 1,
    };
  }

  return {
    ...costType,
    usePricePerUnit: false,
  };
};

export const transformCostTypeToCostTypeInForm = (
  { id, name, purchasePrice, sellingPrice, usePricePerUnit, unit, margin }: CostType,
  label: string,
): CostTypeInForm => {
  const costTypeInForm = {
    /*
      We only need a unique id to support editing the new costType's values in the form,
      the ProjectService is submitted without these costType generated ids
      and the back-end will generate the ids itself
    */
    id: `NOT_A_VALID_BACKEND_ID_${randomId()}`,
    name: label,
    defaultCostType: {
      id,
      name,
    },
    purchasePrice: {
      amount: Big(purchasePrice.amount),
      currency: purchasePrice.currency,
    },
    sellingPrice: {
      amount: Big(sellingPrice.amount),
      currency: sellingPrice.currency,
    },
    margin,
    total: {
      amount: Big(sellingPrice.amount),
      currency: sellingPrice.currency,
    },
    isInvoiceable: true,
    hasRegistrations: false,
  };

  if (usePricePerUnit && unit) {
    return {
      ...costTypeInForm,
      usePricePerUnit: true,
      unit,
      quantity: 1,
    };
  }

  return {
    ...costTypeInForm,
    usePricePerUnit: false,
  };
};

export const transformToCreateServiceCostType = (
  {
    id,
    defaultCostType,
    name,
    purchasePrice,
    sellingPrice,
    quantity,
    isInvoiceable,
    sortOrder,
  }: WithSortOrder<ValidCostTypeInForm>,
  invoiceMethod: InvoiceMethod,
): CostTypeInServiceBody => {
  return {
    id,
    defaultCostTypeId: defaultCostType.id,
    description: name,
    purchasePrice: transformMoneyToMoneyFromAPI(purchasePrice),
    sellingPrice: transformMoneyToMoneyFromAPI(sellingPrice),
    quantity,
    isInvoiceable: invoiceMethod === InvoiceMethod.time_and_expenses ? isInvoiceable : undefined,
    sortOrder,
  };
};
