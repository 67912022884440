import { format } from "date-fns";
import { useCallback } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { getDateRangeFilter, useAppSelector } from "../../data";
import { CubeDimension, RecordShape, cubeDimensionToKey } from "../../types";

const SEARCH_PARAM_DATE_FORMAT = "yyyy-MM-dd";

export type Mapping = {
  recordKey: CubeDimension;
  searchParam: string;
};

export const useNavigateToDetailsPage = (mapping: readonly Mapping[], targetUrl: string) => {
  const navigate = useNavigate();
  const dateRange = useAppSelector(getDateRangeFilter);

  const handleClick = useCallback(
    (record: RecordShape) => {
      const mappedParams = Object.fromEntries(
        mapping
          .map(({ recordKey: key, searchParam }) =>
            typeof record[cubeDimensionToKey(key)] === "string"
              ? [searchParam, record[cubeDimensionToKey(key)]]
              : undefined,
          )
          .filter((entry): entry is [string, string] => entry !== undefined),
      );

      const contextualParams = {
        startDate: format(dateRange.start, SEARCH_PARAM_DATE_FORMAT),
        endDate: format(dateRange.end, SEARCH_PARAM_DATE_FORMAT),
      };

      const params = {
        ...contextualParams,
        ...mappedParams,
      };

      navigate({
        pathname: targetUrl,
        search: createSearchParams(params).toString(),
      });
    },
    [dateRange.end, dateRange.start, mapping, navigate, targetUrl],
  );

  return handleClick;
};
