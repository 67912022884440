import { useGetDefaultServicesQuery } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { Select, Tooltip, TypedDropdownChangeEvent } from "@simplicate/ui";
import { memo } from "react";
import { useProjectForm } from "../hooks/useProjectServiceForm";

const useDefaultServiceSelectProps = () => {
  const { data: options } = useGetDefaultServicesQuery();
  const { values, errors, touched, setDefaultService } = useProjectForm();

  return {
    existsOnInvoice: values.existsOnInvoice,
    defaultService: values.defaultService,
    touched: touched.defaultService,
    error: errors.defaultService,
    setDefaultService,
    options,
  };
};

const DefaultServiceSelectContent = memo(function DefaultServiceSelectContent({
  existsOnInvoice,
  defaultService,
  touched,
  error,
  setDefaultService,
  options,
}: ReturnType<typeof useDefaultServiceSelectProps>) {
  const { t } = useTranslation("project_services");

  const compatibleOptions = options?.map((option) => ({
    label: option.description,
    value: option.id,
  }));

  const tooltip = existsOnInvoice ? t("default_service_locked_invoices_tooltip") : undefined;

  const select = (
    <Select
      testId="default_service_select"
      name="defaultService"
      label={t("default_service")}
      placeholder={t("default_service_placeholder")}
      emptyFilterMessage={t("no_default_services_found")}
      emptyMessage={t("no_default_services")}
      options={compatibleOptions}
      value={defaultService}
      onChange={(event: TypedDropdownChangeEvent<string>) => setDefaultService?.(event.target.value)}
      invalid={touched && error !== undefined}
      disabled={existsOnInvoice}
    />
  );

  return tooltip ? (
    <Tooltip message={tooltip} position="right">
      {select}
    </Tooltip>
  ) : (
    select
  );
});

export const DefaultServiceSelect = () => <DefaultServiceSelectContent {...useDefaultServiceSelectProps()} />;
