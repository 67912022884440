import { skipToken } from "@simplicate/api-client";
import { useEffect, useMemo } from "react";
import { useBuildProjectServiceQuery, BuiltProjectService, transformMoneyFromAPIToMoney } from "../../../data";
import { transformHoursRegistrationConfiguration } from "../transformProjectServiceToForm";

type useBuildProjectServiceProps = {
  projectId: string | undefined;
  isEditingService: boolean;
  defaultServiceValue: string | undefined;
  defaultServiceTouched: boolean;
  setValuesFromProjectService: (projectService: BuiltProjectService) => void;
  isSubmitting: boolean;
};

export const useBuildProjectService = ({
  defaultServiceValue,
  defaultServiceTouched,
  projectId,
  isEditingService,
  isSubmitting,
  setValuesFromProjectService,
}: useBuildProjectServiceProps) => {
  const shouldFetchWhenCreating = !isEditingService;
  const shouldFetchWhenEditing = isEditingService && defaultServiceTouched;
  const isInitial = !(shouldFetchWhenCreating || shouldFetchWhenEditing);

  const shouldFetch = projectId !== undefined && defaultServiceValue !== undefined;

  const {
    data: projectService,
    isSuccess,
    isFetching,
  } = useBuildProjectServiceQuery(
    shouldFetch ? { defaultServiceId: defaultServiceValue, projectId: projectId } : skipToken,
  );

  useEffect(() => {
    if (isFetching || !isSuccess || projectService == undefined || isInitial || isSubmitting) {
      return;
    }

    setValuesFromProjectService(projectService);
  }, [isSuccess, projectService, setValuesFromProjectService, isFetching, isInitial, isSubmitting]);

  const defaultPrices = useMemo(() => {
    const prices = projectService?.defaultService?.defaultPrices;

    if (!prices) return;

    const subscription = prices.subscription;

    return {
      subscription: {
        monthly: subscription.monthly && transformMoneyFromAPIToMoney(subscription.monthly),
        quarterly: subscription.quarterly && transformMoneyFromAPIToMoney(subscription.quarterly),
        halfYearly: subscription.halfYearly && transformMoneyFromAPIToMoney(subscription.halfYearly),
        yearly: subscription.yearly && transformMoneyFromAPIToMoney(subscription.yearly),
      },
      fixedPrice: transformMoneyFromAPIToMoney(prices.fixedPrice),
      isPriceLocked: prices.isPriceLocked ?? false,
    };
  }, [projectService]);

  return {
    defaultPrices,
    isInitial,
    employeeRateAgreements:
      projectService?.hoursRegistrationConfiguration &&
      transformHoursRegistrationConfiguration(projectService.hoursRegistrationConfiguration)?.employeeRateAgreements,
    hourTypeRateAgreements:
      projectService?.hoursRegistrationConfiguration &&
      transformHoursRegistrationConfiguration(projectService.hoursRegistrationConfiguration)?.hourTypeRateAgreements,
  };
};
