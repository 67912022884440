import { useTranslation } from "@simplicate/translations";
import { Icon } from "@simplicate/ui";
import classNames from "classnames";
import styles from "../ProjectToggle/ProjectToggle.module.scss";

export type ProjectToggleProps = {
  onChange: () => void;
  isNew: boolean;
};

export const ProjectToggle = ({ onChange, isNew }: ProjectToggleProps) => {
  const { t } = useTranslation("sales_convert_to_project");

  return (
    <div className={styles.toggleWrapper}>
      <input
        type="checkbox"
        className={styles.toggleInput}
        name="projectToggle"
        id="projectToggle"
        onChange={onChange}
      />
      <label className={styles.toggleLabel} htmlFor="projectToggle">
        <span className={classNames(styles.toggleNew, isNew && styles.active)}>
          <Icon className={styles.toggleIcon} icon="sparkles" />
          {t("new_project")}
        </span>
        <span className={classNames(styles.toggleExisting, !isNew && styles.active)}>
          <Icon className={styles.toggleIcon} icon="penRuler" />
          {t("existing_project")}
        </span>
      </label>
    </div>
  );
};
