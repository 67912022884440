import { createSelector } from "@simplicate/state";
import { cubeDimensionToKey } from "../../../../types";
import { getActiveViewPreset } from "./getActiveViewPreset";
import { getLocalChanges } from "./getLocalChanges";

export const getActiveFilters = createSelector(
  [getActiveViewPreset, getLocalChanges],
  (activeDashboard, localChanges) => {
    const filters = localChanges.filters ?? activeDashboard?.filters ?? [];

    const uniqueFilterDimensions = new Set(filters.map(({ valueDimension }) => cubeDimensionToKey(valueDimension)));
    const filterValues = Object.fromEntries(
      [...uniqueFilterDimensions].map(
        (dimension) =>
          [
            dimension,
            filters
              .filter(({ valueDimension }) => cubeDimensionToKey(valueDimension) === dimension)
              .map(({ value }) => value),
          ] as const,
      ),
    );

    return {
      filters,
      filterValues,
    };
  },
);
