import { skipToken } from "@simplicate/api-client";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useGetProjectQuery } from "../../data";

export const useProjectRelation = () => {
  const { id: projectId } = useParams<{ id: string }>();

  const { data, isLoading } = useGetProjectQuery(projectId ?? skipToken);

  const relation = useMemo(() => data?.relation, [data]);

  return { relation, isLoading };
};
