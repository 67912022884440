import { useAppSelector } from "@simplicate/state";
import { useTranslation } from "@simplicate/translations";
import { NavMenu, NavMenuProps } from "@simplicate/ui";
import { BusinessIntelligence } from "./BusinessIntelligence";
import { Crm } from "./Crm";
import { Dashboard } from "./Dashboard";
import { EnvironmentManagement } from "./EnvironmentManagement";
import { Hours } from "./Hours";
import { Hrm } from "./Hrm";
import { Insights } from "./Insights";
import { Invoices } from "./Invoices";
import { Projects } from "./Projects";
import { ResourcePlanner } from "./ResourcePlanner";
import { Sales } from "./Sales";

export const MainMenu = (
  props: Omit<NavMenuProps, "children" | "collapseMenuMessage" | "expandMenuMessage" | "skipToContentMessage">,
) => {
  const { t } = useTranslation("main_menu");
  const { showOverlay } = useAppSelector(/* istanbul ignore next */ ({ state }) => state.header);

  return (
    <NavMenu
      {...props}
      testId="main-nav"
      skipToContentMessage={t("skip_to_content")}
      collapseMenuMessage={t("collapse_menu")}
      expandMenuMessage={t("expand_menu")}
      showOverlay={showOverlay}
    >
      <NavMenu.Group>
        <Dashboard />
        <Crm />
        <Hrm />
        <Sales />
        <Projects />
        <ResourcePlanner />
        <Hours />
        <Invoices />
        <Insights />
        <BusinessIntelligence />
      </NavMenu.Group>
      <NavMenu.Group>
        <EnvironmentManagement />
      </NavMenu.Group>
    </NavMenu>
  );
};
