import { simplicateApiV3 } from "../components";
import { createTagTypes } from "../tags";

export type SupportedAddOns = "hyarchiscomply";

export type AddOnStatus = {
  id: string;
  isActive: boolean;
};

const tags = createTagTypes({
  tagPrefix: "AddOns",
  tags: ["item"],
});

const endpoints = simplicateApiV3
  .enhanceEndpoints({
    addTagTypes: Object.values(tags),
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getAddOnStatusById: builder.query<AddOnStatus, SupportedAddOns>({
        query: (id) => `addon.status/${id}`,
        providesTags: (_result, _error, identifier) => [{ type: tags.item, id: identifier }] as const,
      }),
    }),
  });

export const { useGetAddOnStatusByIdQuery } = endpoints;
