import { createTagTypes, simplicateApiV2 } from "@simplicate/api-client";
import { transformAPIEmployee } from "../utils";
import type { Employee } from "../types";

const tags = createTagTypes({ tagPrefix: "Employee", tags: ["entity"] });

const endpoints = simplicateApiV2.enhanceEndpoints({ addTagTypes: Object.values(tags) }).injectEndpoints({
  endpoints: (builder) => ({
    getAllEmployeeBirthdays: builder.query<Employee[], void>({
      query: () => ({
        url: `/hrm/employee?q[employment_status]=active&q[person.date_of_birth]=*&q[person.date_of_birth][ge]=1900-01-01`,
      }),
      transformResponse: transformAPIEmployee,
      providesTags: [tags.entity],
    }),
  }),
});

export const { useGetAllEmployeeBirthdaysQuery } = endpoints;
