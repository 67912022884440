import { InvoiceMethod } from "@simplicate/api-client";
import { CurrencyFormat } from "@simplicate/number-format";
import { useTranslation } from "@simplicate/translations";
import { memo, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Big } from "../../data";
import { useProjectForm } from "./hooks/useProjectServiceForm";
import { useServiceTotal } from "./hooks/useServiceTotal";
import styles from "./ProjectServiceHeader.module.scss";

type ProjectServiceHeaderProps = {
  testId?: string;
};

const useProjectServiceHeader = () => {
  const { values } = useProjectForm();
  const { serviceId } = useParams();
  const price = useServiceTotal({
    hourTypesSpecifiedTotalAmount: values.hourTypesSpecifiedTotal?.amount ?? Big(0),
    costTypes: values.costTypes ?? [],
  });

  return {
    invoiceMethod: values.invoiceMethod,
    description: values.description,
    serviceId,
    price,
  };
};

type ProjectServiceHeaderContentProps = ReturnType<typeof useProjectServiceHeader> & {
  testId?: string;
};

export const ProjectServiceHeaderContent = memo(function ProjectServiceHeaderContent({
  testId,
  price,
  description,
  invoiceMethod,
  serviceId,
}: ProjectServiceHeaderContentProps) {
  const { t } = useTranslation("project_services");
  const invoiceName = useMemo(() => {
    if (!invoiceMethod) return undefined;

    const labelsForInvoiceMethods = {
      [InvoiceMethod.fixed_price]: t("fixed_price"),
      [InvoiceMethod.subscription]: t("subscription"),
      [InvoiceMethod.time_and_expenses]: t("time_and_expenses"),
    };

    return labelsForInvoiceMethods[invoiceMethod];
  }, [invoiceMethod, t]);

  const isEditing = serviceId !== undefined;
  const title = isEditing ? description : t("create_project_service_page_title");

  return (
    <header>
      {title && (
        <h2 className={styles.title} data-testid={`${testId}-title`}>
          {title}
        </h2>
      )}

      {isEditing && (
        <p className={styles.text} data-testid={`${testId}-invoice`}>
          {invoiceName && <span data-testid={`${testId}-invoice-name`}>{invoiceName}: </span>}
          {price && <CurrencyFormat data-testid={`${testId}-price`} value={price.toString()} />}
        </p>
      )}
    </header>
  );
});

export const ProjectServiceHeader = ({ testId }: ProjectServiceHeaderProps) => (
  <ProjectServiceHeaderContent {...useProjectServiceHeader()} testId={testId} />
);
