import { useTranslation } from "@simplicate/translations";
import { Table, buildColumnProps, SkeletonLoader, Widget, Counter, Avatar } from "@simplicate/ui";
import { DataTableSortMeta } from "primereact/datatable";
import { useMemo, useCallback, useState } from "react";
import { useDashboardContext } from "../../components/Dashboard";
import { FormattedValue } from "../../components/FormattedValue";
import { getColumns, getOrder, orderBy, useAppSelector, useAppDispatch } from "../../data";
import { CubeDimension, cubeDimensionToKey, Column, RecordShape } from "../../types";
import styles from "./TableWidget.module.scss";

type AvatarColumnProps = {
  column: Column;
  value?: boolean | number | string;
  url: string;
  altText: string;
};

type TableWidgetProps = {
  columns: readonly Column[];
  title: string;
  onRecordSelect?: (record: RecordShape) => void;
};

const AvatarColumn = ({ column, value, url, altText }: AvatarColumnProps) => (
  <span className={styles.wrapper}>
    <span className={styles.avatarWrapper}>
      <Avatar size="small" src={url} altText={altText} />
    </span>
    <FormattedValue formatter={column.format} value={value} />
  </span>
);

// Top UI (3rem) + height of filterwidget (100px) + height of the table header (40px) = 188px
// Negative values lower the observed boundary the element needs to cross to be out of view
const STICKY_HEADER_MARGIN = "-188px";

export const TableWidget = ({ title, columns, onRecordSelect }: TableWidgetProps) => {
  const { t } = useTranslation("insights");
  const stateColumns = useAppSelector(getColumns);
  const order = useAppSelector(getOrder);
  const dispatch = useAppDispatch();
  const [sortField, setSortField] = useState<string>("");

  const {
    queryResult: { resultSet, isLoading },
  } = useDashboardContext();
  const dimensions = useMemo(
    () =>
      columns
        .flatMap((column) => [
          column.dimension,
          column.avatarConfig?.avatarDimension,
          column.avatarConfig?.initialsDimension,
        ])
        .filter((dimension: CubeDimension | undefined): dimension is CubeDimension => dimension !== undefined),
    [columns],
  );

  const formattedColumns = useMemo(() => {
    return columns
      .map((column, index) => {
        const fieldName = cubeDimensionToKey(column.dimension);
        const stateColumn = stateColumns.find((stateColumn) => cubeDimensionToKey(stateColumn.dimension) === fieldName);
        const order = stateColumn?.order ?? index;
        const isVisible = (!column.hidden && stateColumn?.visible) ?? true;

        return {
          ...column,
          order,
          hidden: !isVisible,
          header: column.title(t),
          body: (record: Record<string, boolean | number | string>) => {
            const value = record[fieldName];

            if (column.avatarConfig !== undefined) {
              const filename = record[cubeDimensionToKey(column.avatarConfig.avatarDimension)];
              const url = `/api/v2/storage/thumbnail?filename=${filename}&thumbnailsize=2x2cm&validation=avatar`;
              const altText = record[cubeDimensionToKey(column.avatarConfig.initialsDimension)];

              return <AvatarColumn column={column} value={value} altText={altText?.toString() ?? ""} url={url} />;
            }

            return <FormattedValue formatter={column.format} value={value} />;
          },
        };
      })
      .filter((column) => column.hidden === false)
      .sort((a, b) => a.order - b.order);
  }, [columns, stateColumns, t]);

  const value = useMemo(() => {
    if (isLoading) return new Array(10).fill(null).map(() => ({}));
    if (!resultSet) return [];

    const processRow = (row: Record<string, unknown>) => {
      const result: Record<string, unknown> = {};

      dimensions.forEach((dimension) => {
        const key = cubeDimensionToKey(dimension);

        result[key] = row[key];
      });

      return result;
    };

    return resultSet.tablePivot().map(processRow);
  }, [dimensions, isLoading, resultSet]);

  const [multiSortMeta, setMultiSortMeta] = useState<DataTableSortMeta[]>(
    () =>
      order?.map((order) => ({
        field: order.column,
        order: order.direction === "asc" ? 1 : -1,
      })) ?? [],
  );
  const handleSort = useCallback(
    ({ sortField }: { sortField: string }) => {
      dispatch(orderBy(sortField));
      setMultiSortMeta((prev) => {
        const newSortMeta = [...prev];
        const index = newSortMeta.findIndex((meta) => meta.field === sortField);

        setSortField(sortField);

        if (index === -1) {
          newSortMeta.push({ field: sortField, order: 1 });
        } else {
          /*istanbul ignore next*/
          newSortMeta[index]!.order = newSortMeta[index]!.order === 1 ? -1 : 1;
        }

        return newSortMeta;
      });
    },
    [dispatch],
  );

  const handleRowClick = useCallback(
    (event: { data: RecordShape }) => {
      /*istanbul ignore next*/
      onRecordSelect?.(event.data);
    },
    [onRecordSelect],
  );

  return (
    <Widget
      title={title}
      variant="borderless"
      titleSize="large"
      annotation={!isLoading && <Counter value={value.length} max={false} />}
    >
      <Table
        value={value}
        multiSortMeta={multiSortMeta}
        sortField={sortField}
        sortOrder={multiSortMeta.find((meta) => meta.field === sortField)?.order}
        onRowClick={handleRowClick}
        onSort={handleSort}
        enableStickyHeader
        stickyHeaderMargin={STICKY_HEADER_MARGIN}
      >
        {formattedColumns.map((column) => (
          <Table.Column
            key={cubeDimensionToKey(column.dimension)}
            {...buildColumnProps({
              ...column,
              field: cubeDimensionToKey(column.dimension),
              body: isLoading ? <SkeletonLoader /> : column.body,
            })}
          />
        ))}
      </Table>
    </Widget>
  );
};
