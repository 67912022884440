import { Action, ThunkAction } from "@simplicate/state";
import { setOrder } from "../dashboardSlice";
import { ColumnOrder, DashboardRootState } from "../types";

function flipDirection(direction: "asc" | "desc"): "asc" | "desc" {
  return direction === "asc" ? "desc" : "asc";
}

function buildOrderingState(order: ColumnOrder[], column: string): ColumnOrder[] {
  const existingOrder = order.find(({ column: col }) => col === column);

  if (existingOrder) {
    return [{ column, direction: flipDirection(existingOrder.direction) }];
  }

  return [{ column, direction: "asc" }];
}

export const orderByThunk =
  (column: string): ThunkAction<void, DashboardRootState, unknown, Action<"Insights Dashboard State/setOrder">> =>
  (dispatch, getState) => {
    const slice = getState().state.insights.dashboard_views;
    const order =
      slice.localChanges.order ??
      (slice.activeDashboardId ? slice.dashboards[slice.activeDashboardId]?.order : []) ??
      [];

    const newOrder = buildOrderingState(order, column);

    dispatch(setOrder(newOrder));
  };
