import classnames from "classnames";
import { PropsWithChildren } from "react";
// eslint-disable-next-line css-modules/no-unused-class -- classes are used in styles[direction]
import styles from "./InputGroup.module.scss";

type InputGroupProps = {
  direction?: "horizontal" | "vertical";
  spacing?: "large" | "medium";
  label?: string;
  hasBorder?: boolean;
  testId?: string;
};

export const InputGroup = ({
  children,
  direction = "vertical",
  spacing = "medium",
  label,
  hasBorder = false,
  testId,
}: PropsWithChildren<InputGroupProps>) => {
  return (
    <div className={styles.inputGroupWrapper}>
      {label && <span className={styles.inputGroupLabel}>{label}</span>}
      <div
        className={classnames(
          styles.inputGroup,
          styles[direction],
          styles[`${spacing}Spacing`],
          hasBorder && direction !== "horizontal" && styles.hasBorder,
        )}
        data-testid={testId}
      >
        {children}
      </div>
    </div>
  );
};
