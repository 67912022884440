import { Clickable } from "../Clickable";
import { Icon, IconName } from "../Icon";
import styles from "./FloatingActionButton.module.scss";

type FloatingActionButtonProps = {
  onClick: () => void;
  hoverMessage: string;
  icon: IconName;
};

export const FloatingActionButton = ({ onClick, icon, hoverMessage }: FloatingActionButtonProps) => {
  return (
    <Clickable className={styles.floatingActionButton} onClick={onClick}>
      <div className={styles.icon}>
        <Icon icon={icon} />
      </div>
      <div className={styles.animateContainer}>
        <span className={styles.text}>{hoverMessage}</span>
      </div>
    </Clickable>
  );
};
