import { RadioButton } from "@simplicate/ui";
import { Field } from "formik";
import { ServiceData } from "../../SalesConvertToProjectPage.types";
import { InputLabel } from "../InputLabel";

type ServiceRadioProps = {
  groupId?: string;
  service: ServiceData;
};

export const ServiceRadio = ({ groupId, service }: ServiceRadioProps) => {
  const { id, invoiceMethod, name, subscriptionCycle } = service;

  return (
    <Field as={RadioButton} value={id} name={`services.${groupId}`}>
      <InputLabel name={name} invoiceMethod={invoiceMethod} subscriptionCycle={subscriptionCycle} />
    </Field>
  );
};
