import { InvoiceMethod, useGetInvoiceMethodsQuery } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { Select, Tooltip, TypedDropdownChangeEvent } from "@simplicate/ui";
import { memo } from "react";
import { useParams } from "react-router-dom";
import { useProjectForm } from "../hooks/useProjectServiceForm";

const useInvoiceMethodSelectProps = () => {
  const { data: invoiceMethodsResponse } = useGetInvoiceMethodsQuery();
  const { values, errors, touched, setInvoiceMethod } = useProjectForm();
  const { serviceId } = useParams();

  return {
    invoiceMethods: invoiceMethodsResponse?.invoiceMethods,
    defaultService: values.defaultService,
    invoiceMethod: values.invoiceMethod,
    touched: touched.invoiceMethod,
    error: errors.invoiceMethod,
    setInvoiceMethod,
    serviceId,
  };
};

const InvoiceMethodSelectData = memo(function InvoiceMethodSelectData({
  invoiceMethods = [],
  invoiceMethod,
  touched,
  error,
  setInvoiceMethod,
  serviceId,
  defaultService,
}: ReturnType<typeof useInvoiceMethodSelectProps>) {
  const { t } = useTranslation("project_services");
  const isEditingService = serviceId !== undefined;
  const labelsForInvoiceMethods = {
    [InvoiceMethod.fixed_price]: t("fixed_price"),
    [InvoiceMethod.subscription]: t("subscription"),
    [InvoiceMethod.time_and_expenses]: t("time_and_expenses"),
  };

  const compatibleOptions = invoiceMethods.map((option) => ({
    label: labelsForInvoiceMethods[option],
    value: option,
  }));
  const isDefaultServiceUndefined = defaultService === undefined;

  const disabled = isEditingService || isDefaultServiceUndefined;
  const disabledTooltip = isEditingService ? t("invoice_method_cannot_be_changed") : undefined;

  const select = (
    <Select
      testId="invoice_method_select"
      name="invoiceMethod"
      label={t("invoice_method")}
      placeholder={t("invoice_method_placeholder")}
      emptyMessage={t("no_default_services")}
      value={invoiceMethod}
      options={compatibleOptions}
      onChange={(event: TypedDropdownChangeEvent<InvoiceMethod>) => setInvoiceMethod?.(event.target.value)}
      invalid={touched && error !== undefined}
      disabled={disabled}
      filter={false}
    />
  );

  return (
    <>
      {!disabledTooltip && select}
      {disabled && disabledTooltip && (
        <Tooltip message={disabledTooltip} position="right">
          {select}
        </Tooltip>
      )}
    </>
  );
});

export const InvoiceMethodSelect = () => <InvoiceMethodSelectData {...useInvoiceMethodSelectProps()} />;
