/* istanbul ignore file -- this file contains no logic */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const ASSETS_BASE_URL = "https://assets.simplicateapp.com/";

export const simplicateAssetsClient = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: ASSETS_BASE_URL,
    responseHandler: "text",
  }),
  endpoints: () => ({}),
  reducerPath: "assets",
});
