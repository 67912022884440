import { PropsWithChildren } from "react";
import { ScrollContainer } from "./ScrollContainer";
import styles from "./TabBar.module.scss";

type TabBarProps = PropsWithChildren<{
  testId?: string;
  actions?: React.ReactNode;
}>;

export const TabBar = ({ testId, children, actions }: TabBarProps) => {
  return (
    <div className={styles.tabBar} data-testid={`tab-bar-${testId}`}>
      <ScrollContainer testId={testId && `${testId}-container`} className={styles.tabsContainer}>
        {children}
      </ScrollContainer>
      {actions && <div className={styles.actionsContainer}>{actions}</div>}
    </div>
  );
};
