import { useNumericFormatter } from "@simplicate/number-format";
import { useTranslation } from "@simplicate/translations";
import { Alert, Link } from "@simplicate/ui";
import { memo } from "react";
import { useProjectForm } from "./hooks/useProjectServiceForm";
import styles from "./ProjectServiceTotalInfo.module.scss";

export const useProjectServiceTotalInfoProps = () => {
  const { values, setInvoicePrice, budgetTotal } = useProjectForm();

  const invoicePrice = values.invoicePrice;

  return {
    invoicePrice,
    setInvoicePrice,
    budgetTotal,
  };
};
export const ProjectServiceTotalInfoContent = memo(function ProjectServiceTotalInfoContent({
  invoicePrice,
  setInvoicePrice,
  budgetTotal,
}: ReturnType<typeof useProjectServiceTotalInfoProps>) {
  const { t } = useTranslation("project_services");
  const formattedCalculatedTotal = useNumericFormatter(budgetTotal?.toString());

  if (!invoicePrice?.amount) {
    return;
  }

  if (budgetTotal?.cmp(invoicePrice.amount) === 0) {
    return;
  }

  return (
    <Alert type="info">
      <div className={styles.totalInfo}>
        <span>
          {t("invoice_price_differs_from_calculated", {
            value: formattedCalculatedTotal,
          })}
        </span>

        <Link testId="reset-invoice-price-button" onClick={() => setInvoicePrice?.(budgetTotal)}>
          {t("reset_invoice_price")}
        </Link>
      </div>
    </Alert>
  );
});

export const ProjectServiceTotalInfo = () => <ProjectServiceTotalInfoContent {...useProjectServiceTotalInfoProps()} />;
