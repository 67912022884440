import { useTranslation } from "@simplicate/translations";
import { Icon, Link } from "@simplicate/ui";
import { useCallback } from "react";
import { getActiveViewPreset, requestSave, useAppDispatch, useAppSelector } from "../../data";

export const EditNameAction = () => {
  const { t } = useTranslation("insights");
  const dashboard = useAppSelector(getActiveViewPreset);
  const dispatch = useAppDispatch();

  const handleClick = useCallback(() => {
    dispatch(requestSave("change-name"));
  }, [dispatch]);

  return (
    <Link testId="edit-view-button" onClick={handleClick} disabled={!dashboard?.id}>
      <Icon icon="pen" fixedWidth />
      {t("general.edit_view")}
    </Link>
  );
};
