import { useTranslation, Trans } from "@simplicate/translations";
import { Button, Clickable, Icon } from "@simplicate/ui";
import { lightSpacingXs } from "@simplicate-software/design-tokens";
import { ForwardedRef, forwardRef } from "react";
import {
  HourTypeDeviationGrid,
  EmployeeHourlyRateDeviationGrid,
} from "../ProjectServicePage/grids/HourlyRateDeviationGrid";
import { type RateWithAgreedRate } from "../ProjectServicePage/hooks/useRateAgreements";
import { DialogBase } from "./DialogBase";
import { useProjectRelation } from "./useProjectRelation";

export type DialogTariffRateDeviationProps = {
  rateWithAgreedRate: RateWithAgreedRate[];
  onRateChange: (hourlyRates: RateWithAgreedRate[]) => void;
  ref: ForwardedRef<HTMLDialogElement>;
  type: "HOUR_TYPE" | "HOURLY_RATE";
};

export const DialogTariffRateDeviation = forwardRef(function DialogTariffRateDeviation(
  { rateWithAgreedRate, onRateChange, type }: DialogTariffRateDeviationProps,
  ref,
) {
  const { t } = useTranslation("project_services");

  const { relation: { name, relationEntityId } = { name: t("relation"), relationEntityId: undefined } } =
    useProjectRelation();

  return (
    <DialogBase ref={ref} maxWidth="large" onSubmit={() => onRateChange(rateWithAgreedRate)}>
      <DialogBase.Title>{t("rate_agreements")}</DialogBase.Title>
      <DialogBase.Description>
        <Trans
          ns="project_services"
          i18nKey="rate_agreements_deviation_{{name}}"
          values={{ name }}
          components={{
            Clickable: (
              <Clickable to={relationEntityId && `/crm/organizations/${relationEntityId}`} target="_blank"></Clickable>
            ),
            Icon: <Icon icon="faArrowUpRightFromSquare" />,
            span: (
              <span
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  gap: lightSpacingXs,
                  textDecoration: relationEntityId ? "underline" : "none",
                  textTransform: relationEntityId ? undefined : "lowercase",
                  cursor: relationEntityId ? "pointer" : "default",
                }}
              ></span>
            ),
          }}
        ></Trans>
      </DialogBase.Description>
      <DialogBase.Content>
        {type === "HOUR_TYPE" ? (
          <HourTypeDeviationGrid value={rateWithAgreedRate} onApplyTariff={onRateChange} />
        ) : (
          <EmployeeHourlyRateDeviationGrid value={rateWithAgreedRate} onApplyTariff={onRateChange} />
        )}
      </DialogBase.Content>
      <DialogBase.Footer>
        <Button testId="confirm-changing-rates" variant="secondary" size="small" type="submit">
          {t("rate_agreements_confirm_changes")}
        </Button>
        <Button variant="subtle" type="reset">
          {t("rate_agreements_cancel_changes")}
        </Button>
      </DialogBase.Footer>
    </DialogBase>
  );
});
