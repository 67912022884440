import { useTranslation } from "@simplicate/translations";
import { Clickable, Dropdown, Icon } from "@simplicate/ui";
import classNames from "classnames";
import { useState, useCallback } from "react";
import {
  getActiveViewPreset,
  getAvailableViewPresets,
  getChangedMeta,
  removeActivePreset,
  setActivePreset,
  useAppDispatch,
  useAppSelector,
} from "../../data";
import { DeleteViewAction } from "./DeleteViewAction";
import { EditNameAction } from "./EditNameAction";
import { RestoreViewAction } from "./RestoreViewAction";
import { SaveAsAction } from "./SaveAsAction";
import { SaveViewAction } from "./SaveViewAction";
import styles from "./ViewMenu.module.scss";

export const ViewMenu = () => {
  const { t } = useTranslation("insights");
  const [isOpen, setIsOpen] = useState(false);
  const views = useAppSelector(getAvailableViewPresets);
  const activePreset = useAppSelector(getActiveViewPreset);
  const { hasChanges } = useAppSelector(getChangedMeta);
  const dispatch = useAppDispatch();

  const handleDefaultView = () => {
    dispatch(removeActivePreset());
    setIsOpen(false);
  };

  const handleViewChange = (viewId: number) => {
    const selectedView = views.find((view) => view.id === viewId);

    // istanbul ignore else -- The selected view should always exist
    if (selectedView) {
      dispatch(setActivePreset(selectedView));
    }

    setIsOpen(false);
  };

  const toggleIsOpen = useCallback(() => setIsOpen((prev) => !prev), []);

  return (
    <>
      <div className={styles.wrapper}>
        <Clickable onClick={toggleIsOpen} testId="view-menu-button" className={styles.viewMenuButton}>
          <Icon icon="tableLayout" />
          <div className={styles.text}>
            {activePreset?.name ?? t("general.view_menu_default")}
            {hasChanges && <span className={styles.status}>*</span>}
          </div>
          <Icon icon="angleDown" />
        </Clickable>

        {isOpen && (
          <div className={styles.dropdownContainer}>
            <Dropdown
              isOpen={isOpen}
              width="default"
              height="auto"
              variant="clean"
              onClickOutside={/* istanbul ignore next */ () => setIsOpen(false)}
            >
              <div className={styles.dropdownContent}>
                <div className={styles.viewContent}>
                  <span className={styles.listHeader}>{t("general.view_menu_placeholder")}</span>
                  <Clickable
                    onClick={handleDefaultView}
                    title={t("general.view_menu_default")}
                    className={styles.listItem}
                  >
                    <span className={classNames(styles.icon, !activePreset?.id && styles.selected)}>
                      <Icon icon="check" testId="check-icon" fixedWidth color={undefined} />
                    </span>
                    <span>{t("general.view_menu_default")}</span>
                  </Clickable>
                  {views.map(({ id, name }) => (
                    <Clickable
                      key={id}
                      testId={`view-menu-${id}`}
                      onClick={() => id && handleViewChange(id)}
                      title={name}
                      className={styles.listItem}
                    >
                      <span
                        className={classNames(
                          styles.icon,
                          activePreset?.id && activePreset.id === id && styles.selected,
                        )}
                      >
                        <Icon icon="check" testId="check-icon" fixedWidth color={undefined} />
                      </span>
                      <span>{name}</span>
                      {activePreset?.id && activePreset.id === id && hasChanges && (
                        <span className={styles.status}>{t("general.view_status")}</span>
                      )}
                    </Clickable>
                  ))}
                </div>
                <div className={styles.actionContainer}>
                  <SaveViewAction />
                  <SaveAsAction />
                  <EditNameAction />
                </div>
                <div className={styles.actionContainer}>
                  <RestoreViewAction />
                  <DeleteViewAction />
                </div>
              </div>
            </Dropdown>
          </div>
        )}
      </div>
    </>
  );
};
