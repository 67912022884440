import { CurrencyFormat, NumberFormat } from "@simplicate/number-format";
import { useTranslation } from "@simplicate/translations";
import { Table, buildColumnProps, Icon, useTableSelection } from "@simplicate/ui";
import { size5, size7, size8, size9, lightColorIconSuccess } from "@simplicate-software/design-tokens";
import { ColumnProps } from "primereact/column";
import { Children, ReactElement, ReactNode, isValidElement, memo } from "react";
import { Big } from "../../../../data";
import { CollectiveActions } from "../../CollectiveActions";
import { moveTo } from "../../utils";
import { HourTypeActionDropdown } from "./HourTypeActionDropdown";
import styles from "./HourTypeGrid.module.scss";
import { HourTypeGridFooter } from "./HourTypeGridFooter";
import type { HourTypeInForm, MoneyInForm } from "../../types";

type HourTypeGridProps = {
  value?: HourTypeInForm[];
  onAmountChange: (id: string, amount: number | undefined) => void;
  onHourlyRateChange: (id: string, hourlyRate: Big | undefined) => void;
  onIsInvoiceableToggled: (isInvoiceable: boolean, ...hourTypeIds: string[]) => void;
  onAddNewHourTypeEntry: (entry: HourTypeInForm) => void;
  onCancelNewHourTypeEntry: () => void;
  onRemoveHourTypeEntries: (...hourTypeIds: string[]) => void;
  onChangeSpecifiedTotal: (specifiedTotal: Big | undefined) => void;
  onReorderHourTypes: (value: HourTypeInForm[]) => void;
  specifiedTotal?: MoneyInForm;
  showIsInvoiceableColumn?: boolean;
  showTariffColumn?: boolean;
  disabled?: boolean;
};

export const HourTypeGrid = memo(function HourTypeGrid({
  value = [],
  onAmountChange,
  onHourlyRateChange,
  onIsInvoiceableToggled,
  onAddNewHourTypeEntry,
  onCancelNewHourTypeEntry,
  onRemoveHourTypeEntries,
  onChangeSpecifiedTotal,
  onReorderHourTypes,
  specifiedTotal,
  showIsInvoiceableColumn = false,
  showTariffColumn = true,
  disabled = false,
}: HourTypeGridProps) {
  const { t } = useTranslation("project_services");
  const { selection, ...selectionProps } = useTableSelection({
    value,
    CollectiveActions: ({ selection }: { selection: HourTypeInForm[] }) => {
      const selectionIds = selection.map((item) => item.id);

      return (
        <CollectiveActions
          testId="hour-type-collective-actions"
          showInvoiceable={showIsInvoiceableColumn}
          onMakeInvoiceable={() => onIsInvoiceableToggled(true, ...selectionIds)}
          onMakeNonInvoiceable={() => onIsInvoiceableToggled(false, ...selectionIds)}
          onDelete={() => onRemoveHourTypeEntries(...selectionIds)}
        />
      );
    },
  });

  const { totalAmount } = value.reduce(
    (totals, current) => {
      totals.totalAmount += current.amount ?? 0;
      totals.totalMonetaryValue = totals.totalMonetaryValue.add(current.total.amount ?? 0);

      return totals;
    },
    { totalAmount: 0, totalMonetaryValue: Big(0) },
  );

  const filterOutEmptyChildren = (children: ReactNode[]) =>
    Children.toArray(children).filter((child) => isValidElement(child)) as ReactElement<ColumnProps>[];

  return (
    <Table
      testId="hour-type-grid"
      value={value}
      dataKey="id"
      emptyMessage={t("empty_hour_type_grid_message")}
      selection={selection}
      {...selectionProps}
      reorderable={true}
      onRowReorder={
        /* istanbul ignore next - Testing this callback is responsibility of the Table */ ({ value }) =>
          onReorderHourTypes(value)
      }
      footer={
        <HourTypeGridFooter
          onConfirmNewEntry={onAddNewHourTypeEntry}
          onCancelNewEntry={onCancelNewHourTypeEntry}
          totalAmount={totalAmount}
          specifiedTotal={specifiedTotal}
          disabled={disabled}
          value={value}
          showTariffColumn={showTariffColumn}
          onChangeSpecifiedTotal={onChangeSpecifiedTotal}
        />
      }
      disabled={disabled}
    >
      {filterOutEmptyChildren([
        <Table.Column
          key="label"
          {...buildColumnProps({
            header: t("hour_type_label_header"),
            field: "label",
            body: ({ name }: HourTypeInForm) => name,
            width: "dynamic",
          })}
        />,

        showIsInvoiceableColumn && (
          <Table.Column
            {...buildColumnProps({
              header: t("hour_type_is_invoiceable_header"),
              field: "isInvoiceable",
              body: ({ isInvoiceable }: HourTypeInForm) =>
                isInvoiceable ? <Icon icon="check" color={lightColorIconSuccess} /> : <span>-</span>,
              width: size5,
            })}
          />
        ),

        <Table.Column
          key="amount"
          {...buildColumnProps({
            header: t("hour_type_quantity_header"),
            field: "amount",
            body: ({ id, amount }: HourTypeInForm) => (
              <NumberFormat
                testId="input-hour-type-amount"
                displayType="input"
                size="small"
                value={amount}
                decimalScale={2}
                textAlign="right"
                onValueChange={
                  /* istanbul ignore next -- Component is mocked as <span/> making input test cases impossible */
                  ({ floatValue }) => onAmountChange(id, floatValue)
                }
                onBlur={() => {
                  if (amount === undefined) {
                    onAmountChange(id, 0);
                  }
                }}
                disabled={disabled}
              />
            ),
            width: size7,
            align: "right",
          })}
        />,

        showTariffColumn && (
          <Table.Column
            {...buildColumnProps({
              header: t("hour_type_hourly_rate_header"),
              field: "hourlyRate",
              body: ({ id, hourlyRate }: HourTypeInForm) => (
                <CurrencyFormat
                  testId="input-hour-type-hourly-rate"
                  displayType="input"
                  size="small"
                  value={hourlyRate.amount?.toString()}
                  decimalScale={2}
                  textAlign="right"
                  onValueChange={
                    /* istanbul ignore next -- Component is mocked as <span/> making input test cases impossible */
                    ({ value }) => {
                      if (value.length === 0) {
                        return onHourlyRateChange(id, undefined);
                      }

                      return onHourlyRateChange(id, Big(value));
                    }
                  }
                  onBlur={() => {
                    if (hourlyRate.amount === undefined) {
                      onHourlyRateChange(id, Big(0));
                    }
                  }}
                  disabled={disabled}
                />
              ),
              width: size9,
              align: "right",
            })}
          />
        ),

        showTariffColumn && (
          <Table.Column
            {...buildColumnProps({
              header: t("hour_type_total_header"),
              field: "total",
              body: ({ total }: HourTypeInForm) => (
                <CurrencyFormat
                  title={total.amount.toFixed(2)}
                  className={styles.totalColumn}
                  displayType="text"
                  decimalScale={2}
                  disabled={disabled}
                  value={total.amount.toString()}
                />
              ),
              width: size8,
              align: "right",
            })}
          />
        ),

        <Table.Column
          key="actions"
          {...buildColumnProps({
            body: ({ id, isInvoiceable, hasRegistrations }: HourTypeInForm) => (
              <HourTypeActionDropdown
                id={id}
                isInvoiceable={isInvoiceable}
                hasRegistrations={hasRegistrations}
                showIsInvoiceableColumn={showIsInvoiceableColumn}
                onIsInvoiceableToggled={() => onIsInvoiceableToggled(!isInvoiceable, id)}
                onRemoveEntries={onRemoveHourTypeEntries}
                onMoveToTop={() => onReorderHourTypes(moveTo(value, id, "top"))}
                onMoveToBottom={() => onReorderHourTypes(moveTo(value, id, "bottom"))}
                disabled={disabled}
              />
            ),
            width: size5,
            align: "right",
          })}
        />,
      ])}
    </Table>
  );
});
