import { useTranslation } from "@simplicate/translations";
import { Datepicker, Tooltip } from "@simplicate/ui";
import { memo } from "react";

export const TimeframeDatePicker = memo(function TimeframeDatePicker({
  name,
  testId,
  label,
  touched,
  error,
  disabled,
  tooltip,
  onChange,
  value,
  minValue,
  maxValue,
}: {
  name?: string;
  testId?: string;
  label: string;
  touched?: boolean;
  error: string | undefined;
  disabled: boolean;
  tooltip?: string;
  onChange: (date: Date | undefined) => void;
  value?: Date;
  minValue?: Date;
  maxValue?: Date;
}) {
  const { t } = useTranslation("project_services");
  const datepicker = (
    <Datepicker
      name={name}
      testId={testId}
      placeholder={t("choose_date")}
      label={label}
      invalid={touched && error !== undefined}
      disabled={disabled}
      showButtonBar
      readOnlyInput={false}
      onChange={(event) => onChange(event.value)}
      minDate={minValue}
      maxDate={maxValue}
      value={value}
    />
  );

  return (
    <>
      {!tooltip && datepicker}
      {tooltip && (
        <Tooltip message={tooltip} position="right">
          {datepicker}
        </Tooltip>
      )}
    </>
  );
});
