import { useTranslation } from "@simplicate/translations";
import { Input, Select } from "@simplicate/ui";
import { Field } from "formik";
import { FormInitialValuesType } from "../../../../data/types/ProjectData";
import { ProjectToggle } from "./components";
import styles from "./ProjectForm.module.scss";

type ProjectFormProps = {
  data?: FormInitialValuesType;
  isNewProject: boolean;
  handleSetIsNewProject: () => void;
};

export const ProjectForm = ({ data, isNewProject, handleSetIsNewProject }: ProjectFormProps) => {
  const { t } = useTranslation("sales_convert_to_project");

  if (!data) {
    return null;
  }

  return (
    <>
      <div>
        <h3 className={styles.projectAddTitle}>{t("add_to")}</h3>
        <ProjectToggle isNew={isNewProject} onChange={handleSetIsNewProject} />
      </div>
      <div>
        <h3 className={styles.projectInfoTitle}>{t("project_information")}</h3>
        <div className={styles.projectInfoWrapper}>
          <div className={styles.projectInfoColumn}>
            {isNewProject ? (
              <Field
                placeholder={t("form_placeholders.project_name")}
                as={Input}
                name="projectName"
                label={t("form_labels.project_name")}
              />
            ) : (
              <Field
                placeholder={t("form_placeholders.project_name")}
                as={Select}
                name="projectId"
                options={data.projectName.options}
                label={t("form_labels.project_name")}
                filter={false}
              />
            )}

            <Field
              placeholder={t("form_placeholders.project_manager")}
              as={Select}
              name="projectManager"
              label={t("form_labels.project_manager")}
              options={data.projectManager.options}
              filter={false}
              disabled={!isNewProject}
            />
            <Field
              placeholder={t("form_placeholders.project_relation")}
              as={Select}
              name="relation"
              label={t("form_labels.project_relation")}
              options={data.relation.options}
              filter={false}
              disabled={!isNewProject}
            />
          </div>
          <div className={styles.projectInfoColumn}>
            {data.myOrganizationProfile && (
              <Field
                placeholder={t("form_placeholders.project_organization")}
                as={Select}
                name="myOrganizationProfile"
                label={t("form_labels.project_organization")}
                options={data.myOrganizationProfile.options}
                filter={false}
                disabled={!isNewProject}
              />
            )}
            <Field
              placeholder={t("form_placeholders.project_hours_rate")}
              as={Select}
              name="hoursRate"
              label={t("form_labels.project_hours_rate")}
              options={data.hoursRate.options1}
              filter={false}
              disabled={!isNewProject}
            />

            {data.projectNumber && (
              <>
                <Field
                  placeholder={t("form_placeholders.project_number")}
                  as={Input}
                  name="projectNumber"
                  label={t("form_labels.project_number")}
                  disabled={!isNewProject}
                />
                {data.projectNumber.lastAddedNumber && isNewProject && (
                  <span className={styles.helperText}>
                    {t("last_added_project_number", { number: data.projectNumber.lastAddedNumber })}
                  </span>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
