import { NodeModel } from "@minoru/react-dnd-treeview";
import { InvoiceMethod, SubscriptionCycle } from "@simplicate/api-client";

export type MoneyFromAPI = {
  amount: string;
  currency: string;
};

export type GroupType = "all_included" | "multiple_optional" | "single_optional" | "single_required";

type VatDivision = {
  code: string;
  description: string;
  vatAmount: MoneyFromAPI;
};

type Totals = {
  totalExcluding: MoneyFromAPI;
  totalVat: MoneyFromAPI;
  totalIncluding: MoneyFromAPI;
  vatDivisions: VatDivision[];
};

export type InvoicePrice = {
  quantity: number;
  price: MoneyFromAPI;
  totalPrice: MoneyFromAPI;
};

export type ServiceData = {
  isGrouped: boolean;
  invoiceMethod: InvoiceMethod;
  subscriptionCycle?: SubscriptionCycle;
  saleQuantity?: number;
  hoursTotalAmount?: number;
  fixedPrice?: InvoicePrice;
  totalPrice?: MoneyFromAPI;
  hoursTotalBudget?: MoneyFromAPI;
  purchaseTotalBudget?: MoneyFromAPI;
  duplicateCallback?: () => void;
  isLoading?: boolean;
  loadingText?: string;
  deleteCallback?: () => void;
  editCallback?: () => void;
};

export enum ServiceGroupType {
  NORMAL = "normal",
  UNGROUPED = "ungrouped",
}

export type ServiceGroupData = {
  groupType?: ServiceGroupType;
  deleteCallback?: () => void;
  isLoading?: boolean;
  loadingText?: string;
  loadingMutation?: Record<string, boolean>;
  description?: string;
  type?: GroupType;
  editCallback?: () => void;
  totals?: Totals;
};

export type ServiceNodeModel = NodeModel<ServiceData>;
export type ServiceGroupNodeModel = NodeModel<ServiceGroupData>;
export type GroupedServicesNodeModel = NodeModel<ServiceData | ServiceGroupData>;
