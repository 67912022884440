import { UseCubeQueryResult } from "@cubejs-client/react";
import { createContext, useContext } from "react";

export type DashboardContextType = {
  queryResult: UseCubeQueryResult<unknown, unknown>;
};

export const DashboardContext = createContext<DashboardContextType | null>(null);

export const useDashboardContext = () => {
  const context = useContext(DashboardContext);

  if (context === null) {
    throw new Error("Can only use `useDashboardContext` inside `DashboardContextProvider`");
  }

  return context;
};
