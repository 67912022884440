export type CubeDimension = readonly [cube: string, dimension: string];

export function cubeDimensionToKey([cube, dimension]: CubeDimension): string {
  return `${cube}.${dimension}`;
}

export const convertStringToCubeDimension = (value: string): CubeDimension => {
  const input = value.split(".");

  if (input.length === 2) {
    return [input[0]!, input[1]!] as const;
  }

  throw new Error(`Invalid cube dimension: ${value}`);
};
