import { InvoiceMethod } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { Checkbox, Datepicker } from "@simplicate/ui";
import { UniqueComponentId } from "primereact/utils";
import { memo, useMemo } from "react";
import { CanRegisterToggleHeader } from "./CanRegisterToggleHeader/CanRegisterToggleHeader";
import { FormikErrors } from "./FormikErrors";
import { CostTypeGrid } from "./grids/CostTypeGrid";
import { useProjectForm } from "./hooks/useProjectServiceForm";
import styles from "./ProjectServicePageCostSection.module.scss";

const useCostTypeSectionProps = () => {
  const { costTypeHandlers: handlers, values, touched, errors } = useProjectForm();

  const isCanRegisterCostsLocked = useMemo(
    () =>
      (values.costTypes?.some(({ hasRegistrations }) => hasRegistrations) ?? false) &&
      (values.canRegisterCosts ?? false),
    [values.canRegisterCosts, values.costTypes],
  );

  return {
    isCanRegisterCostsLocked,
    canRegisterCosts: values.canRegisterCosts,
    costTypes: values.costTypes,
    registrationEndDate: values.registrationEndDate,
    hasRegistrationEndDate: values.hasRegistrationEndDate,
    touched: touched.canRegisterCosts,
    error: errors.canRegisterCosts,
    registrationEndDateError: errors.registrationEndDate,
    handlers,
    defaultService: values.defaultService,
    invoiceMethod: values.invoiceMethod,
  };
};

const CostTypeSectionContent = memo(function CostTypeSectionContent({
  isCanRegisterCostsLocked,
  canRegisterCosts,
  costTypes,
  registrationEndDate,
  hasRegistrationEndDate,
  touched,
  error,
  registrationEndDateError,
  handlers,
  defaultService,
  invoiceMethod,
}: ReturnType<typeof useCostTypeSectionProps>) {
  const { t } = useTranslation("project_services");

  if (!handlers) {
    throw new Error("useProjectForm must be used within a ProjectServicePage component");
  }

  const isDefaultServiceUndefined = defaultService === undefined;
  const invoiceMethodIsTimeAndExpenses = invoiceMethod === InvoiceMethod.time_and_expenses;
  const isCostTypeGridDisabled = !canRegisterCosts || isDefaultServiceUndefined;

  return (
    <>
      <CanRegisterToggleHeader
        isDefaultServiceUndefined={isDefaultServiceUndefined}
        canRegister={canRegisterCosts ?? false}
        setCanRegister={handlers.setCanRegisterCosts}
        isCanRegisterLocked={isCanRegisterCostsLocked}
        labelText={t("expense_registration")}
        testId="expense_registration_toggle"
        tooltipText={t("cannot_disable_can_register_costs")}
        errors={<FormikErrors touched={touched} key={UniqueComponentId()} error={error} />}
      />
      <CostTypeGrid
        value={costTypes}
        onAddCostType={handlers.addCostType}
        onRemoveCostTypes={handlers.removeCostTypes}
        onReorderCostTypes={handlers.setCostTypes}
        onLabelChange={handlers.setLabelForCostType}
        onQuantityChange={handlers.setQuantityForCostType}
        onMarginChange={handlers.setMarginForCostType}
        onPurchasePriceChange={handlers.setPurchasePriceForCostType}
        onSellingPriceChange={handlers.setSellingPriceForCostType}
        disabled={isCostTypeGridDisabled}
        showIsInvoiceableColumn={invoiceMethodIsTimeAndExpenses}
        onIsInvoiceableToggled={handlers.toggleIsInvoiceableForCostTypes}
      />

      <div className={styles.registrationEndDateContainer}>
        <Checkbox
          name="registrationEndDate"
          label={t("custom_end_date_label")}
          disabled={!canRegisterCosts || isCostTypeGridDisabled}
          value={hasRegistrationEndDate ?? false}
          onChange={/* istanbul ignore next */ (e) => handlers.setHasRegistrationEndDate(e.target.checked)}
        />
        {hasRegistrationEndDate && (
          <Datepicker
            name={t("end_date")}
            label={t("end_date")}
            placeholder={t("choose_end_date")}
            disabled={!canRegisterCosts || isCostTypeGridDisabled}
            value={registrationEndDate}
            invalid={!!registrationEndDateError}
            onChange={/* istanbul ignore next */ (e) => handlers.setRegistrationEndDate(e.value ?? undefined)}
          />
        )}
      </div>
    </>
  );
});

export const CostTypeSection = () => <CostTypeSectionContent {...useCostTypeSectionProps()} />;
