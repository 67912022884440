import { Action, ThunkAction } from "@simplicate/state";
import { CubeDimension, cubeDimensionToKey } from "../../../../types";
import { setFilters } from "../dashboardSlice";
import { DashboardRootState } from "../types";

type ApplyFilterPayload = {
  config: { labelDimension: CubeDimension; valueDimension: CubeDimension };
  values: { label: string; value: string }[];
};

export const applyFilterThunk =
  ({
    config,
    values,
  }: ApplyFilterPayload): ThunkAction<
    void,
    DashboardRootState,
    unknown,
    Action<"Insights Dashboard State/setFilters">
  > =>
  (dispatch, getState) => {
    const slice = getState().state.insights.dashboard_views;
    const localChangesFilters = slice.localChanges.filters;
    const activeDashboardFilters = slice.activeDashboardId
      ? slice.dashboards[slice.activeDashboardId]?.filters
      : undefined;
    const currentFilters = localChangesFilters ?? activeDashboardFilters ?? [];

    const newFilters = [
      ...currentFilters.filter(
        (filter) => cubeDimensionToKey(filter.valueDimension) !== cubeDimensionToKey(config.valueDimension),
      ),
      ...values.map((value) => ({
        ...value,
        valueDimension: config.valueDimension,
        labelDimension: config.labelDimension,
      })),
    ];

    dispatch(setFilters(newFilters));
  };
