import { useTranslation } from "@simplicate/translations";
import { Icon, Link } from "@simplicate/ui";
import { useCallback } from "react";
import { requestSave, useAppDispatch } from "../../data";

export const SaveAsAction = () => {
  const { t } = useTranslation("insights");
  const dispatch = useAppDispatch();

  const handleClick = useCallback(() => {
    dispatch(requestSave("create"));
  }, [dispatch]);

  return (
    <Link testId="save-view-as-button" onClick={handleClick}>
      <Icon icon="floppyDisk" fixedWidth />
      {t("general.add_view")}
    </Link>
  );
};
