import { Action, ThunkAction } from "@simplicate/state";
import { ViewPresetEndpoints } from "../../../endpoints/ViewPreset/ViewPreset";
import { setChangedMeta } from "../dashboardSlice";
import { DashboardRootState } from "../types";

export const finalizeSaveThunk =
  (
    newName: string,
  ): ThunkAction<void, DashboardRootState, unknown, Action<"Insights Dashboard State/setChangedMeta">> =>
  (dispatch, getState) => {
    const slice = getState().state.insights.dashboard_views;

    dispatch(
      setChangedMeta({
        ...slice.changedMeta,
        saveRequested: false,
      }),
    );

    if (!slice.activeDashboardId) {
      return;
    }

    const viewPreset = {
      ...slice.dashboards[slice.activeDashboardId],
      ...slice.localChanges,
      name: newName,
      dashboardId: slice.activeDashboardId,
    };

    if (!viewPreset.order) {
      viewPreset.order = [];
    }

    if (!viewPreset.filters) {
      viewPreset.filters = [];
    }

    if (!viewPreset.columns) {
      viewPreset.columns = [];
    }

    if (slice.changedMeta.saveType === "create") {
      delete viewPreset.id;

      void dispatch(ViewPresetEndpoints.createViewPreset.initiate(viewPreset));

      return;
    }

    void dispatch(ViewPresetEndpoints.updateViewPreset.initiate(viewPreset));
  };
