import { NumberFormat } from "@simplicate/number-format";
import { useTranslation } from "@simplicate/translations";
import { Tooltip } from "@simplicate/ui";
import { memo } from "react";
import { useProjectForm } from "../hooks/useProjectServiceForm";
import styles from "./InvoiceQuantityInput.module.scss";

const useInvoiceQuantityInputProps = () => {
  const { values, setInvoiceQuantity } = useProjectForm();

  return {
    invoiceQuantity: values.invoiceQuantity,
    existsOnInvoice: values.existsOnInvoice,
    setInvoiceQuantity,
  };
};

const InvoiceQuantityInputContent = memo(function InvoiceQuantityInputContent({
  invoiceQuantity,
  setInvoiceQuantity,
  existsOnInvoice,
}: ReturnType<typeof useInvoiceQuantityInputProps>) {
  const { t } = useTranslation("project_services");
  const tooltip = existsOnInvoice ? t("invoice_quantity_locked_invoices_tooltip") : undefined;

  const input = (
    <div className={styles.invoiceQuantity}>
      <NumberFormat
        testId="invoice-quantity"
        displayType="input"
        size="normal"
        label={t("invoice_quantity_label")}
        decimalScale={2}
        textAlign="left"
        value={invoiceQuantity}
        disabled={existsOnInvoice}
        defaultValue={1}
        onValueChange={
          /* istanbul ignore next -- Component is mocked as <span/> making input test cases impossible */ ({
            floatValue,
          }) => setInvoiceQuantity?.(floatValue)
        }
      />
    </div>
  );

  return tooltip ? (
    <Tooltip message={tooltip} position="right">
      {input}
    </Tooltip>
  ) : (
    input
  );
});

export const InvoiceQuantityInput = () => <InvoiceQuantityInputContent {...useInvoiceQuantityInputProps()} />;
