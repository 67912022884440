import { useTranslation } from "@simplicate/translations";
import { Icon, InputGroup, RadioButton, Tooltip } from "@simplicate/ui";
import classNames from "classnames";
import { memo } from "react";
import styles from "./IsPlannableRadioButton.module.scss";

type IsPlannableRadioButtonProps = {
  value: boolean;
  onChange: (value: boolean) => void;
  disabled?: boolean;
  tooltip?: string;
};

export const IsPlannableRadioButton = memo(function IsPlannableRadioButton({
  value,
  onChange,
  disabled,
  tooltip,
}: IsPlannableRadioButtonProps) {
  const { t } = useTranslation("project_services");

  const radioButtonOptions = [
    { label: t("yes", { ns: "general" }), value: "yes", disabled },
    { label: t("no", { ns: "general" }), value: "no", disabled },
  ];

  const radioButtonCheck = value ? "yes" : "no";

  const radioButtons = (
    <InputGroup spacing="large" direction="horizontal">
      {radioButtonOptions.map(({ value, label, disabled }) => (
        <RadioButton
          key={value}
          value={value}
          checked={radioButtonCheck === value}
          name="is_plannable"
          disabled={disabled}
          onChange={(event) => onChange(event.target.value === "yes")}
        >
          {label}
        </RadioButton>
      ))}
    </InputGroup>
  );

  return (
    <div className={styles.isPlannableContainer} data-testid="is-plannable-radiobutton">
      <label
        htmlFor="is_plannable"
        className={classNames(styles.isPlannableLabelContainer, disabled && styles.disabled)}
      >
        <span className={styles.isPlannableLabel}>{t("is_plannable_label")}</span>
        <Tooltip message={t("is_plannable_tooltip")}>
          <div className={styles.infoIconContainer}>
            <Icon icon="infoCircle" className={styles.isPlannableLabelInfoIcon} />
          </div>
        </Tooltip>
      </label>
      <div className={styles.isPlannableRadioButton}>
        {tooltip ? (
          <Tooltip message={tooltip} position="right">
            {radioButtons}
          </Tooltip>
        ) : (
          radioButtons
        )}
      </div>
    </div>
  );
});
