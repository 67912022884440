import { BarChart } from "@simplicate/charts";
import { Widget } from "@simplicate/ui";
import { CubeQuery } from "../../data";
import { CubeDimension } from "../../types";
import { useBarChartData } from "./useBarChartData";

type BarChartWidgetProps = {
  title: string;
  /**
   * Limits the amount of categories to show in the chart. Set to 0 to show all.
   *
   * @default 5
   */
  selectCount?: number;
  query: Partial<CubeQuery>;
  pivotConfig: {
    x: readonly CubeDimension[];
    y: readonly CubeDimension[];
  };
  translations: Record<string, string>;
  orientation?: "horizontal" | "vertical";
  labelFormat?: "month-name" | "unformatted";
};

export const BarChartWidget = ({
  title,
  selectCount = 5,
  pivotConfig,
  query,
  translations,
  orientation = "horizontal",
  labelFormat,
}: BarChartWidgetProps) => {
  const data = useBarChartData(query, pivotConfig, selectCount, translations, labelFormat);

  return (
    <Widget title={title}>
      <BarChart
        data={data}
        config={{
          orientation,
          stacked: true,
        }}
      />
    </Widget>
  );
};
