import { useFeatureFlag } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { Button, Modal, MODAL_ANCHOR, Portal, usePortalContext } from "@simplicate/ui";
import { useEffect } from "react";
import { type ServiceGroup } from "../../../../data";
import { LoadingFallback } from "../LoadingFallback";
import { DescriptionInput, ErrorMessage, GroupTypeSelect, NameInput } from "./ModalElements";
import { useServiceGroupModalForm } from "./ServiceGroupModal.hooks";
import styles from "./ServiceGroupModal.module.scss";

export type ServiceGroupModalProps = {
  onClose: () => void;
  saleId: string;
  serviceGroupInEdit?: ServiceGroup;
};

export const ServiceGroupModal = ({ onClose, saleId, serviceGroupInEdit }: ServiceGroupModalProps) => {
  const { t } = useTranslation("sales_services");
  const isOptionalFlagEnabled = useFeatureFlag("sales-service-groups-optional-services").enabled;
  const { targetRefs } = usePortalContext();
  const {
    errors,
    setGroupType,
    values,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    isLoading,
    loadingText,
    isMutationSuccess,
    mutationError,
  } = useServiceGroupModalForm(saleId, serviceGroupInEdit);

  useEffect(() => {
    if (isMutationSuccess) {
      onClose();
    }
  }, [isMutationSuccess, onClose]);

  return (
    <>
      {isLoading && (
        <Portal targetRef={targetRefs[MODAL_ANCHOR]}>
          <LoadingFallback text={loadingText} />
        </Portal>
      )}

      <Modal isOpen={true} onClose={onClose}>
        <form className={styles.modalContent} onSubmit={handleSubmit}>
          <h3>{serviceGroupInEdit ? t("service_group.edit_title") : t("service_group.add_title")}</h3>
          <NameInput
            name={values.name}
            error={errors.name}
            isTouched={touched.name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <DescriptionInput
            description={values.description}
            error={errors.description}
            onChange={handleChange}
            onBlur={handleBlur}
            isTouched={touched.description}
          />
          {isOptionalFlagEnabled && (
            <GroupTypeSelect groupType={values.groupType} onChange={setGroupType} onBlur={handleBlur} />
          )}
          {!!mutationError && <ErrorMessage>{mutationError}</ErrorMessage>}
          <div className={styles.modalButtonsWrapper}>
            <Button variant="primary" size="medium" type="submit">
              {serviceGroupInEdit ? t("button_save") : t("button_add")}
            </Button>
            <Button variant="subtle" size="medium" onClick={onClose}>
              {t("button_cancel")}
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
};
