import { usePermission } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { ModuleIcon, NavCard, Page, Tag } from "@simplicate/ui";
import {
  colorModulesGreen100,
  colorModulesGreen500,
  colorCommonBrand100,
  colorCommonBrand500,
} from "@simplicate-software/design-tokens";
import { useMemo } from "react";
import { useGetDashboardsListQuery } from "../../data";
import { IndexPageTemplate } from "./IndexPage.template";

export const IndexPage = () => {
  const { t } = useTranslation("insights");

  const availableHoursDashboard = useMemo(
    () => ({
      hours: {
        id: "hours-report",
        title: t("hours.report_title"),
        description: t("hours.report_description"),
        route: "dashboard/hours",
        icon: <ModuleIcon module="hours" color={colorModulesGreen500} />,
        footer: <Tag text={t("hours.category_label")} variant="color" color={colorModulesGreen100} />,
        utilityCorner: <Tag text={t("beta_label")} variant="light" />,
        color: colorModulesGreen100,
      },
      hoursDetails: {
        id: "hours-details-report",
        title: t("hours.details_report_title"),
        description: t("hours.details_report_description"),
        route: "dashboard/hours-details",
        icon: <ModuleIcon module="hours" color={colorModulesGreen500} />,
        footer: <Tag text={t("hours.category_label")} variant="color" color={colorModulesGreen100} />,
        utilityCorner: <Tag text={t("beta_label")} variant="light" />,
        color: colorModulesGreen100,
      },
    }),
    [t],
  );

  const availableWorkflowsDashboard = useMemo(
    () => ({
      Workflows: {
        id: "workflows-report",
        title: t("workflows.report_title"),
        description: t("workflows.report_description"),
        route: "dashboard/workflows",
        icon: <ModuleIcon module="workflows" color={colorCommonBrand500} />,
        footer: <Tag text={t("workflows.category_label")} variant="color" color={colorCommonBrand100} />,
        utilityCorner: <Tag text={t("beta_label")} variant="light" />,
        color: colorCommonBrand100,
      },
      WorkflowsDetails: {
        id: "workflows-details-report",
        title: t("workflows.details_report_title"),
        description: t("workflows.details_report_description"),
        route: "dashboard/workflow-details",
        icon: <ModuleIcon module="workflows" color={colorCommonBrand500} />,
        footer: <Tag text={t("workflows.category_label")} variant="color" color={colorCommonBrand100} />,
        utilityCorner: <Tag text={t("beta_label")} variant="light" />,
        color: colorCommonBrand100,
      },
    }),
    [t],
  );
  const { data: dashboards } = useGetDashboardsListQuery();
  const { granted: hasAccessToHours } = usePermission("hours", "view");
  const { granted: hasHoursReportingPermission } = usePermission("hours", "hoursrapportage_all");

  const visibleHoursDashboards = Object.values(availableHoursDashboard).filter(({ id }) =>
    dashboards?.dashboards?.some((dashboard) => dashboard.slug === id),
  );

  const visibleWorkflowsDashboards = Object.values(availableWorkflowsDashboard).filter(({ id }) =>
    dashboards?.dashboards?.some((dashboard) => dashboard.slug === id),
  );

  return (
    <Page>
      <IndexPageTemplate>
        <IndexPageTemplate.Title title={t("index_page_title")} />
        <NavCard.Container>
          {hasAccessToHours &&
            hasHoursReportingPermission &&
            visibleHoursDashboards.map(({ route, title, description, icon, footer, utilityCorner, color }) => (
              <NavCard
                key={route}
                title={title}
                description={description}
                to={route}
                accentColor={color}
                icon={icon}
                footer={footer}
                utilityCorner={utilityCorner}
              />
            ))}

          {visibleWorkflowsDashboards.map(({ route, title, description, icon, footer, utilityCorner, color }) => (
            <NavCard
              key={route}
              title={title}
              description={description}
              to={route}
              accentColor={color}
              icon={icon}
              footer={footer}
              utilityCorner={utilityCorner}
            />
          ))}
        </NavCard.Container>
      </IndexPageTemplate>
    </Page>
  );
};
