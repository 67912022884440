import {
  RootState,
  TypedUseSelectorHook,
  useSelector,
  useAppDispatch as useRootDispatch,
  Action,
  ThunkDispatch,
} from "@simplicate/state";
import { DashboardSlice } from "./dashboardSlice";

type InsightsSlices = {
  insights: {
    dashboard_views: DashboardSlice;
  };
};

type AppDispatch = ThunkDispatch<RootState<InsightsSlices>, undefined, Action<string>>;

export * from "./dashboardSlice";
export const useAppDispatch: () => AppDispatch = useRootDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState<InsightsSlices>> = useSelector;
