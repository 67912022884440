import { Icon } from "@simplicate/ui";
import { lightColorIconError } from "@simplicate-software/design-tokens";
import { type PropsWithChildren } from "react";
import styles from "./ErrorMessage.module.scss";

export const ErrorMessage = ({ children }: PropsWithChildren) => (
  <div className={styles.error}>
    <span className={styles.errorIcon}>
      <Icon fixedWidth icon="exclamationTriangle" color={lightColorIconError} />
    </span>
    <span>{children}</span>
  </div>
);
