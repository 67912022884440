import { DeeplyReadonly, Query } from "@cubejs-client/core";
import { useTranslation } from "@simplicate/translations";
import { DialogHandle } from "@simplicate/ui";
import { PropsWithChildren, useEffect, useRef } from "react";
import {
  useAppDispatch,
  useAppSelector,
  useDataset,
  setActiveDashboard,
  getActiveViewPreset,
  Filter,
  getChangedMeta,
  finalizeSave,
  cancelMutation,
  finalizeDelete,
  getActiveFilters,
  getOrder,
} from "../../data";
import { useDateRangeSearchQuery } from "../../hooks";
import { ConfirmDeleteDialog } from "../ConfirmDeleteDialog/ConfirmDeleteDialog";
import { SaveViewDialog, SaveViewDialogHandle } from "../SaveViewDialog";
import { DashboardContext } from "./Dashboard.context";

type DashboardProps = PropsWithChildren<{
  dashboardId: string;
  query: DeeplyReadonly<Query>;
  filters?: Filter[];
}>;

export const Dashboard = ({ children, query, dashboardId, filters }: DashboardProps) => {
  const { t } = useTranslation("insights");
  const askForNameDialog = useRef<SaveViewDialogHandle>(null);
  const deleteDialog = useRef<DialogHandle<unknown>>(null);
  const dateRange = useDateRangeSearchQuery();
  const dashboard = useAppSelector(getActiveViewPreset);
  const dashboardFilters = useAppSelector(getActiveFilters);
  const dashboardOrder = useAppSelector(getOrder);
  const changedMeta = useAppSelector(getChangedMeta);
  const appDispatch = useAppDispatch();

  const queryResult = useDataset({
    ...query,
    state: {
      filters: [...dashboardFilters.filters, ...(filters ?? [])],
      order: dashboardOrder,
    },
  });

  useEffect(() => {
    appDispatch(setActiveDashboard({ dashboardId, dateRange }));
  }, [appDispatch, dashboardId, dateRange]);

  useEffect(() => {
    if (changedMeta.saveRequested) {
      const name = changedMeta.saveType === "create" ? undefined : dashboard?.name;
      const title = changedMeta.saveType === "create" ? t("general.add_view") : t("general.edit_view");

      askForNameDialog.current
        ?.open(name, { dialogTitle: title })
        .then(
          /* istanbul ignore next -- these promises do not work in jest */
          (result) => {
            if (result.status === "submit" && result.data) {
              appDispatch(finalizeSave(result.data.name));

              return;
            }

            appDispatch(cancelMutation());
          },
        )
        .catch(
          /* istanbul ignore next -- these promises do not work in jest */ () => {
            appDispatch(cancelMutation());
          },
        );
    }
  }, [appDispatch, changedMeta.saveRequested, changedMeta.saveType, dashboard?.name, t]);

  useEffect(() => {
    if (changedMeta.deleteRequested) {
      deleteDialog.current
        ?.open()
        .then(
          /* istanbul ignore next -- these promises do not work in jest */
          (result) => {
            if (result.status === "submit") {
              appDispatch(finalizeDelete());

              return;
            }

            appDispatch(cancelMutation());
          },
        )
        .catch(
          /* istanbul ignore next */ () => {
            appDispatch(cancelMutation());
          },
        );
    }
  }, [appDispatch, changedMeta.deleteRequested]);

  return (
    <DashboardContext.Provider
      value={{
        queryResult,
      }}
    >
      {children}
      <SaveViewDialog ref={askForNameDialog} />
      <ConfirmDeleteDialog ref={deleteDialog} />
    </DashboardContext.Provider>
  );
};
