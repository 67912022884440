import { lightColorIconDefault } from "@simplicate-software/design-tokens";
import classNames from "classnames";
import { Dropdown, DropdownProps, DropdownChangeEvent } from "primereact/dropdown";
import { SelectItem } from "primereact/selectitem";
import { FormTarget } from "primereact/ts-helpers";
import { Icon } from "../Icon";
// eslint-disable-next-line css-modules/no-unused-class -- classes are used in styles[size]
import styles from "./Select.module.scss";

export type TypedDropdownChangeEvent<T> = Omit<DropdownChangeEvent, "target"> & {
  target: Omit<FormTarget, "target"> & {
    value: T;
  };
};

type SelectOption = Omit<SelectItem, "className"> & {
  tooltip?: string;
};

type SelectProps<ValueType, OptionType extends SelectOption> = Omit<
  DropdownProps,
  "className" | "onChange" | "options" | "pt" | "size" | "value"
> & {
  testId?: string;
  label?: string;
  value?: ValueType;
  options?: OptionType[];
  invalid?: boolean;
  size?: "normal" | "small";
  onChange?(event: TypedDropdownChangeEvent<ValueType>): void;
};

export const Select = <ValueType, OptionType extends SelectOption>({
  testId,
  label,
  value,
  options = [],
  invalid,
  name,
  disabled,
  size = "normal",
  ...dropdownProps
}: SelectProps<ValueType, OptionType>) => {
  const defaultProps: DropdownProps = {
    itemTemplate: (option: OptionType) => (
      <div
        // Interactivity is needed for the tooltip to show. Stopping propagation of the click event prevents the dropdown from closing on click.
        onClick={(event) => option.disabled && event.stopPropagation()}
        role="none"
        data-testid={testId && `${testId}-option-${option.value}`}
        className={classNames(styles.dropdownItemContent, option.disabled && styles.optionDisabled)}
        title={option.tooltip}
      >
        {option.label}
        {value === option.value && (
          <Icon testId={`${testId}-selectedIcon`} icon="check" className={styles.selectedIcon} />
        )}
        {option.tooltip && <Icon icon="infoCircle" />}
      </div>
    ),
    filter: true,
    filterInputAutoFocus: true,
    resetFilterOnHide: true,
    showOnFocus: true,
    pt: {
      root: (options) => ({
        className: classNames(
          styles.dropdownRoot,
          styles.inputWrapper,
          /* istanbul ignore next -- options being undefined is not applicable here */
          options?.props.disabled ? styles.disabled : undefined,
        ),
      }),
      input: () => ({
        className: styles.input,
      }),
      filterInput: () => ({
        className: classNames(styles.inputWrapper, styles.input),
      }),
      filterIcon: () => ({
        className: styles.filterIcon,
      }),
      filterContainer: () => ({
        className: classNames(styles.filterContainer),
      }),

      header: () => ({
        className: styles.dropdownHeader,
      }),
      wrapper: () => ({
        className: styles.dropdownWrapper,
      }),
      item: () => ({
        className: styles.dropdownItem,
      }),
      list: () => ({
        className: styles.itemList,
      }),
    },
  };

  return (
    <>
      <div
        data-testid={testId && `${testId}-wrapper`}
        className={classNames(styles.wrapper, disabled && styles.disabled)}
        data-active={!!value}
      >
        {label && (
          <label htmlFor={name} className={styles.label}>
            {label}
          </label>
        )}
        <Dropdown
          {...defaultProps}
          data-testid={testId}
          value={value}
          name={name}
          options={options}
          dropdownIcon={<Icon icon="angleDown" color={lightColorIconDefault} />}
          disabled={disabled}
          className={classNames(invalid && styles.invalid, styles[size], value && styles.activeControl)}
          panelClassName={styles.dropdown}
          {...dropdownProps}
        />
      </div>
    </>
  );
};
