import { useTranslation } from "@simplicate/translations";
import { memo } from "react";
import { useParams } from "react-router-dom";
import { useProjectForm } from "../hooks/useProjectServiceForm";
import styles from "./RegistrationTimeFrame.module.scss";
import { TimeframeDatePicker } from "./TimeFrameDatePicker";

const useRegistrationTimeframeProps = () => {
  const {
    values,
    errors,
    touched,
    setRegistrationTimeFrameStartDate,
    setRegistrationTimeFrameEndDate,
    existingProjectService,
  } = useProjectForm();
  const { serviceId } = useParams();

  return {
    registrationTimeFrame: values.registrationTimeFrame,
    touched: touched.registrationTimeFrame,
    error: errors.registrationTimeFrame,
    setRegistrationTimeFrameStartDate,
    setRegistrationTimeFrameEndDate,
    existingProjectService,
    serviceId,
  };
};

const RegistrationTimeframeContent = memo(function RegistrationTimeframeContent({
  serviceId,
  registrationTimeFrame,
  touched,
  error,
  setRegistrationTimeFrameStartDate,
  setRegistrationTimeFrameEndDate,
  existingProjectService,
}: ReturnType<typeof useRegistrationTimeframeProps>) {
  const { t } = useTranslation("project_services");
  const isEditingService = serviceId !== undefined;

  const isTimeframeStartDateLocked = isEditingService && !!existingProjectService?.timeFrame?.startDate;

  const tooltip = isTimeframeStartDateLocked ? t("subscription_timeframe_start_cannot_be_changed") : undefined;

  return (
    <>
      <div className={styles.timeframeFields}>
        <div className={styles.timeframeFieldContainer}>
          <TimeframeDatePicker
            onChange={(value) => setRegistrationTimeFrameStartDate?.(value)}
            value={registrationTimeFrame?.startDate}
            disabled={false}
            name="registrationStartDate"
            tooltip={tooltip}
            testId="registration-start-date-datepicker"
            label={t("start_date")}
            touched={touched}
            error={error}
          />
        </div>
        <div className={styles.timeframeFieldContainer}>
          <TimeframeDatePicker
            onChange={(value) => setRegistrationTimeFrameEndDate?.(value)}
            value={registrationTimeFrame?.endDate}
            disabled={false}
            name="registrationEndDate"
            testId="registration-end-date-datepicker"
            label={t("end_date")}
            minValue={registrationTimeFrame?.startDate}
            touched={touched}
            error={error}
          />
        </div>
      </div>
    </>
  );
});

export const RegistrationTimeframe = () => <RegistrationTimeframeContent {...useRegistrationTimeframeProps()} />;
