export const moveTo = <T extends { id: string }>(arr: T[], id: string, position: "bottom" | "top") => {
  const index = arr.findIndex((element) => element.id === id);

  if (index < 0 || index >= arr.length) return arr;

  const [element] = arr.splice(index, 1);

  if (!element) return arr;
  if (position === "top") {
    arr.unshift(element);
  } else {
    arr.push(element);
  }

  return [...arr];
};
