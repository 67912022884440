import classNames from "classnames";
import { InputHTMLAttributes, PropsWithChildren } from "react";
import styles from "./Checkbox.module.scss";

type CheckboxProps = Omit<InputHTMLAttributes<HTMLInputElement>, "value"> &
  PropsWithChildren<{
    name?: string;
    label?: string;
    value: boolean | "indeterminate";
    disabled?: boolean;
    testId?: string;
  }>;

export const Checkbox = ({ name, label, disabled, value, testId, children, ...inputProps }: CheckboxProps) => {
  const hasLabel = label !== undefined || children !== undefined;

  return (
    <div className={classNames(styles.checkbox, disabled && styles.disabled, hasLabel && styles.hasLabel)}>
      <input
        id={name}
        name={name}
        type="checkbox"
        className={styles.input}
        checked={value === "indeterminate" ? false : value}
        disabled={disabled}
        ref={(input) => {
          if (input) {
            input.indeterminate = value === "indeterminate";
          }
        }}
        data-testid={testId}
        {...inputProps}
      />
      {hasLabel && (
        <label className={styles.label} htmlFor={name}>
          {label}
          {children}
        </label>
      )}
    </div>
  );
};
